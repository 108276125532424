import { BiFirstPage, BiLastPage } from "react-icons/bi";
import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";
import { Link,useParams } from 'react-router-dom';
import styled from 'styled-components';

function PagingBox ({boardName , totalPage } ) {
    /* 
        [ PagingBox 컴포넌트 사용법 ] 

        boardName : 게시판 이름 
        totalPage : 총 몇개의 게시글이 있는지 
    */
    let {pageNum} = useParams(); 
    const currentPage = (pageNum !== undefined)? pageNum: 1; //현재 페이지 
    const startPage = (Math.floor((currentPage - 1) / 5)) * 5 + 1;
    const endPage = Math.min(startPage + 4, totalPage);
    let pageArr = []; 
    for(let page = startPage; page <= endPage; page++ ){
        pageArr.push(
            <Link to={`/${boardName}/${page}`} key={page}><div className={`paging-btn ${(Number(currentPage) === Number(page)) ? 'on':''}`}>{page}</div></Link>
        )
    }

    return (
        <PagingContainer aria-label="Page navigation">
            <PagingWrap>
                {
                    (currentPage > 5) && 
                    <>
                        <Link to={`/${boardName}/${1}`}><div className="paging-btn"><BiFirstPage/> </div></Link>
                        <Link to={`/${boardName}/${startPage - 1}`}><div className="paging-btn"><IoIosArrowBack /></div></Link>
                    </>
                }
                {pageArr}
                {   
                    (endPage < totalPage) && 
                    <>
                        <Link to={`/${boardName}/${endPage + 1}`}><div className="paging-btn"><IoIosArrowForward /></div></Link>
                        <Link to={`/${boardName}/${totalPage}`}><div className="paging-btn"><BiLastPage/> </div></Link>
                    </>
                }
            </PagingWrap>
        </PagingContainer>
    );
    
}

export default PagingBox; 


const PagingContainer = styled.div`
    display: flex; justify-content: center; align-items: center;
`
const PagingWrap = styled.div`
    display:flex; align-items:center; justify-content:center; gap: 5px;
    .paging-btn{ 
        border:1px solid #dfdfdf;  
        width : 30px; height: 30px; display: flex; align-items: center; justify-content: center;
        border-radius: 5px; color: #7f7f7f;
        &.on{ 
            background-color:#222; color: #fff; border-color:#222; 
        }
    }
`