import styled from 'styled-components'
import "./Project.css"

import { Link } from 'react-router-dom';
import AdminMenu from 'components/AdminMenu';
import ServiceBottom from 'components/ServiceBottom';
import { useRef,useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/effect-fade";

import { Helmet } from 'react-helmet';
    

const Project = () => {
    
    /* 스와이퍼 버튼 */
    const swiperNavigationPrevRef = useRef(null);
    const swiperNavigationNextRef = useRef(null);

    const [projectData, setProjectData] = useState();
    const [category, setCategory] = useState('all');
    useEffect(() => {
      const fetchProjectData = async (id) => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/project`);
          if (response.status === 200) {
            //   console.log(response);
              setProjectData(response.data.result[0]);
            } else {
              throw new Error('서버 응답 오류');
            }
          } catch (error) {
            console.log(error);
            alert(error);
          }
      };
      fetchProjectData(); // Pass the 'id' value to the fetchProjectData function
    }, []);

    const menuChangeHandler = (menu) => {
        setCategory(menu);
    };

    useEffect(() => {
        const fetchProjectData = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/project/menu/${category}`);
            if (response.status === 200) {
                console.log(response);
                setProjectData(response.data.result[0]);
              } else {
                throw new Error('서버 응답 오류');
              }
            } catch (error) {
              console.log(error);
              alert(error);
            }
        };
        fetchProjectData(); // Pass the 'id' value to the fetchProjectData function
    }, [category]);

    return(
        <div className="rootConatiner">
            <Helmet>
                {/* SEO 코드를 넣는 부분*/}
                <title>잡오브브라더스 : PROJECT</title>
                <meta name="description" content="홈페이지 제작, 서비스 & 소프트웨어 개발 실력, 포트폴리오로 증명하겠습니다." />
            </Helmet>
            {/* 
                <SwiperConatiner>
                    <Swiper
                        modules = {[Autoplay, Navigation, EffectFade]}
                        // autoplay={{
                        //     delay: 2500,
                        //     disableOnInteraction: false,
                        // }}
                        navigation = {{
                            prevEl : swiperNavigationPrevRef.current, 
                            nextEl : swiperNavigationNextRef.current,
                        }}
                        slidesPerView={1}
                        loop = {true}
                        effect={"fade"}
                        className="swiperContainer"
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = swiperNavigationPrevRef.current;
                            swiper.params.navigation.nextEl = swiperNavigationNextRef.current;
                    }}
                    >
                        <SwiperSlide>
                            <img src={`${process.env.PUBLIC_URL}/images/portfolio_banner_example.png`} alt="서울 캠퍼스타운 성과발표회" className="pc_img"/>
                            <img src={`${process.env.PUBLIC_URL}/images/portfolio_banner_example.png`} alt="서울 캠퍼스타운 성과발표회" className="mobile_img"/>
                            <div className="titleContainer">
                                <div className="inner">
                                    <div className="titleWrap">
                                        <li className="titleBox title01">
                                            2022 서울 캠퍼스타운 페스티벌 사이트 제작
                                        </li>
                                        <li className="titleBox title02">
                                            반응형, 페스티벌 소개
                                        </li>
                                        <li className="titleBox title03">
                                            서울 캠퍼스타운 입주 기업을 대상으로 개최한 
                                            캠퍼스타운 페스티벌
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={`${process.env.PUBLIC_URL}/images/portfolio_banner_example.png`} alt="서울 캠퍼스타운 성과발표회" className="pc_img"/>
                            <img src={`${process.env.PUBLIC_URL}/images/portfolio_banner_example.png`} alt="서울 캠퍼스타운 성과발표회" className="mobile_img"/>
                            <div className="titleContainer">
                                <div className="inner">
                                    <div className="titleWrap">
                                        <li className="titleBox title01">
                                            2022 서울 캠퍼스타운 페스티벌 사이트 제작 서울 캠퍼스타운 입주 기업을 대상으로 개최한 
                                            캠퍼스타운 페스티벌 
                                        </li>
                                        <li className="titleBox title02">
                                            반응형, 페스티벌 소개서울 캠퍼스타운 입주 기업을 대상으로 개최한 
                                            캠퍼스타운 페스티벌 
                                        </li>
                                        <li className="titleBox title03">
                                            서울 캠퍼스타운 입주 기업을 대상으로 개최한 
                                            캠퍼스타운 페스티벌 서울 캠퍼스타운 입주 기업을 대상으로 개최한 
                                            캠퍼스타운 페스티벌 
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={`${process.env.PUBLIC_URL}/images/portfolio_banner_example.png`} alt="서울 캠퍼스타운 성과발표회" className="pc_img"/>
                            <img src={`${process.env.PUBLIC_URL}/images/portfolio_banner_example.png`} alt="서울 캠퍼스타운 성과발표회" className="mobile_img"/>
                            <div className="titleContainer">
                                <div className="inner">
                                    <div className="titleWrap">
                                        <li className="titleBox title01">
                                            2022 서울 캠퍼스타운 페스티벌 사이트 제작
                                        </li>
                                        <li className="titleBox title02">
                                            반응형, 페스티벌 소개
                                        </li>
                                        <li className="titleBox title03">
                                            서울 캠퍼스타운 입주 기업을 대상으로 개최한 
                                            캠퍼스타운 페스티벌
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="btnWrap">
                        <div className="inner">
                            <ul className="btnBox">
                                <li><img src={`${process.env.PUBLIC_URL}/images/project_slide_arrow.png`} alt="prevBtn" ref={swiperNavigationPrevRef}/></li>
                                <li><img src={`${process.env.PUBLIC_URL}/images/project_slide_arrow.png`} alt="nextBtn" ref={swiperNavigationNextRef}/></li>
                            </ul>
                        </div>
                    </div>
                </SwiperConatiner> */
            }
            <ContentContainer>
                <div className="inner">
                    <div className="titleBox">
                        <p className="t1">다양한 산업과 업종에 걸쳐 성공적으로 완수된</p>
                        <p className="t2">포트폴리오로 증명하겠습니다. </p>
                        <ul class="toggleList">
                            <li>
                                <button type="button" class={(category=='all')?'item active':'item'} onClick={() => menuChangeHandler('all')}>전체보기</button>
                            </li>
                            <li>
                                <button type="button" class={(category=='기업 소개')?'item active':'item'} onClick={() => menuChangeHandler('기업 소개')}>기업 소개</button>
                            </li>
                            <li>
                                <button type="button" class={(category=='랜딩페이지')?'item active':'item'} onClick={() => menuChangeHandler('랜딩페이지')}>랜딩페이지</button>
                            </li>
                            <li>
                                <button type="button" class={(category=='프랜차이즈')?'item active':'item'} onClick={() => menuChangeHandler('프랜차이즈')}>프랜차이즈</button>
                            </li>
                            <li>
                                <button type="button" class={(category=='플랫폼')?'item active':'item'} onClick={() => menuChangeHandler('플랫폼')}>플랫폼</button>
                            </li>
                            <li>
                                <button type="button" class={(category=='법률·세무')?'item active':'item'} onClick={() => menuChangeHandler('법률·세무')}>법률·세무</button>
                            </li>
                            <li>
                                <button type="button" class={(category=='여행·숙박')?'item active':'item'} onClick={() => menuChangeHandler('여행·숙박')}>여행·숙박</button>
                            </li>
                            <li>
                                <button type="button" class={(category=='공기업')?'item active':'item'} onClick={() => menuChangeHandler('공기업')}>공기업</button>
                            </li>
                            <li>
                                <button type="button" class={(category=='공모전·페스티벌')?'item active':'item'} onClick={() => menuChangeHandler('공모전·페스티벌')}>공모전·페스티벌</button>
                            </li>
                            <li>
                                <button type="button" class={(category=='온라인 쇼핑몰')?'item active':'item'} onClick={() => menuChangeHandler('온라인 쇼핑몰')}>온라인 쇼핑몰</button>
                            </li>
                        </ul>
                    </div>{/* titleBox end */}
                    
                    <div className="listContainer">
                        <AdminMenu 
                            boardName = "project"
                            type="list"
                        />
                        <ul className="list">
                            { (projectData) ? 
                                projectData.map((project) => (
                                <li key={project.id}>
                                    <Link to={`./view/${project.id}`}>
                                        <div className="item">
                                        <div className="imgBox" style={{ backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}/uploads/project/${project.projectFile1}")` }}></div>
                                        <div className="hoverBox">
                                            <div className="content">
                                            <h3>{project.projectName}</h3>
                                            <img src={`${process.env.PUBLIC_URL}/images/main_portfolio_arti.svg`} alt="arrow" className="arrow" />
                                            </div>
                                        </div>
                                        </div>
                                    </Link>
                                </li>
                                ))
                                : null
                            }

                        </ul>
                    </div>
                </div>
                
                

            </ContentContainer>
            <ServiceBottom />
        </div>
    );
}


const ContentContainer = styled.article`

    padding-bottom: 300px;

    .titleBox{ 
        padding:65px 0 8px 0; text-align:center; border-bottom: 1px solid #b4b4b4;
        .t1{ font-size:20px; font-weight:600; }
        .t2{ font-size:28px; font-weight:700; margin-top: 10px;  }
    }

    .produceContainer{
        padding:42px 0 50px 0; 
        > .title{ font-size:20px; text-align:center; font-weight:600; margin-bottom:20px; }
        .logoBox{ 
            display: flex; flex-wrap: wrap; justify-content: center; align-items: center;
            max-width: 800px; margin: 0 auto; 
            > li{ 
                width: calc(100% / 4);  padding: 5px;
                > img { max-width: 100%; display: block; margin: 0 auto; }
            }
        }
    }

    .toggleList{ display:flex; margin-top:77px; flex-wrap:wrap; }
    .toggleList > li{ padding-right:34px; position:relative; display:flex; align-items:center; }
    .toggleList > li:last-child{ padding-right:0%; }
    .toggleList > li:after{ content:'|'; position:absolute; right:17px; color:#E4E4E4; top:10px; }
    .toggleList > li:last-child:after{ display:none; }
    .toggleList > li .item{ cursor:pointer; background-color:transparent; border:1px solid transparent; color:#7F7F7F; font-size:14px; position:relative; padding:10px 5px 0 0; }
    .toggleList > li .item.active{ color:#222; font-weight:700; }
    .toggleList > li .item.active:after{ content:''; display:block; width:6px; height:6px; border-radius:50%; background-color:#FF4D00; position:absolute; right:0px; top:2px; }

    @media screen and (max-width: 1250px) {
        padding-bottom: 0;
        .inner{ padding:0; width:100%;  }
        .titleBox{ width:95%; margin:0 auto; }
    }
    @media screen and (max-width: 650px) {
        .titleBox{ 
            .t1{ font-size:18px; }
            .t2{ font-size:20px; }
        }
        .produceContainer{
            > .title{ font-size:18px; }
        }
    }
`;

const SwiperConatiner = styled.article`

    position:relative; background-color: #F6F7F8;

    .btnWrap{ 
        position:absolute; width: 100%; height: 100%; 
        left: 0; top: 0;
        .inner{ position:relative; width:100%; height:100%; }
        .btnBox{ 
            position:absolute; width:100%; left:0; bottom:7%; 
            height: 20px; z-index: 2; display: flex; justify-content: space-between; align-items: center;
            li{ 
                padding:10px;
                img{ display:block; cursor:pointer; position:relative; z-index:2; }
            }
            li:nth-child(2){ 
                img{ transform:scaleX(-1); }
            } 
        }
    }

    .swiperContainer{
        background-color:#F6F7F8; 
        .swiper-wrapper{
            .swiper-slide{  
                height: auto; display: flex; flex-direction: column;
                > img{ 
                    width:100%; 
                    &.mobile_img{ display:none; }
                }
            }
        }
    }
    .titleContainer{ 
        padding:30px 0; position: relative; z-index: 2; background-color: #F6F7F8; flex-grow: 1;
        .inner{ display:flex; align-items: center; height:100%; }
        .btn{ 
            padding:10px; 
            img{ width:100%; display:block; cursor:pointer; } 
            &.nextBtn{ img{ transform:scaleX(-1) } }
        }
        .titleWrap{ 
            flex-grow:1; display:flex; align-items:flex-start; 
            padding: 0 70px;
            > li{ 
                padding: 10px; 
                &::before{ content:''; display:block; width:80px; height:4px; background-color:#222; margin-bottom:10px; }
                &:nth-child(1){ 
                    width:40%; font-size: 20px; font-weight: 700; line-height: 1.5em;
                }
                &:nth-child(2){ width:20%; line-height: 1.5em; }
                &:nth-child(3){ width:40%; line-height: 1.5em; }
            }
        }
    }


    @media screen and (max-width: 1250px) {
        .btnWrap{ display:none; }
        .titleContainer{ 
            .btn{ 
                img{ width:3vw; }
            }
            .titleWrap{
                padding:0;
                >li{
                    font-size: 1.3vw;
                    &::before{ width:40px; height:2px;  }
                    &:nth-child(1){ font-size:1.6vw; }
                }
            }
        }
    }
    @media screen and (max-width: 950px) {
        .titleContainer{ 
            .titleWrap{
                flex-wrap: wrap; text-align: center;
                >li{ 
                    &::before{ display:none; } padding: 0;
                    &:nth-child(1){ width:100%; font-size:18px; }
                    &:nth-child(2){ width:100%; font-size:14px; margin-top:5px; color: #7f7f7f; }
                    &:nth-child(3){ width:100%; font-size:14px; margin-top:5px; }
                }
            }
        }
    } 

    @media screen and (max-width: 560px) {
        .swiperContainer{
            .swiper-wrapper{
                .swiper-slide{ 
                    img{ 
                        &.pc_img{ display:none; }
                        &.mobile_img{ display:block; }
                    }
                }
            }
        }
        .titleContainer{ 
            padding:18px 0;
            .titleWrap{
                >li{ 
                    font-size: 14px;
                    &:nth-child(1){ font-size:16px; }
                }
            }
        }
    }

`;



export default Project;