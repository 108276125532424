import { useState } from "react";
/* DatePicker */
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from 'react-router-dom';

/* Axios */
import axios from 'axios';
/* uuid 랜덤 자동생성 */
import {v4 as uuidv4} from 'uuid';


const ProjectWrite = () => {
    
    const navigate = useNavigate();

    //Submit
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('projectName', inputValues.projectName);
            formData.append('projectCompany', inputValues.projectCompany);
            formData.append('projectIntro', inputValues.projectIntro);
            formData.append('projectCategory', inputValues.projectCategory);
            formData.append('projectUrl', inputValues.projectUrl);
            formData.append('projectColor1', inputValues.projectColor1);
            formData.append('projectColor2', inputValues.projectColor2);
            formData.append('projectColor3', inputValues.projectColor3);
            formData.append('projectColorM', inputValues.projectColorM);
            formData.append('projectFile1', inputValues.projectFile1);
            formData.append('projectFile2', inputValues.projectFile2);
            formData.append('projectFile3', inputValues.projectFile3);
            formData.append('projectFile4', inputValues.projectFile4);
            formData.append('projectFile5', inputValues.projectFile5);
            // formData.append('projectStart', inputValues.projectStart);
            // formData.append('projectEnd', inputValues.projectEnd);
            formData.append('projectOrder', inputValues.projectOrder);
            const checkboxes = document.querySelectorAll('input[name="category"]:checked');
            const selectedCategories = Array.from(checkboxes).map(checkbox => checkbox.value).join('|');
            if(selectedCategories==''){
                alert('카테고리를 선택해주세요.');
                return false;
            }
            formData.append('projectCategory2', selectedCategories);
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/project/write`, formData);
                if (response.status === 200) {
                    const insertedId = response.data.insertedId; // 삽입된 ID 값
                    const redirectURL = `/project/view/${insertedId}`; // 리디렉션할 URL 생성
                    console.log(response);
                    navigate(redirectURL);
                } else {
                  throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }


        } catch (error) {
            console.error(error);
        }
    };
    

    //input 정의
    const [inputValues, setInputValues] = useState({
        projectName: '',
        projectCompany: '',
        projectCategory: '',
        projectIntro: '',
        projectStart: '',
        projectEnd: '',
        projectUrl: '',
        projectColor1: '#000000',
        projectColor2: '#000000',
        projectColor3: '#000000',
        projectColorM: '#000000',
        projectFile1: null,
        projectFile2: null,
        projectFile3: null,
        projectFile4: null,
        projectFile5: null,
        projectOrder: '',
    });

    //input 핸들러
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
        }));
    };
    //file 핸들러
    const handleFileChange = (event) => {
        const { name, files } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: files[0],
        }));
    };

    //date 핸들러
    const handleDateChange = (name, value) => {
        setInputValues((prevInputValues) => ({
          ...prevInputValues,
          [name]: value,
        }));
    };

    return(
        <div className="rootConatiner">
            <div className="inner"> 
                <form onSubmit={onSubmitHandler}>
                    <div className="write_page">
                        <ul className="full_box title_box">
                            <li>
                                <span className="label">카테고리 *</span>
                                <span className="label_content">
                                    <label>
                                        <input type="checkbox" name="category" value="기업 소개" />
                                        <sapn>기업 소개</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="랜딩페이지" />
                                        <sapn>랜딩페이지</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="프랜차이즈" />
                                        <sapn>프랜차이즈</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="플랫폼" />
                                        <sapn>플랫폼</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="법률·세무" />
                                        <sapn>법률·세무</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="여행·숙박" />
                                        <sapn>여행·숙박</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="공기업" />
                                        <sapn>공기업</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="공모전·페스티벌" />
                                        <sapn>공모전·페스티벌</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="온라인 쇼핑몰" />
                                        <sapn>온라인 쇼핑몰</sapn>
                                    </label>
                                </span>
                            </li>
                        </ul>
                        <ul className="half_box title_box">
                            <li>
                                <span className="label">프로젝트명 *</span>
                                <span className="label_content">
                                    <input 
                                        type="text" 
                                        name="projectName"
                                        value={inputValues.projectName}
                                        onChange={handleInputChange}
                                        maxLength={255}
                                        required 
                                        placeholder="프로젝트명을 입력해주세요."
                                    />
                                </span>
                            </li>
                            <li>
                                <span className="label">회사명(고객사) *</span>
                                <span className="label_content">
                                    <input 
                                        type="text"
                                        name="projectCompany"
                                        value={inputValues.projectCompany}
                                        onChange={handleInputChange}
                                        required
                                        maxLength={255} 
                                        placeholder="회사명, 고객사를 입력해주세요."
                                        />
                                </span>
                            </li>
                        </ul>
                        <ul className="half_box title_box">
                            <li>
                                <span className="label">홈페이지 유형 *</span>
                                <span className="label_content">
                                    <select 
                                        name="projectCategory" 
                                        onChange={handleInputChange} 
                                        value={inputValues.projectCategory}
                                        required>
                                        <option value="">홈페이지 유형을 선택해주세요</option>
                                        <option value="반응형 홈페이지">반응형 홈페이지</option>
                                        <option value="PC">PC</option>
                                        <option value="모바일">모바일</option>
                                    </select>
                                </span>
                            </li>
                            <li>
                                <span className="label">간단 소개 *</span>
                                <span className="label_content">
                                    <input 
                                        type="text" 
                                        name="projectIntro"
                                        value={inputValues.projectIntro}
                                        onChange={handleInputChange}
                                        required
                                        maxLength={255} 
                                        placeholder="ex ) 리뉴얼 , 랜딩페이지 제작 등"
                                        />
                                </span>
                            </li>
                        </ul>
                        {/* <ul className="half_box title_box">
                            <li>
                                <span className="label">참여기간(시작) *</span>
                                <span className="label_content">
                                <ReactDatePicker 
                                    name="projectStart"
                                    placeholderText="YYYY-MM-DD"
                                    selected={inputValues.projectStart}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(value) => handleDateChange('projectStart', value)}
                                    className="datepicker"
                                />
                                </span>
                            </li>
                            <li>
                                <span className="label">참여기간(종료)</span>
                                <span className="label_content">
                                <ReactDatePicker 
                                    name="projectEnd"
                                    placeholderText="YYYY-MM-DD"
                                    selected={inputValues.projectEnd}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(value) => handleDateChange('projectEnd', value)}
                                    className="datepicker"
                                />
                                </span>
                            </li>
                        </ul> */}
                        <ul className="full_box title_box">
                            <li>
                                <span className="label">홈페이지 URL</span>
                                <span className="label_content">
                                    <input 
                                        type="text" 
                                        name="projectUrl"
                                        value={inputValues.projectUrl}
                                        onChange={handleInputChange}
                                        placeholder="홈페이지 URL을 입력해주세요."
                                    />
                                </span>
                            </li>
                            <li>
                                <span className="label">순서</span>
                                <span className="label_content">
                                    <input 
                                        type="text" 
                                        name="projectOrder"
                                        value={inputValues.projectOrder}
                                        onChange={handleInputChange}
                                        placeholder="순서를 입력해주세요."
                                    />
                                </span>
                            </li>
                        </ul>
                        <ul className="half_box title_box">
                            <li>
                                <span className="label">색상 #1</span>
                                <span className="label_content">
                                    <input 
                                        type="color" 
                                        name="projectColor1"
                                        value={inputValues.projectColor1}
                                        onChange={handleInputChange}
                                        />
                                </span>
                            </li>
                            <li>
                                <span className="label">색상 #2</span>
                                <span className="label_content">
                                    <input
                                        type="color" 
                                        name="projectColor2"
                                        value={inputValues.projectColor2}
                                        onChange={handleInputChange}
                                    />
                                </span>
                            </li>
                            <li>
                                <span className="label">색상 #3</span>
                                <span className="label_content">
                                    <input
                                        type="color" 
                                        name="projectColor3"
                                        value={inputValues.projectColor3}
                                        onChange={handleInputChange}
                                    />
                                </span>
                            </li>
                            <li>
                                <span className="label">색상 #모바일</span>
                                <span className="label_content">
                                    <input
                                        type="color" 
                                        name="projectColorM"
                                        value={inputValues.projectColorM}
                                        onChange={handleInputChange}
                                    />
                                </span>
                            </li>
                        </ul>

                        <div className="add_form">
                            {/* <div className="add_box link_box"><input name="" type="text" placeholder="링크를 입력해주세요." /></div>
                            <div className="add_box link_box"><input name="" type="text" placeholder="링크를 입력해주세요." /></div> */}
                            <div className="add_box file_box">
                                <p className="help">썸네일 이미지(960 * 800)</p>
                                <input 
                                    name="projectFile1"
                                    onChange={handleFileChange}
                                    type="file"
                                />
                            </div>
                            {/* <div className="file_del_box">
                                <label htmlFor="file_01" className="file_title"><input type="checkbox" id="file_01"/>file.jpg(11.4K) 파일 삭제</label>
                            </div> */}
                            
                            <div className="add_box file_box">
                                <p className="help">배너 이미지 ( 1400 * 350 )</p>
                                <input 
                                    name="projectFile2"
                                    onChange={handleFileChange}
                                    type="file"
                                />
                            </div>
                            {/* <div className="file_del_box">
                                <label htmlFor="file_02" className="file_title"><input type="checkbox" id="file_02"/>file.jpg(11.4K) 파일 삭제</label>
                            </div> */}

                            {/* <div className="add_box file_box">
                                <p className="help">회사로고</p>
                                <input name="" type="file"/>
                            </div>
                            <div className="file_del_box">
                                <label htmlFor="file_02" className="file_title"><input type="checkbox" id="file_02"/>file.jpg(11.4K) 파일 삭제</label>
                            </div> */}

                            <div className="add_box file_box">
                                <p className="help">(상세) 반응형 </p>
                                <input 
                                    name="projectFile3"
                                    onChange={handleFileChange}
                                    type="file"
                                />
                            </div>
                            {/* <div className="file_del_box">
                                <label htmlFor="file_02" className="file_title"><input type="checkbox" id="file_02"/>file.jpg(11.4K) 파일 삭제</label>
                            </div> */}

                            <div className="add_box file_box">
                                <p className="help">(상세) 메인화면 </p>
                                <input 
                                    name="projectFile4"
                                    onChange={handleFileChange}
                                    type="file"
                                />
                            </div>
                            {/* <div className="file_del_box">
                                <label htmlFor="file_02" className="file_title"><input type="checkbox" id="file_02"/>file.jpg(11.4K) 파일 삭제</label>
                            </div> */}
                            
                            <div className="add_box file_box">
                                <p className="help">(상세) 모바일 이미지 </p>
                                <input 
                                    name="projectFile5"
                                    onChange={handleFileChange}
                                    type="file"
                                />
                            </div>
                            {/* <div className="file_del_box">
                                <label htmlFor="file_02" className="file_title"><input type="checkbox" id="file_02"/>file.jpg(11.4K) 파일 삭제</label>
                            </div> */}
                        </div>

                        <div className="write_btn_box">
                            <button type="button" className="cancelBtn" onClick={() => {navigate(-1)}}>취소</button>
                            <button type="submit" className="submitBtn">작성완료</button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    );
}

export default ProjectWrite;