import ServiceBottom from 'components/ServiceBottom';
import { useEffect } from 'react';
import styled, {keyframes} from 'styled-components';
import { Helmet } from 'react-helmet';

const About = () => {

    return(
        <div className="rootConatiner">
            <Helmet>
                {/* SEO 코드를 넣는 부분*/}
                <title>잡오브브라더스 : ABOUT</title>
                <meta name="description" content="잡오브브라더스는 웹개발 전문으로, 온라인 비즈니스 전문업체입니다." />
            </Helmet>
            {/* <BannerBox>
                <div className='bgWrap'>
                    <div className="blackBg"></div>
                </div>
                <div className="inner">
                    <div className="contentBox">
                        <div className='title1'>Online Business</div>
                        <div className="title2">맞춤형 웹사이트 전문 제작</div>

                        <div className="content">
                            청년창업사관학교 출신기업<br/>
                            책임감과 신뢰를 바탕으로 이용자에게 최적화된 웹사이트를 구현합니다.
                        </div>
                    </div>
                    <div className="computerBox">
                        <img src={`${process.env.PUBLIC_URL}/images/about_jb_ring.png`} alt="JOB OF BROTHERS" className="jb_ring"/>
                        <img src={`${process.env.PUBLIC_URL}/images/about_jb_computer.png`} alt="JOB 컴퓨터 이미지" className="computer" />
                    </div>
                </div>
            </BannerBox> */}
            <AboutUs className="ani">
                <div className="bg_line"></div>
                <div className="inner">
                    <ul className="contentWrap">
                        <li className="imgBox">
                            <div className="img"></div>
                        </li>
                        <li className="contentBox">
                            <p className="title">About Us</p>
                            <p className="content01">
                                잡오브브라더스는 웹 개발 전문으로<br/>
                                온라인 비즈니스 전문 업체입니다.<br/><br/>
                                <b>
                                    기획부터 결과물까지 모든 과정을 고객과 함께하며, <br/>
                                    믿음에 책임감으로 답변해드리겠습니다. 
                                </b>
                            </p>
                        </li>
                    </ul>
                </div>
            </AboutUs>
            <Product className="ani">
                <div className='inner'>
                    <ul className="productList">
                        <li>
                            <div className="bubble">
                                정부 인증 기업
                            </div>
                            <div className="item">
                                <ProductIcon iconImg={`${process.env.PUBLIC_URL}/images/about_icon01.svg`}/>
                                <div className="content">
                                    예비창업패키지, 청년창업사관학교 등<br/>
                                    국가 인증 지원사업을 받은 기업입니다.
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="bubble">
                                신뢰 있는 레퍼런스
                            </div>
                            <div className="item">
                                <ProductIcon iconImg={`${process.env.PUBLIC_URL}/images/about_icon02.svg`}/>
                                <div className="content">
                                    대기업, 공공기업, 대학기관,기부단체 등<br/>
                                    다양한 고객사와 함께 걸어왔습니다.
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="bubble">
                                체계적인 프로세스
                            </div>
                            <div className="item">
                                <ProductIcon iconImg={`${process.env.PUBLIC_URL}/images/about_icon03.svg`}/>
                                <div className="content">
                                    다년간 만들어진 총 8단계로 구성된<br/>
                                    전문가들의 탄탄한 프로세스를 <br/>
                                    경험해보세요
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="bubble">
                                고객 맞춤 서비스
                            </div>
                            <div className="item">
                                <ProductIcon iconImg={`${process.env.PUBLIC_URL}/images/about_icon04.svg`}/>
                                <div className="content">
                                    기획안, 절차안내, 사용방법까지<br/>
                                    번거러운 행정 절차를 함께 합니다.
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </Product>
            <ProduceText 
                iconImg={`${process.env.PUBLIC_URL}/images/example_img.png`}
            >
                <div className="inner">
                    <div className="contentBox">
                        <div className="txt">
                            {/* <div className="logoBox"></div> */}
                            100 여개의 프로젝트 진행 결과, <br className="mobileBr"/>계약 파기율은 0%입니다.<br/>
                            항상 고객의 입장에서 생각하는  <br className="mobileBr"/>잡오브브라더스가 되기 위해 노력하겠습니다.
                        </div>
                    </div>
                </div>
            </ProduceText >
            <OnlineBusiness 
                className="ani"
                iconImg1={`${process.env.PUBLIC_URL}/images/about_online_img01.png`}
                iconImg2={`${process.env.PUBLIC_URL}/images/about_online_img02.png`}
                iconImg3={`${process.env.PUBLIC_URL}/images/about_online_img03.png`}
                iconImg4={`${process.env.PUBLIC_URL}/images/about_online_img04.png`}
            >
                <div className="bgBox"></div>
                <div className="inner">
                    <div className='titleBox'>
                        <p className="t1">Professional</p>
                        <p className="t2">잡오브브라더스의 전문 분야</p>
                    </div>
                    <ul className="itemList">
                        <li>
                            <div className="item">
                                <div className="imgBox"></div>
                                <p className="title">전문 웹개발</p>
                                <p className="content">
                                    체계적인 프로세스와 고도화된 작업으로<br/>
                                    클라이언트의 아이디어 현실화
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="imgBox"></div>
                                <p className="title">UI/UX 디자인</p>
                                <p className="content">
                                    끊임없는 사용자를 위한 UI/UX 시스템 연구로<br/>
                                    대체할 수 없는 가치를 가시화
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="imgBox"></div>
                                <p className="title">유지보수</p>
                                <p className="content">
                                    템플릿 형태의 웹빌더가 아닌 솔루션 사용으로<br/>
                                    지속적인 개발기능 추가
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="imgBox"></div>
                                <p className="title">지원사업</p>
                                <p className="content">
                                    OO의 정부지원사업 활동의 노하우로<br/>
                                    든든한 개발사 가능
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </OnlineBusiness>
            <Process className="ani">
                <div className="inner">
                    <CommonTitle>
                        <p className="t1">체계적인 구축 과정의 프로세스</p>
                        <p className="t2">자세한 커뮤니션과 함께 진행합니다</p>
                    </CommonTitle>
                    <div className="contentBox">
                        <ul className="circleBox">
                            <li>
                                <div className="circle">
                                    <p className="t1">Discovery</p>
                                </div>
                            </li>
                            <li>
                                <div className="circle">
                                    <p className="t1">Design</p>
                                </div>
                            </li>
                            <li>
                                <div className="circle">
                                    <p className="t1">Development</p>
                                </div>
                            </li>
                        </ul>
                        <ul className="lineBox">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                        <ul className="itemBox">
                            <li>
                                <div className="item">1. 제작문의 이후 개발 및 디자인 기획</div>
                            </li>
                            <li>
                                <div className="item">2. 견적 확인 및 계약 진행</div>
                            </li>
                            <li>
                                <div className="item">3. 실무자와 채팅방 개설</div>
                            </li>
                            <li>
                                <div className="item">4. 디자인 시안 제작</div>
                            </li>
                            <li>
                                <div className="item">5. 디자인 시안 컨펌</div>
                            </li>
                            <li>
                                <div className="item">6. 개발 작업 진행</div>
                            </li>
                            <li>
                                <div className="item">7. 모든 작업자와 최종 검수</div>
                            </li>
                            <li>
                                <div className="item">8. 백업 파일 및 사용 안내서 제공</div>
                            </li>
                        </ul>
                        
                    </div>
                    
                </div>
            </Process>
            <Partners className="ani">
                <div className="inner whiteBg">
                    <CommonTitle>
                        <p className="t1">잡오브브라더스와 함께하는 기업들</p>
                        <p className="t2">클라이언트의 아이디어에 가치를 더해드립니다 </p>
                    </CommonTitle>

                    <ul className="partnerList">
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo01.png`} alt="서울특별시" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo02.png`} alt="매일경제" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo03.png`} alt="MBN" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo04.png`} alt="서울신용보증재단" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo05.png`} alt="월미당" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo06.png`} alt="CAU 중앙대학교 " />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo07.png`} alt="Save the Children" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo08.png`} alt="MINERVA BACCALAUREATE" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo09.png`} alt="법무법인 한미" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo10.png`} alt="DR.STRENGTH" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo11.png`} alt="ECOPACK-IT" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo12.png`} alt="Outdo" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo13.png`} alt="성결대학교" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo14.png`} alt="UNITA" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo15.png`} alt="미래를꿈구는사람들" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo16.png`} alt="SUPERBEBN.tv" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo17.png`} alt="법무법인 온조" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo18.png`} alt="KCA (사)한국코치협회" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo19.png`} alt="문화프레임 빅데이터연구소" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo20.png`} alt="마법이일어나는 오즈영어" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo21.png`} alt="OPENINGS" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo22.png`} alt="Anyparking" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo23.png`} alt="APGREEN" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo24.png`} alt="SOLVIST WORLDWIDE" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo25.png`} alt="101 DESIGN WORKS" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo26.png`} alt="Gnetworks" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo27.png`} alt="Anetworks" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo28.png`} alt="재단법인 여민재단" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo29.png`} alt="공인나눔번역센터" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/partner_logo30.png`} alt="홍삼만" />
                        </li>
                    </ul>
                </div>
                
            </Partners>
            <ServiceBottom />
        </div>
    )
}



/* animation */
const rotate = keyframes` /* 2. css코드를 씀. */
0%{
    transform: rotate(0deg);
    border-radius: 0px;
}
50%{ 
    border-radius: 100px;
}
100%{
    transform: rotate(350deg);
    border-radius: 0px;
}
`;
const BannerBox = styled.article`
    position: relative; padding: 80px 0 100px 0;
    .bgWrap{ 
        position:absolute; left:0; top:0; width:100%; height:100%;
        .blackBg{ height:100%; width:75%; background-color:var(--main-color);  }
    }  
    .inner{ position:relative; z-index: 1; display:flex; flex-wrap:wrap; align-items:flex-end; }
    .contentBox{ 
        width:50%; color: #fff; 
        .title1{ font-size:56px; font-weight:700;  }
        .title2{ 
            font-size:36px; font-weight:600; 
            &::after{ content:''; display:block; margin:20px 0; width:80px; height:4px; background-color:#fff; }
        }
        .content{ font-size:18px; line-height:1.5em; }
    }
    .computerBox{ 
        width:50%; position:relative; 
        .jb_ring{ 
            position:absolute; left:-18%; top:-12%; width: 50%;
            animation: ${rotate} 30s linear infinite;
        }
        .computer{ position:relative; z-index:1; width:100%; transform:translateY(50px); display:block; }
    }

    @media screen and (max-width: 1250px) {
        .contentBox{
            .title1{ font-size:40px; }
            .title2{ 
                font-size:30px; 
                &::after{ width:40px; height:2px; }
            }
            .content{ font-size:16px; }
        }
    }
    @media screen and (max-width: 750px) {
        .bgWrap{
            .blackBg{ width:100%; }
        }
        .contentBox{ 
            width:100%; text-align:center;  
            .title2{ &::after{ margin:20px auto; } }
        }
        .computerBox{ 
            width: 100%; margin-top: 50px;
            .computer{ max-width:500px; margin:0 auto; }
        }
    }
    @media screen and (max-width: 650px) {
        .contentBox{
            .title1{ font-size:30px; }
            .title2{ font-size:18px; }
            .content{ font-size:14px }
        } 
    }
`;
const AboutUs = styled.article`
    padding:50px 0; position:relative; overflow: hidden;
    .bg_line{ background-color:#F6F7F8; width:100%; height:55%; position:absolute; bottom:0; }
    .inner{ 
        position:relative; z-index:1; 
        .contentWrap{ 
            display:flex; flex-wrap:wrap; align-items: center;
            > li{ width:50%; }
            .imgBox{ 
                padding-right:50px;
                .img{ 
                    background-image: url('${process.env.PUBLIC_URL}/images/about_about_arti_img.png'); width:100%; height:500px; border-radius: 20px;
                    background-position: center; background-repeat: no-repeat; background-size: cover;
                }
             }
            .contentBox{ 
                .title{ 
                    font-size:56px; font-weight:600; position:relative; 
                    transform: translateX(-50px); opacity: 0; transition: transform 1s, opacity 1s;
                }
                .content01{ 
                    margin-top:20px; line-height:1.5em; font-size:18px; 
                    transform: translateX(-50px); opacity: 0; transition: transform 1s, opacity 1s;
                }
            }
        }
    }
    &.on{ 
        .inner{
            .contentWrap{
                .contentBox{
                    .title{ transform: translateX(0); opacity: 1; }
                    .content01{ transform: translateX(0); opacity: 1; }
                }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .bg_line{ height:58%; }
        .inner{
            .contentWrap{
                .imgBox{
                    .img{ height:40vw; }
                }
                .contentBox{ 
                    .title{ font-size:40px; }
                    .content01{ font-size:16px; }
                }
            }
        }
    }
    @media screen and (max-width: 800px) {
        .bg_line{ height:58%; }
        .inner{
            .contentWrap{
                .imgBox{
                    padding-right:20px;
                    .img{ height:43vw; }
                }
                .contentBox{ 
                    .title{ font-size:30px; }
                }
            }
        }
    }
    @media screen and (max-width: 750px) {
        .inner{
            .contentWrap{
                >li{ width:100%; }
                .imgBox{ 
                    padding-right:0;  
                    .img{ height:60vw; }
                }
                .contentBox{ margin-top:30px; text-align:center; }
            }
        }
    }
    @media screen and (max-width: 650px) {
        .inner{
            .contentBox{
                .title{ font-size:20px; }
                .content01{ font-size:14px; }
            }
        }
    }
`;
const Product = styled.article`
    padding:50px 0; overflow: hidden;
    .productList{ 
        display:flex; flex-wrap:wrap; width: calc(100% + 20px); margin-left: -10px;
        > li{ 
            transform: translateY(50px); opacity: 0; transition: transform 1s, opacity 1s;
            width:calc(100% / 4); padding:10px; 

            &:nth-child(2){ transition-delay:0.1s; }
            &:nth-child(3){ transition-delay:0.3s; }
            &:nth-child(4){ transition-delay:0.5s; }

            .bubble{ 
                background-color:#222; color:#fff; text-align:center; padding:15px 0; border-radius:20px; 
                font-size:18px; max-width:230px; margin:0 auto 20px auto; position:relative; 
                transition:transform 0.5s;
                &::after{ 
                    content:''; position:absolute; width:0; height:0; bottom:-10px;  
                    left:calc(50% - 10px); 
                    border-top:10px solid #222; border-left:10px solid transparent;
                    border-right:10px solid #222; border-bottom:10px solid transparent;
                    transform: rotate(135deg); 
                }
            }
            .item{ 
                border:2px solid #e4e4e4; border-radius:20px; padding:10px; height:320px; 
                display:flex; flex-direction:column; align-items:center; justify-content:center; text-align: center;
                line-height: 1.5em; box-shadow: 3px 8px 13px rgba(0, 0, 0, 0.15);
                .content{ margin-top:20px; }
            }
            &:hover{
                .bubble{ transform:translateY(-10px) }
            }
        }
    }

    &.on{ 
        .productList{ 
            > li{ 
                transform: translateY(0); opacity: 1;
            }
        }
    }

    @media screen and (max-width: 1250px) { 
        .productList{
            >li{
                .bubble{ font-size:16px; padding:10px; border-radius:10px; }
                &::after{ 
                    bottom:-10px; left:calc(50% - 10px); 
                    border-top:5px solid #222; border-left:5px solid transparent;
                    border-right:5px solid #222; border-bottom:5px solid transparent;
                }
                .item{ 
                    height:calc(100% - 40px); padding: 20px 5px;
                    .content{ font-size:1.3vw; line-height:1.4em; }
                }
            }
        }
    }
    @media screen and (max-width: 950px) { 
        .productList{
            >li{
                width: calc(100% / 2); padding: 20px 10px;
                .item{ 
                    .content{ font-size:16px; }
                }
            }
        }
    }
    @media screen and (max-width: 650px) { 
        .productList{
            >li{
                .item{ 
                    box-shadow: unset;
                    .content{ font-size:14px; }
                }
                &:hover{
                    .bubble{ transform:translateY(0) }
                }
            }
        }
    }
    @media screen and (max-width: 500px) { 
        .productList{
            flex-wrap:wrap; width: calc(100% + 10px); margin-left: -5px;
            >li{
                padding: 20px 5px;
                .bubble{ font-size:2.7vw; }
                .item{ 
                    .content{ font-size:2.7vw; }
                }
            }
        }
    }
`;
const ProductIcon = styled.article`
    width:140px; height:90px; background-size:contain; background-repeat:no-repeat; background-position:center;
    background-image: ${(props) => props.iconImg ? `url(${props.iconImg})` : `url(${process.env.PUBLIC_URL}/images/example_img.png)` };

    @media screen and (max-width: 1250px) { 
        width:100%; height:6.5vw; 
    }
    @media screen and (max-width: 950px) { 
        height:10vw; 
    }
    @media screen and (max-width: 500px) { 
        height:13vw; 
    }
`;
const ProduceText = styled.article`
    background-color: var(--main-color); padding: 100px 0;
    text-align: center; color: #fff; 
    .logoBox{ 
        margin:0 auto; width:380px; height:120px; background-repeat: no-repeat; background-position: center; background-size: contain; 
        margin-bottom:10px;
        background-image: ${(props) => props.iconImg ? `url(${props.iconImg})` : `url(${process.env.PUBLIC_URL}/images/example_img.png)` };
        & + .contentBox{ margin-top: 50px; }
    }
    .contentBox{ 
        font-size:25px; line-height:1.4em; 
        display:flex; justify-content: center; align-items:center;
        ::before{ 
            content:''; width:30px; height:30px; background-image:url('${process.env.PUBLIC_URL}/images/about_quote.png'); background-size:contain; 
            background-repeat:no-repeat; background-position:left top;  
        } 
        ::after{ 
            content:''; width:30px; height:30px; background-image:url('${process.env.PUBLIC_URL}/images/about_quote.png'); background-size:contain; 
            background-repeat:no-repeat; background-position:left bottom; transform:scaleX(-1) scaleY(-1);
        }
        .txt{ 
            padding:0 20px; margin-top:10px; word-break:keep-all; 
            .mobileBr{ display:none; }
        }
    }

    @media screen and (max-width: 1250px) { 
        .logoBox{ width:300px; height:90px;  }
        .contentBox{ 
            font-size:22px; 
            ::before{ width:20px; }
            ::after{ width:20px; }
            .txt{ padding:0 10px; }
        }
    }
    @media screen and (max-width: 650px) { 
        padding:50px 0;
        .logoBox{ width:200px; height:80px;  }
        .contentBox{ 
            font-size:18px; padding:0; 
            .txt{ 
                margin-top:2vw; margin:0; 
                .mobileBr{ display:inline-block; }
            }
        }
    }
`;
const OnlineBusiness = styled.article`
    padding: 100px 0; position: relative; overflow: hidden;
    .bgBox{ position:absolute; left:0; bottom:0; width:100%; height:calc(100% - 135px); background-color:#F6F7F8;  }
    .inner{ position:relative; z-index:1; }

    .titleBox{ 
        font-weight:600; position: relative; display: flex; flex-direction: column; justify-content: center;
        ::before{ 
            content:''; display:block;  width: 100%; height: 2px; margin-bottom:30px; background-color: #222; 
            position: absolute; left:calc(-100% - 20px); 
        }
        .t1{ font-size:56px; transform:translateX(-50px); opacity:0; transition:transform 1s, opacity 1s; }
        .t2{ font-size:28px; transform:translateX(-50px); opacity:0; transition:transform 1s, opacity 1s; }
    }

    .itemList{ 
        margin-top:50px; display:flex; flex-wrap:wrap; width:calc(100% + 20px); margin-left:-10px; 
        > li{
            width: calc(100% / 4); padding: 10px;
            transform: translateY(50px); opacity: 0; transition: transform 1s, opacity 1s;

            .item{ 
                width: 100%; height: 100%; 
                .imgBox{ 
                    background-color:#fff; height:22vw; max-height:390px; border-radius:10px; 
                    box-shadow: rgba(0, 0, 0, 0.15) 3px 8px 13px; background-size: cover; background-repeat: no-repeat; 
                    background-position: center;
                }
                .title{ margin-top:20px; font-size:24px; font-weight:600; }
                .content{ margin-top:10px; line-height:1.3em; }
            }
            :nth-child(1){
                .item{
                    .imgBox{  background-image: ${(props) => props.iconImg1 ? `url(${props.iconImg1})` : `url(${process.env.PUBLIC_URL}/images/example_img.png)` }; }
                }
            }
            :nth-child(2){
                transition-delay: 0.1s;
                .item{
                    .imgBox{  background-image: ${(props) => props.iconImg2 ? `url(${props.iconImg2})` : `url(${process.env.PUBLIC_URL}/images/example_img.png)` }; }
                }
            }
            :nth-child(3){
                transition-delay: 0.3s;
                .item{
                    .imgBox{  background-image: ${(props) => props.iconImg3 ? `url(${props.iconImg3})` : `url(${process.env.PUBLIC_URL}/images/example_img.png)` }; }
                }
            }
            :nth-child(4){
                transition-delay: 0.5s;
                .item{
                    .imgBox{  background-image: ${(props) => props.iconImg4 ? `url(${props.iconImg4})` : `url(${process.env.PUBLIC_URL}/images/example_img.png)` }; }
                }
            }
        }
    }

    /* animation */
    &.on{
        .titleBox{ 
            .t1{ transform:translateX(0); opacity:1; }
            .t2{ transform:translateX(0); opacity:1; }
        }

        .itemList{
            > li{ transform: translateY(0); opacity: 1;  }
        }
    }

    @media screen and (max-width: 1250px) { 
        .bgBox{ height:calc(100% - 124px); }
        .titleBox{ 
            .t1{ font-size:40px; }
            .t2{ font-size:24px; }
        }

        .itemList{ 
            > li{
                .item{
                    .imgBox{ height:20vw; max-height:unset; }
                    .title{ font-size:16px; }
                    .content{ font-size:1.3vw; }
                }
            }
        }
    }
    @media screen and (max-width: 950px) { 
        .titleBox{ 
            ::before{
                left:calc( -100% - 5px)
            }
        }
        .itemList{ 
            > li{
                width:calc(100% / 2);
                .item{
                    .imgBox{ height:42vw; }
                    .title{ font-size:20px; }
                    .content{ font-size:16px; }
                }
            }
        }
    }
    @media screen and (max-width: 650px) { 
        padding:50px 0;
        .bgBox{ height:calc(100% - 66px); }
        .titleBox{ 
            .t1{ font-size:30px; }
            .t2{ font-size:16px; }
            ::before{ margin-bottom:20px; }
        }
        .itemList{ 
            margin-top:50px; width:calc(100% + 10px); margin-left:-5px; 
            > li{ 
                padding:5px; 
                .item{
                    .imgBox{ box-shadow:unset; }
                    .title{ font-size:3.5vw; }
                    .content{ font-size:2.7vw; margin-top:5px; }
                }
            }
            
        }

    }
`;
const CommonTitle = styled.article`
    text-align: center;
    .t1{ 
        font-size:32px; font-weight:700; 
        &::before{ content:''; display:block; width:80px; height:4px; background-color:#222; margin:0 auto 15px auto; }
    }
    .t2{ font-size:24px; color:#7F7F7F; margin-top:10px; }

    @media screen and (max-width: 1250px) { 
        .t1{ 
            font-size:25px;
            &::before{ width:40px; height:2px; } 
        }
        .t2{ font-size:20px; }
     }
    @media screen and (max-width: 800px) { 
        .t1{ font-size:24px; }
        .t2{ font-size:16px; }
    }
`;
const Process = styled.article`
    padding: 100px 0; overflow: hidden;
    .contentBox{ 
        position:relative; margin-top: 50px;

        .itemBox{
            position: absolute; width: 100%; height: 100%; 
            z-index: 1; display: flex; flex-direction: column; align-items: flex-start;
            left:0; top:0; padding: 6% 0 6% 9%;
            /* transform:  translateX(calc((-100% + 280px) / 8)); background: rgba(0,0,0,0.5); */
            >li{ 
                padding: 10px; width: 100%; flex-grow:1; 
                transform: translateX(40px); opacity: 0; transition: transform 1.5s, opacity 1.5s;
                .item{ 
                    background-color:#fff; box-shadow: 4px 8px 14px rgba(0, 0, 0, 0.15); border-radius: 6px; padding:10px 15px 10px 30px; 
                    position: relative; display: flex; align-items: center; width: 280px; height: 100%;
                    ::before{ content:'|'; color: #d9d9d9; position:absolute; left:15px; font-weight:800; }
                }
                :nth-child(2){ padding-left:calc((100% - 280px) / 8 * 1 - 10px); }
                :nth-child(3){ padding-left:calc((100% - 280px) / 8 * 2 - 10px) ; }
                :nth-child(4){ padding-left:calc((100% - 280px) / 8 * 3 - 10px); }
                :nth-child(5){ padding-left:calc((100% - 280px) / 8 * 4 - 10px); }
                :nth-child(6){ padding-left:calc((100% - 280px) / 8 * 5 - 10px); }
                :nth-child(7){ padding-left:calc((100% - 280px) / 8 * 6 - 10px); }
                :nth-child(8){ padding-left:calc((100% - 280px) / 8 * 7 - 10px); }
            }
        }
        .lineBox{ 
            position:absolute; left:0; top:0; width:100%; height:100%; display: flex;
            padding: 100px 0;
            > li{ 
                flex-grow:1; border-right:1px solid #d9d9d9;   
                :last-child{ border-right:none; }
            }
        }
        .circleBox{
            display: flex; position: relative; justify-content: center;
            > li{ 
                width : calc(100% / 3.5); display: flex; align-items: center; justify-content: center;

                :nth-child(1){ transform:translateX(100%); opacity:0; transition:transform 1.5s, opacity 1s; }
                :nth-child(3){ transform:translateX(-100%); opacity:0; transition:transform 1.5s, opacity 1s; }

                .circle{ 
                    width:150%; border:1px solid var(--main-color); height:40vw; border-radius:50%; max-height:689.77px; 
                    color: var(--main-color); flex-shrink: 0;
                    .t1{ text-align:center; padding:40px 0; font-size:24px; font-weight:600; }
                }

            }

        }
    }

    /* animtaion */
    &.on{
        .itemBox{
            >li{
                transform: translateX(0); opacity: 1;
                :nth-child(2){ transition-delay:0.1s; }
                :nth-child(3){ transition-delay:0.2s; }
                :nth-child(4){ transition-delay:0.3s; }
                :nth-child(5){ transition-delay:0.4s; }
                :nth-child(6){ transition-delay:0.5s; }
                :nth-child(7){ transition-delay:0.6s; }
                :nth-child(8){ transition-delay:0.7s; }
                :nth-child(9){ transition-delay:0.8s; }
            }
        }
        .contentBox{
            .circleBox{ 
                >li{
                    :nth-child(1){ transform:translateX(0); opacity:1; }
                    :nth-child(3){ transform:translateX(0); opacity:1; }
                }
            }
        }
    }

    @media screen and (max-width: 1380px) {
        .contentBox{
            .itemBox{
                > li{
                    padding: 0.6vw;
                    .item{ font-size:1.1vw; }
                }
            }
        }
    }
    @media screen and (max-width: 1250px) {
        .contentBox{ 
            .circleBox{
                max-width: 700px; justify-content: center; margin: 0 auto;
                >li{ 
                    .circle{ 
                        display:flex; align-items:center; justify-content:center;  
                        height: 250px; width: 120%;
                        .t1{ font-size:17px; }
                    }
                }
            }
            .itemBox{ 
                position:relative; padding-left: 0; flex-direction: row; flex-wrap: wrap;
                padding: 0; margin-top: 40px;
                >li{ 
                    padding: 10px 5px; width: calc(100% / 2); flex-grow:1; 
                    .item{ 
                        width: 100%; font-size: 16px;
                        ::before{ content:'|'; color: #d9d9d9; position:absolute; left:15px; font-weight:800; }
                    }
                    :nth-child(2){ padding-left:0; }
                    :nth-child(3){ padding-left:0; }
                    :nth-child(4){ padding-left:0; }
                    :nth-child(5){ padding-left:0; }
                    :nth-child(6){ padding-left:0; }
                    :nth-child(7){ padding-left:0; }
                    :nth-child(8){ padding-left:0; }
                }
            }
            .lineBox{ display:none; }
        }

    }
    @media screen and (max-width: 750px) {
        .contentBox{ 
            .circleBox{
                >li{ 
                    padding: 5px 0;
                    .circle{ 
                        height:34vw; 
                        .t1{ font-size:2.8vw; }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 650px) {
        padding: 50px 0;
        .contentBox{ 
            .circleBox{
                >li{ 
                    width: calc(100% / 3.2);
                    .circle{ 
                        height: 36vw;
                    }
                }
            }
            .itemBox{ 
                >li{ 
                    width:50%; padding:5px 10px; 
                    :first-child{ width:100%; padding:5px 0; }
                    :last-child{ width:100%; }
                    .item{ font-size:14px; }
                }
            }
        }
    }
    @media screen and (max-width: 500px) {
        .contentBox{ 
            .itemBox{ 
                >li{ 
                    .item{ font-size:3vw; }
                }
            }
        }
    }

`;
const Partners = styled.article`
    padding: 100px 0; overflow: hidden; background-color: #F6F7F8;
    .inner{ 
        &.whiteBg{
            background-color:#fff; padding: 50px 10px;
        } 
    }
    ul{ 
        display:flex; flex-wrap:wrap; justify-content:center; align-items:center; margin-top: 50px;
        transform: translateY(50px); opacity: 0; transition: transform 1s, opacity 1s;
        > li{
            width:calc(100% / 6); padding:10px 20px; 
            img{ max-width:100%; display:block; margin:0 auto; }
        }
    }
   
    &.on{
        ul{ transform: translateY(0); opacity: 1; }
    }

    @media screen and (max-width: 800px) {
        ul{
            > li{ padding:10px; }
        }
    }
    @media screen and (max-width: 650px) {
        padding: 0 0 50px 0;
        ul{
            > li{ width:calc(100% / 5); }
        }
    }
    @media screen and (max-width: 500px) {
        ul{
            > li{ width:calc(100% / 3); padding:10px 20px; }
        }
    }

`;



export default About; 