// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MemberAdditional_memberContainer__A13ob{ border-radius: 20px; border: 1px solid #E2E8F0; background: #FFF; padding: 30px; }\n\n\n\n\n@media screen and (max-width: 650px) { \n\n    .MemberAdditional_memberContainer__A13ob{ border-radius: 10px; padding: 15px 10px; }\n\n}", "",{"version":3,"sources":["webpack://./src/routes/mypage/admin/MemberAdditional.module.css"],"names":[],"mappings":"AAAA,0CAAkB,mBAAmB,EAAE,yBAAyB,EAAE,gBAAgB,EAAE,aAAa,EAAE;;;;;AAKnG;;IAEI,0CAAkB,mBAAmB,EAAE,kBAAkB,EAAE;;AAE/D","sourcesContent":[".memberContainer{ border-radius: 20px; border: 1px solid #E2E8F0; background: #FFF; padding: 30px; }\n\n\n\n\n@media screen and (max-width: 650px) { \n\n    .memberContainer{ border-radius: 10px; padding: 15px 10px; }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"memberContainer": "MemberAdditional_memberContainer__A13ob"
};
export default ___CSS_LOADER_EXPORT___;
