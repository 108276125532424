import styled from 'styled-components';
const MyPageContentBox = styled.div`
    flex-grow: 1; overflow-y: scroll; 
    padding: 20px; flex-grow: 1;
    @media screen and (max-width: 1680px) {
        &{ width:100vw; padding:0; }
    }
`;
const MyPageScrollBox = styled.div`
    max-width:1400px; 
    padding: 20px 5px;
    h2{ font-size: 20px; font-weight:500; }
    @media screen and (max-width: 1680px) {
        & { width:100%; max-width:unset; padding:10px; margin-bottom:50px; padding-top:100px; }
    }
    @media screen and (max-width: 650px) {
        h2{ font-size: 18px; }
    }
    @media screen and (max-width: 400px) {
        &{ padding-top:80px; }
    }
`;
const MyPageContent = (props) => {
    return (
        <MyPageContentBox>
            <MyPageScrollBox>
                {props.children}
            </MyPageScrollBox>
        </MyPageContentBox>
    )
}

export default MyPageContent;