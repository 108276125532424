import "./Signup.css"
import {useState,useRef} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import { phone_check } from '../middlewares/textTest.js';

function Signup ({setPrivacyPopup, privacyPopup}) {

    const navigate = useNavigate();
    const agreeRef = useRef(null);
    const privacyPopupHandler = (e) => {
        e.preventDefault();
        console.log(privacyPopup)
        setPrivacyPopup(!privacyPopup);
    }
    
 //Submit
 const onSubmitHandler = async (e) => {

    e.preventDefault();
    try {
        if(agreeRef.current.checked === false){
            alert('개인정보 처리방침에 동의해주세요.');
            return false;
        }

        if(inputValues.checkId=="중복된 아이디입니다."){
            alert('다른 아이디를 사용해주세요.');
            return
        }
        if(inputValues.checkId=="4자리 이상 입력해주세요."){
            alert('아이디를 4자리 이상 입력해주세요.');
            return
        }
        if (inputValues.userPassword !== inputValues.userPassword2) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        if(inputValues.userPhone.length!=0){
            if(!phone_check(inputValues.userPhone)){
                alert('전화번호 형식이 맞지 않습니다.');
                return
            }
        }

        const formData = new FormData();
        formData.append('userId', inputValues.userId);
        formData.append('userPassword', inputValues.userPassword);
        formData.append('userName', inputValues.userName);
        formData.append('userCompany', inputValues.userCompany);
        formData.append('userPhone', inputValues.userPhone);
        formData.append('userEmail', inputValues.userEmail);
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/write`, formData);
            if (response.status === 200) {
                if(response.data.message){
                    alert(response.data.message);
                    return;
                }
                const redirectURL = `/login`; 
                alert('회원가입이 완료되었습니다.');
                navigate(redirectURL);
            } else {

              throw new Error('서버 응답 오류');
            }
        } catch (error) {
            console.log(error);
            alert(error);
        }
    } catch (error) {
        console.error(error);
    }
   
};
const [inputValues, setInputValues] = useState({
    userId: '',
    userPassword: '',
    userPassword2: '',
    userName: '',
    userCompany: '',
    userPhone: '',
    userEmail: '',
    checkId: '4자리 이상 입력해주세요.',
});


//input 핸들러
const handleInputChangeId = async (event) => {
    let { name, value } = event.target;
    let checkInfo;
    if(value.length<4){
        checkInfo="4자리 이상 입력해주세요.";
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
            checkId:checkInfo
        }));
        return
    }
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/checkId/${value}`);
    if (response.status === 200) {
        if(response.data.message=="S"){
            checkInfo="중복되지 않은 아이디입니다.";
        }else{
            checkInfo="중복된 아이디입니다.";
        }
    }
    setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: value,
        checkId:checkInfo
    }));

};


//input 핸들러
const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: value,
    }));
};


const phonehandleInputChange = (event) => {
    const { name, value } = event.target;
    const filteredValue = value.replace(/[^0-9]/g,"");
    event.target.value = filteredValue;
    setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: filteredValue,
    }));
};
    return (
        <div className="rootConatiner">
            <div className="signupContainer">
                <div className="inner">
                    <div className="titleBox">
                        <h2>Jobofbrothers</h2>
                        <p>서비스 가입하기</p>
                    </div>
                    <form onSubmit={onSubmitHandler}>
                    <div className="signupForm">
                        <p className="produce">가입 정보를 입력해주세요</p>
                        <ul className="formList">
                            <li className="half">
                                <label>아이디</label>
                                <div className="iptWrap">
                                    <input 
                                        type="text" 
                                        name="userId"
                                        onChange={handleInputChangeId}
                                        maxLength={255}
                                        className="ipt"
                                        placeholder="아이디를 입력해주세요"
                                    />
                                </div>
                            </li>
                            <li className="half checkId">
                                <div className={inputValues.checkId == '중복된 아이디입니다.' ? 'checkId_red' : inputValues.checkId == '4자리 이상 입력해주세요.' ? 'checkId_black':'checkId_blue' }>{inputValues.checkId}</div>
                            </li>
                            <li className="half">
                                <label>비밀번호</label>
                                <div className="iptWrap">
                                    <input 
                                        type="password" 
                                        name="userPassword"
                                        onChange={handleInputChange}
                                        maxLength={255}
                                        className="ipt"
                                        placeholder="비밀번호를 입력해주세요"
                                    />
                                </div>
                            </li>
                            <li className="half">
                                <label>비밀번호 확인</label>
                                <div className="iptWrap">
                                    <input 
                                        type="password" 
                                        name="userPassword2"
                                        onChange={handleInputChange}
                                        maxLength={255}
                                        className="ipt"
                                        placeholder="비밀번호를 다시한번 입력해주세요"
                                    />
                                </div>
                            </li>
                            <li className="half">
                                <label>이름</label>
                                <div className="iptWrap">
                                    <input 
                                        type="text" 
                                        name="userName"
                                        onChange={handleInputChange}
                                        maxLength={255}
                                        className="ipt"
                                        placeholder="이름을 입력해주세요."
                                    />
                                </div>
                            </li>


                            <li className="half">
                                <label>기관 혹은 기업명</label>
                                <div className="iptWrap">
                                    <input 
                                        type="text" 
                                        name="userCompany"
                                        onChange={handleInputChange}
                                        maxLength={255}
                                        className="ipt"
                                        placeholder="기업 또는 기관명을 입력해주세요."
                                    />
                                </div>
                            </li>
                            <li className="half">
                                <label>연락처</label>
                                <div className="iptWrap">
                                    <input 
                                        type="text" 
                                        name="userPhone"
                                        onChange={phonehandleInputChange}
                                        maxLength={11}
                                        className="ipt"
                                        placeholder="연락처를 입력해주세요."

                                    />
                                </div>
                            </li>
                            <li className="half">
                                <label>이메일</label>
                                <div className="iptWrap">                                    
                                    <input 
                                        type="email" 
                                        name="userEmail"
                                        onChange={handleInputChange}
                                        maxLength={255}
                                        className="ipt"
                                        placeholder="이메일을 입력해주세요"
                                    />
                                </div>
                            </li>
                        </ul>
                        <div className="checkboxWrap">
                            <input type="checkbox" id="agree" ref={agreeRef}/>
                            <label htmlFor="agree">개인정보 처리방침에 동의합니다. <span className="black" onClick={privacyPopupHandler}>(전문보기)</span></label>
                        </div>
                        <button className="okBtn">가입 완료</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Signup;