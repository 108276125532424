import ServiceBottom from 'components/ServiceBottom';
import styled from 'styled-components'
import { Helmet } from 'react-helmet';

const Service = () => {
    return (
        <div className="rootConatiner">
            <Helmet>
                {/* SEO 코드를 넣는 부분*/}
                <title>잡오브브라더스 : SERVICE</title>
                <meta name="description" content="잡오브브라더스 웹사이트 제작 서비스 안내" />
            </Helmet>
            <BannerArti>
                <div className="bgBlack"></div>
                <div className="inner">
                    <p className="title">
                        <span>About Service</span>
                    </p>
                </div>
            </BannerArti>
            <BackgroundArti className="ani">
                <div className="inner">
                    <TitleBox
                        textAlign="center"
                        color="#fff"
                        barColor="#fff"
                    >
                        <p className="title">Background</p>
                        <p className="title2">
                            웹 빌더로 제작하기 번거롭고<br/>
                            코딩으로 제작하기 불편한 웹 서비스
                        </p>
                        <p className="content">
                            어떠한 분야, 어떠한 소비자인지를 파악하고 / 분야에 맞는, <br className="mobileBr"/>소비자가 원하는 환경을 만드는 것<br/>
                            홈페이지는 소비자와 만나는 장소를 제작하는 것입니다.
                        </p>
                    </TitleBox>
                    <ul className="iconBox">
                        <li>
                            <div className="item icon">
                                <img src={`${process.env.PUBLIC_URL}/images/service_bg_icon01.png`} alt="노트 아이콘"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_bg_icon02.png`} alt="기업 이미지 서치"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_bg_icon03.png`} alt="도메인 / 호스팅 선택 "/>
                            </div>
                        </li>
                        <li>
                            <div className="item icon">
                                <img src={`${process.env.PUBLIC_URL}/images/service_bg_icon04.png`} alt="채팅 아이콘"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_bg_icon05.png`} alt="어려운 진행상황 파악"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_bg_icon06.png`} alt="직관적이지 않은 UI"/>
                            </div>
                        </li>
                        <li>
                            <div className="item icon">
                                <img src={`${process.env.PUBLIC_URL}/images/service_bg_icon07.png`} alt="퍼즐아이콘"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_bg_icon08.png`} alt="불확실한 소요시간"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_bg_icon09.png`} alt="유지보수 불가"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_bg_icon10.png`} alt="협업의 불편함"/>
                            </div>
                        </li>
                        <li>
                            <div className="item icon">
                                <img src={`${process.env.PUBLIC_URL}/images/service_bg_icon11.png`} alt="노트 아이콘"/>
                            </div>
                        </li>
                    </ul>
                </div>
            </BackgroundArti>
            <RecommendArti className="ani">
                <div className="inner">
                    <TitleBox
                        textAlign="center"
                        barColor="#637BFF"
                    >
                        <p className="title">웹사이트 제작 서비스 안내</p>
                        <p className="content">
                            웹사이트 제작 서비스 안내
                        </p>
                    </TitleBox>
                    <ul className="RecommendList">
                        <li>
                            <div className="item">
                                <div className="bubbleBox">
                                    기업가치 입증
                                </div>
                                <div className="contentBox">
                                    <div className="icon"></div>
                                    <div className="t1">
                                        홈페이지를 통해<br/>
                                        기업의 브랜딩을 소개하고 싶으신 분
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="bubbleBox">
                                    문의 & 구매유도
                                </div>
                                <div className="contentBox">
                                    <div className="icon"></div>
                                    <div className="t1">
                                        문의량과 구매유도를 적극적으로<br/>
                                        활용하실분
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="bubbleBox">
                                    아이디어 현실화
                                </div>
                                <div className="contentBox">
                                    <div className="icon"></div>
                                    <div className="t1">
                                        플랫폼, 특수 개발 등<br/>
                                        웹 기반 사업을 진행하실 분
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </RecommendArti>
            <ProduceArti className="ani">
                <div className="inner">
                    <TitleBox barColor="#637BFF">
                        <p className="title">
                            제작하는 과정을<br/>
                            더욱 쉽고 명확하게
                        </p>
                        <p className="content">
                            사이트 구축, 이전, 운영 노하우를 바탕으로 고객사의 시스템 상황을 면밀히 분석하여<br/>
                            고객사별로 맞춤형 디자인 및 개발을 진행합니다.
                        </p>
                    </TitleBox>
                    <ul className="list">
                        <li>
                            <div className="item">
                                <div className="imgBox"></div>
                                <p className="p1">직관적인 용어와 아이콘</p>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="imgBox"></div>
                                <p className="p1">피그마를 활용한 구조도 설계</p>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="imgBox"></div>
                                <p className="p1">명확한 코드 설계</p>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <div className="imgBox"></div>
                                <p className="p1">안정적인 서버 설계</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </ProduceArti>
            <AtoZArti className="ani">
                <div className="inner">
                    <TitleBox barColor="#637BFF" textAlign="center">
                        <p className="title">
                            A to Z 를 도와주는 전문 웹서비스
                        </p>
                        <p className="content">
                            당신의 아이디어가 웹으로 구체화되어 온라인상에 출시될 수 있도록 도움을 드립니다. <br/>
                            기획, 디자인, 개발 완료에 이르기까지 전문 서비스를 제공하며, 필요에 따라 전문가의 도움을 받을 수 있습니다.
                        </p>
                    </TitleBox>
                    <ul className="contentBox">
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_atoz_circle2.png`} alt="원"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_atoz_circle2.png`} alt="원"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_atoz_circle2.png`} alt="원"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_atoz_circle1.png`} alt="원"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_atoz_circle1.png`} alt="원"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_atoz_circle1.png`} alt="원"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_atoz_circle_front.png`} alt="프론트"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_atoz_circle_backend.png`} alt="백엔드"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_atoz_circle_branding.png`} alt="브랜드"/>
                            </div>
                        </li>
                        <li>
                            <div className="item">
                                <img src={`${process.env.PUBLIC_URL}/images/service_atoz_circle_design.png`} alt="디자인"/>
                            </div>
                        </li>
                    </ul>
                </div>
            </AtoZArti>
            <CareArti className="ani">
                <div className="inner">
                    <TitleBox barColor="#637BFF">
                        <p className="title">
                            정말 필요한 기능만<br/>
                            만들어둔 케어시스템
                        </p>
                        <p className="content">
                            잡오브브라더스는 100여개의 웹사이트 환경을 제작 경험을 바탕으로 <br/>
                            고객님들에게 정말 필요한 기능만을 제공합니다.
                        </p>
                    </TitleBox>
                </div>
                <div className="colorBg">
                    <ul className="list">
                        <li>
                            <div className="content">
                                <div className="item"></div>
                                <p className="title">맞춤형 제작</p>
                                <p className="txt">
                                    다년간 경험의 전문가들로<br/>
                                    아이디어를 구체화
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="content">
                                <div className="item"></div>
                                <p className="title">반응형 홈페이지</p>
                                <p className="txt">
                                    PC, 모바일 따로 제작할<br/>
                                    필요없이 한번에 해결
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="content">
                                <div className="item"></div>
                                <p className="title">합리적인 제작 비용</p>
                                <p className="txt">
                                    필요없는 기능을 분석하여<br/>
                                    합리적인 단가로 제공
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="content">
                                <div className="item"></div>
                                <p className="title">빠르고 안전한 호스팅</p>
                                <p className="txt">
                                    안전한 호스팅사 사용으로<br/>
                                    안정적인 데이터
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="content">
                                <div className="item"></div>
                                <p className="title">폭 넓은 서비스 제공</p>
                                <p className="txt">
                                    통계, 메세지 보내기 등<br/>
                                    기본 서비스 세팅 완료
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="content">
                                <div className="item"></div>
                                <p className="title">똑똑한 유지 보수</p>
                                <p className="txt">
                                    기능 추가, 디자인 수정 등<br/>
                                    확장성이 넓은 유지보수
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="content">
                                <div className="item"></div>
                                <p className="title">데이터 백업</p>
                                <p className="txt">
                                    자동으로 데이터를 백업하여<br/>
                                    보안에 최적화
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="content">
                                <div className="item"></div>
                                <p className="title">검색 엔진 최적화</p>
                                <p className="txt">
                                    검색 엔진 최적화 (seo) 작업으로<br/>
                                    키워드 검색 상위 노출
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="content">
                                <div className="item"></div>
                                <p className="title">사이트 제작 설명서</p>
                                <p className="txt">
                                    제작 완료 후  관리가 용이한<br/>
                                    사용 설명서 제공
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="content">
                                <div className="item"></div>
                                <p className="title">고급 유료 이미지</p>
                                <p className="txt">
                                    합법적이고 고급스러운<br/>
                                    이미지 사용
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </CareArti>
            <ResponsiveArti 
                imgBox={`${process.env.PUBLIC_URL}/images/service_portfolio.png`}
                className="ani"
            >
                <div className="inner">
                    <TitleBox barColor="#637BFF" color='#637BFF' textAlign="center">
                        <p className="title">
                            디바이스별 깔끔한 반응형 대응을 진행합니다.
                        </p>
                    </TitleBox>
                    <ul className="contentWrap">
                        <li>
                            <div className="imgBox"></div>
                        </li>
                        <li>
                            <div className="contentBox">
                                <p className="t1">
                                    기업의 전문성을 살리기 위해선 고객군들의 <br/>
                                    디바이스별로 반응형 대응을 진행해야 합니다.
                                </p>
                                <p className="t2">
                                    아무리 로고와 브랜딩 스토리로 기업의 전문성을 살리더라도 홈페이지의 철자, 줄 간격,가독성 등을 <br/>
                                    고려하지 않은 채 개발이 진행된다면 온라인에서의 브랜딩 효과가 200% 이상 마이너스 될 것입니다.
                                </p>
                                <p className="t2">
                                    가령, 중요한 계약 건에 대한 PT를 진행하던 와중 PPT의 내용 중 맞춤법이 틀린다면,전문성이 <br/>
                                    확 떨어지는 것과 마찬가지일 것입니다. 당사는 전문 QA팀을 필두로 QA 프로세스를 통해 <br/>
                                    프로덕트의 품질을 보장합니다.
                                </p>
                                <a href="#" className="moreBtn">반응형 관련 칼럼 확인하기</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </ResponsiveArti>
            <ResponsiveArti 
                className="ani"
                imgBox={`${process.env.PUBLIC_URL}/images/service_ux_ui.png`}
                backgroundColor={`rgb(241, 245, 255)`}
                imgSize="small"
            >
                <div className="inner">
                    <TitleBox barColor="#637BFF" color='#637BFF' textAlign="center">
                        <p className="title">
                            유입부터 구매 전환까지 단계별 섹션을 설계합니다.
                        </p>
                    </TitleBox>
                    <ul className="contentWrap">
                        <li>
                            <div className="imgBox"></div>
                        </li>
                        <li>
                            <div className="contentBox">
                                <p className="t1">
                                    고객의 구매를 유도하기 위한 유저 플로우 및 <br/>
                                    단계별 Funnel을 설계하고 고객이 행동하기에 <br/>
                                    가장 편안한 UX 디자인을 제공합니다.
                                </p>
                                <p className="t2">
                                    ‘전환으로 이어지기 전, 어떤 생각을 할까 ?’ 를 고민하며 전환이 일어날 
                                    수 밖에 없게끔 전략적으로 기획을 진행합니다.<br/>
                                    기획 이후 트렌디한 UI 디자인을 통해 최상의 고객 경험을 선물합니다
                                </p>
                                <a href="#" className="moreBtn">반응형 관련 칼럼 확인하기</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </ResponsiveArti>
            <ResponsiveArti 
                className="ani"
                imgBox={`${process.env.PUBLIC_URL}/images/service_seo.png`}
                imgSize="small"
            >
                <div className="inner">
                    <TitleBox barColor="#637BFF" color='#637BFF' textAlign="center">
                        <p className="title">
                            제작 후 각종 검색엔진의 상위노출이 가능하게 도와드립니다.
                        </p>
                    </TitleBox>
                    <ul className="contentWrap">
                        <li>
                            <div className="imgBox"></div>
                        </li>
                        <li>
                            <div className="contentBox">
                                <p className="t1">
                                    단순히 홈페이지 제작에서만 그치는 것이 아닙니다.
                                </p>
                                <ul className="stepList">
                                    <li>
                                        <div className="item">
                                            개발 시 검색엔진이 가장 우리의 홈페이지를 잘 잡아낼 수 있도록 테크니컬 SEO, 오프페이지 SEO, 콘텐츠 SEO 작업을 진행합니다.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            홈페이지의 데이터를 측정하기 위한 GA, GTM, Hotjar와 같은 추적 도구 세팅을 도와드립니다.
                                        </div>
                                    </li>
                                    <li>
                                        <div className="item">
                                            꾸준한 데이터 추적 & A/B 테스트를 진행하며 그로스 해킹을 진행할 수 있도록 도와드립니다.
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </ResponsiveArti>
            <ServiceBottom />
        </div>
    );
}

const BannerArti = styled.article`
    padding:100px 0 50px 0; position:relative; 
    .bgBlack{ 
        background-color:#222; position:absolute; height:calc(100% - 200px); width:65vw;
        top:0; left:0; background-image:${`url(${process.env.PUBLIC_URL}/images/service_banner_bg.png)`};
        background-repeat:no-repeat; background-size:80px; background-position:right 20px top 10px;
    }
    .inner{ 
        position:relative; z-index:1; background-image:${`url(${process.env.PUBLIC_URL}/images/service_banner_bg01.png)`}; 
        background-size:cover; background-repeat:no-repeat; background-position:center; overflow:hidden;
        height:55vw;border-radius:40px; max-height: 1000px; padding:40px 40px;
        display:flex; align-items:flex-end; justify-content:flex-end;
    }
    .title{
        display:flex; align-items:center; 
        &::before{ content:''; display:block; width:300px; height:1px; background-color:var(--main-color); margin-right:10px; }
        span{ font-size:40px; font-weight:300; }
    }
    @media screen and (max-width: 1250px) {
        .inner{ padding:30px; }
        .title{
            &::before{ width:150px;  }
            span{ font-size:25px; }
        }
    }
    @media screen and (max-width: 650px) {
        padding:50px 0 20px 0;
        .bgBlack{ width:90%; height:calc(100% - 50px); background-size:40px; }
        .inner{ height:70vw; border-radius:20px; padding:15px; }
        .title{ 
            &::before{ width:15vw;  }
            span{ font-size:4vw; } 
        }
    }
`;
const BackgroundArti = styled.article`
    padding: 100px 0 50px 0; background: linear-gradient(360deg, #F1F5FF 0%, rgba(241, 245, 255, 0) 22.5%), #6178FA;
    overflow: hidden;
    .iconBox{ 
        margin-top:50px; position:relative; height:300px; 
        > li{
            display: flex; align-items: center; justify-items: center;
             
            .item{ 
                position:absolute; color: #637BFF; font-weight: 700; font-size: 150%;
                width: 20%;
                transform: translateY(-100px); opacity: 0; transition:transform 1s, opacity 1s;
                &.icon{ 
                    width:8%; 
                }
                img{ width:100%;  }
            }
            :nth-child(1){ /* 노트 (이미지) */  .item{ left:2%; bottom:120px; transition-delay:0.3s; } }
            :nth-child(2){ /* 기업 이미지 서치 */ .item{ left:0; bottom:20px; transition-delay:0.2s; } }
            :nth-child(3){ /* 도메인 / 호스팅 선택 */ .item{ left:12%; bottom:130px; transition-delay:0.4s; } }
            :nth-child(4){ /* 채팅 (이미지) */ .item{ left:34%; bottom:140px; transition-delay:0.5s; }  }
            :nth-child(5){ /* 어려운 진행사항 파악 */ .item{ left:20%; bottom:0; } }
            :nth-child(6){ /* 직관적이지 않는 UI */ .item{ left:45%; bottom:90px; transition-delay:0.3s; } }
            :nth-child(7){ /* 퍼즐 (이미지) */ .item{ left:57%; bottom:175px; transition-delay:0.4s; } }
            :nth-child(8){ /* 불확실한 소요시간 */ .item{ left:65%; bottom:180px; transition-delay:0.5s; } }
            :nth-child(9){ /* 유지보수 불가 */ .item{ left:74%; bottom:90px; transition-delay:0.3s; } }
            :nth-child(10){ /* 협업의 불편함 */ .item{ left:65%; bottom:0px; } }
            :nth-child(11){ /* 메모 (이미지) */ .item{ left:92%; bottom:-10px; transition-delay:0.2s; } }
        }
    }

    &.on{ 
        .iconBox{
            >li{
                .item{ transform: translateY(0); opacity: 1; }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .iconBox{ 
            height: 22vw;
            > li{
                :nth-child(1){ /* 노트 (이미지) */ .item{ bottom:40%;  } }
                :nth-child(2){ /* 기업 이미지 서치 */ .item{ bottom:8%;  } }
                :nth-child(3){ /* 도메인 / 호스팅 선택 */ .item{ bottom:42%; } }
                :nth-child(4){ /* 채팅 (이미지) */ .item{ bottom:50%; }  }
                :nth-child(6){ /* 직관적이지 않는 UI */ .item{ bottom:35%;  } }
                :nth-child(7){ /* 퍼즐 (이미지) */ .item{ bottom:65%; } }
                :nth-child(8){ /* 불확실한 소요시간 */ .item{ bottom:65%; left: 67%; } }
                :nth-child(9){ /* 유지보수 불가 */ .item{ bottom:32%; } }
                :nth-child(11){ /* 메모 (이미지) */ .item{ bottom:-8%; } }
            }
        }
    }
    @media screen and (max-width: 1000px) {
        .iconBox{ 
            height: 22vw;
            > li{
                .item{ 
                    width:22%; 
                    &.icon{ width:12%; }
                }
                :nth-child(1){ /* 노트 (이미지) */ .item{ left:0;  } }
                :nth-child(2){ /* 기업 이미지 서치 */ .item{ bottom:10%;  } }
                :nth-child(3){ /* 도메인 / 호스팅 선택 */ .item{ bottom:45%; } }
                :nth-child(4){ /* 채팅 (이미지) */ .item{ bottom:42%; }  }
                :nth-child(6){ /* 직관적이지 않는 UI */ .item{ left:44%; bottom:25%;  } }
                :nth-child(7){ /* 퍼즐 (이미지) */ .item{ bottom:55%; } }
                :nth-child(8){ /* 불확실한 소요시간 */ .item{ bottom:60%; left: 70%; } }
                :nth-child(9){ /* 유지보수 불가 */ .item{ left:75%; bottom:25%; } }
                :nth-child(10){ /* 협업의 불편함 */ .item{ left:60%; bottom:-10%; } }
                :nth-child(11){ /* 메모 (이미지) */ .item{ bottom:-25%; left:90%; } }
            }
        }
    }
    @media screen and (max-width: 650px) {
        .iconBox{ 
            height: 29vw; 
            > li{
                .item{ 
                    width:30%; 
                    &.icon{ width:10%; }
                }
                :nth-child(1){ /* 노트 (이미지) */ .item{ bottom:47%;  } }
                :nth-child(2){ /* 기업 이미지 서치 */ .item{ bottom:16%;  } }
                :nth-child(3){ /* 도메인 / 호스팅 선택 */ .item{ left:10%; bottom:72%; } }
                :nth-child(4){ /* 채팅 (이미지) */ .item{ left:45%; bottom:65%; }  }
                :nth-child(5){ /* 어려운 진행상황 파악 */ .item{ left:15%; bottom:-10%; }  }
                :nth-child(6){ /* 직관적이지 않는 UI */ .item{ left:34%; bottom:35%;  } }
                :nth-child(7){ /* 퍼즐 (이미지) */ .item{ left:56%; bottom:65%; } }
                :nth-child(8){ /* 불확실한 소요시간 */ .item{ bottom:70%; left: 67%; } }
                :nth-child(9){ /* 유지보수 불가 */ .item{ left:70%; bottom:30%; } }
                :nth-child(10){ /* 협업의 불편함 */ .item{ left:50%; } }
                :nth-child(11){ /* 메모 (이미지) */ .item{ bottom:-8%; left:85%; } }
            }
        }
    }
`;
const RecommendArti = styled.article`
    padding: 100px 0; background-color: #F1F5FF;

    .RecommendList{ 
        margin-top:50px; display:flex; flex-wrap:wrap; width: calc(100% + 20px); margin-left: -10px;
        > li{ 
            width:calc(100% / 3); padding:10px; 
            transform: translateY(50px); opacity: 0; transition:transform 1s, opacity 1s;
            .item{ 
                .bubbleBox{ 
                    background-color:#6178FA; color:#fff; text-align:center; padding: 15px;
                    max-width: 250px; margin: 0 auto; border-radius: 50px; font-size: 20px;
                    position: relative; margin-bottom: 30px; display: flex; align-items: center; justify-content: center;
                    ::after{ 
                        content:''; display:block; width:0; height:0; 
                        position: absolute; transform:rotate(90deg);
                        border-bottom: 10px solid transparent;
                        border-top: 10px solid transparent;
                        border-left: 20px solid #6178FA;;
                        border-right: 20px solid transparent;
                        bottom: -20px;
                    }
                }
                .contentBox{ 
                    background-color:#fff; box-shadow: 6px 12px 14px rgba(0, 0, 0, 0.15); border-radius: 10px;
                    padding: 40px 10px; text-align: center;
                    .icon{ 
                        width:100%; height:40vw; margin:0 auto; max-height:306.67px; 
                        background-repeat: no-repeat; background-position: center; background-size: 50%;
                    }
                    .t1{ margin-top:20px; font-size:18px; line-height:1.5em; }
                }
            }

            &:nth-child(1){ 
                .item{
                    .contentBox{.icon{ background-image:url('${process.env.PUBLIC_URL}/images/service_recommend_img01.png'); }}
                }
            } 
            &:nth-child(2){ 
                .item{
                    .contentBox{.icon{ background-image:url('${process.env.PUBLIC_URL}/images/service_recommend_img02.png'); }}
                }
            } 
            &:nth-child(3){ 
                .item{
                    .contentBox{.icon{ background-image:url('${process.env.PUBLIC_URL}/images/service_recommend_img03.png'); }}
                }
            } 
        }
    }

    &.on{ 
        .RecommendList{ 
            >li{
                transform: translateY(0); opacity: 1;
                :nth-child(2){ transition-delay:0.2s; }
                :nth-child(3){ transition-delay:0.4s; }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .RecommendList{ 
            > li{ 
                .item{ 
                    .bubbleBox{ font-size:16px; }
                    .contentBox{ 
                        .icon{ height:20vw; }
                        .t1{ font-size:16px; }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 820px) {
        .RecommendList{ 
            width: calc(100% + 10px); margin-left: -5px;
            > li{ 
                padding: 5px;
                .item{ 
                    .bubbleBox{ font-size:2vw; }
                    .contentBox{ 
                        padding: 4vw 0.5vw;
                        .icon{ height:50vw; }
                        .t1{ font-size:2vw; }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 650px) {
        .RecommendList{ 
            > li{ 
                width: 100%;
                & + li{ margin-top:20px; }
                .item{ 
                    .bubbleBox{ font-size:14px; }
                    .contentBox{ 
                        max-width:350px; padding: 20px 10px; margin: 0 auto;
                        .t1{ font-size:14px; }
                    }
                }
            }
        }
    }

`;
const ProduceArti = styled.article`
    padding: 100px 0;
    .list{ 
        margin-top:50px; display:flex; flex-wrap: wrap; width: calc(100% + 20px); margin-left: -10px;
        > li{ 
            padding:10px; width:calc(100% / 4);  
            transform: translateY(50px); opacity: 0; transition: transform 1s, opacity 1s;
            .imgBox{ 
                background-color:#dfdfdf; height:21vw; max-height:390px; box-shadow: 6px 12px 14px rgba(0, 0, 0, 0.15);
                background-repeat: no-repeat; background-size: cover; background-position: center;
            }
            .p1{ margin-top:20px; font-size:20px; font-weight:600; }
            &:nth-child(1){ .imgBox{ background-image:url('${process.env.PUBLIC_URL}/images/service_produce_img01.png'); } }
            &:nth-child(2){ .imgBox{ background-image:url('${process.env.PUBLIC_URL}/images/service_produce_img02.png'); } }
            &:nth-child(3){ .imgBox{ background-image:url('${process.env.PUBLIC_URL}/images/service_produce_img03.png'); } }
            &:nth-child(4){ .imgBox{ background-image:url('${process.env.PUBLIC_URL}/images/service_produce_img04.png'); } }
        }
    }

    &.on{
        .list{
            >li{
                transform: translateY(0); opacity:1;
                :nth-child(2){ transition-delay:0.2s; }
                :nth-child(3){ transition-delay:0.4s; }
                :nth-child(4){ transition-delay:0.6s; }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .list{ 
            margin-top:20px;
            > li{ 
                .imgBox{ height:22vw; max-height:unset;  }
                .p1{ font-size:18px; }
            }
        }
    }
    @media screen and (max-width: 900px) {
        .list{ 
            > li{ 
                .p1{ font-size:16px; }
            }
        }
    }
    @media screen and (max-width: 850px) {
        .list{ 
            > li{ 
                width: calc(100% /2);
                .imgBox{ height:40vw; }
                .p1{ font-size:16px; text-align:center; }
            }
        }
    }
    @media screen and (max-width: 650px) {
        padding: 50px 0;
    }
    @media screen and (max-width: 500px) {
        .list{ 
            > li{ 
                .p1{ font-size:3vw; }
            }
        }
    }
`;
const AtoZArti = styled.article`
    padding: 100px 0;
    .contentBox{ 
        position:relative; margin-top:50px; height: 38vw; max-height:650px;
        >li{
            .item{ 
                position:absolute; 
                img{ display:block; width:100%; }
            }
            :nth-child(1){ .item{ width:26%; left:10%; top:0; } }
            :nth-child(2){ .item{ width:15%; left:40%; top:50%; } }
            :nth-child(3){ .item{ width:40%; right:10%;  } }
            :nth-child(4){ .item{ width:2%; left:38%; top:25%;  } }
            :nth-child(5){ .item{ width:3%; left:38.5%; top:65%;  } }
            :nth-child(6){ .item{ width:3%; left:65%; top:65%;  } }
            :nth-child(7){ 
                /* 프론트엔드 */
                .item{ width:6%; left:52%; top:10%; transform:scale(0); transition:transform 1s; } 
            }
            :nth-child(8){ 
                /* 백엔드 */
                .item{ width:10%; left:45%; top:40%; transform:scale(0); transition:transform 1s; } 
            }
            :nth-child(9){ 
                /* 브랜딩 */
                .item{ width:8%; left:25%; top:45%; transform:scale(0); transition:transform 1s; } 
            }
            :nth-child(10){ 
                /* 디자인 */
                .item{ width:7%; left:45%; top:76%; transform:scale(0); transition:transform 1s; } 
            }
        }
    }

    &.on{
        .contentBox{
            >li{
                :nth-child(7){ .item{ transform:scale(1);  } }
                :nth-child(8){ .item{ transform:scale(1); transition-delay:0.1s; } }
                :nth-child(9){ .item{ transform:scale(1); transition-delay:0.2s; } }
                :nth-child(10){ .item{ transform:scale(1); transition-delay:0.3s; } }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .contentBox{
            height: 47vw; max-height:unset;
            >li{
                :nth-child(1){ .item{ width:25%; left:0; } }
                :nth-child(2){ .item{ width:20%; left:40%; } }
                :nth-child(3){ .item{ width:50%; right:0%; } }
                :nth-child(4){ .item{ width:5%; left:32%; } }
                :nth-child(5){ .item{ width:4%; left:38%; } }
                :nth-child(6){ .item{ width:6%; top:55%;  } }
                :nth-child(7){ .item{ width:10%; left:52%; top:5%; } }
                :nth-child(8){ .item{ width:14%; left:43%; top: 36%; } }
                :nth-child(9){ .item{ width:12%; left:16%; top:30%; } }
                :nth-child(10){ .item{ width:11%; left:35%;  } }
            }
        }
    }
    @media screen and (max-width: 650px) {
        padding: 50px 0;
        .contentBox{
            >li{
                :nth-child(7){ .item{ width:14%; left:50%; top:0; } }
                :nth-child(8){ .item{ width:18%; left:40%;  } }
                :nth-child(9){ .item{ width:15%; left:12%; top:30%; } }
                :nth-child(10){ .item{ width:16%; left:unset; right:0; top: 69%; } }
            }
        }
    }
`;
const CareArti = styled.article`
    .colorBg{  padding:50px 0; overflow: hidden; background-color: #6178FA;  margin-top:50px; }
    .list{ 
        display:flex; flex-wrap: wrap; margin:0 auto;
        width: 95%; max-width: 1620px;
        > li{ 
            width:calc(100% / 5); padding:10px; 
            .content{ background-color:#fff; padding:40px 10px; border-radius:10px; text-align:center; }
            .item{ 
                background-color:rgba(97, 120, 250, 0.1); border-radius:50%; 
                width: 80px; height: 80px; margin:0 auto; background-repeat: no-repeat; background-position: center; background-size: 50%;
            }
            .title{ margin-top:10px; font-weight:700; } 
            .txt{ color: #7f7f7f; margin-top:8px; }
            transform: translateY(40px); opacity: 0; transition: transform 1s, opacity 1s;

            :nth-child(1){.item{ background-image:${`url(${process.env.PUBLIC_URL}/images/service_care_icon01.png)`}; }}
            :nth-child(2){.item{ background-image:${`url(${process.env.PUBLIC_URL}/images/service_care_icon02.png)`}; }}
            :nth-child(3){.item{ background-image:${`url(${process.env.PUBLIC_URL}/images/service_care_icon03.png)`}; }}
            :nth-child(4){.item{ background-image:${`url(${process.env.PUBLIC_URL}/images/service_care_icon04.png)`}; }}
            :nth-child(5){.item{ background-image:${`url(${process.env.PUBLIC_URL}/images/service_care_icon05.png)`}; }}
            :nth-child(6){.item{ background-image:${`url(${process.env.PUBLIC_URL}/images/service_care_icon06.png)`}; }}
            :nth-child(7){.item{ background-image:${`url(${process.env.PUBLIC_URL}/images/service_care_icon07.png)`}; }}
            :nth-child(8){.item{ background-image:${`url(${process.env.PUBLIC_URL}/images/service_care_icon08.png)`}; }}
            :nth-child(9){.item{ background-image:${`url(${process.env.PUBLIC_URL}/images/service_care_icon09.png)`}; }}
            :nth-child(10){.item{ background-image:${`url(${process.env.PUBLIC_URL}/images/service_care_icon10.png)`}; }}
            :nth-child(11){.item{ background-image:${`url(${process.env.PUBLIC_URL}/images/service_care_icon11.png)`}; }}
            :nth-child(12){.item{ background-image:${`url(${process.env.PUBLIC_URL}/images/service_care_icon12.png)`}; }}
        }
    }

    &.on{
        .list{
            >li{ 
                transform: translateY(0); opacity: 1;
            }
        }
    }

    @media screen and (max-width: 1260px) {
        .list{ 
            >li{
                .title{ font-size:1.3vw; }
                .txt{ font-size:1.15vw; }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .list{ 
            padding-left:0; 
            > li{ 
                width:calc(100% / 4);
                .content{ padding:25px 10px; }
                .title{ font-size:1.6vw; }
                .txt{ font-size:1.4vw; }
            }
        }
    }
    @media screen and (max-width: 1000px) {
        .list{ 
            padding-left:0;
            > li{ 
                width:calc(100% / 3);
                .item{ width:80px; height:80px; background-size:35%; }
                .title{ font-size:16px; }
                .txt{ font-size:14px; }
            }
        }
    }
    @media screen and (max-width: 650px) {
        padding:50px 0;
        .list{ 
            > li{ 
                width:calc(100% / 2); padding:5px;
                .item{ width:15vw; height:15vw; background-size:30%; }
                .title{ font-size:3vw; }
                .txt{ font-size:2.5vw; }
            }
        }
    }
`;
const TitleBox = styled.div`
    color: ${(props) => props.color ? `${props.color}` : `#222` };
    text-align : ${(props) => props.textAlign ? `${props.textAlign}` : `left` };

    .title{ 
        font-size:40px; font-weight:600; line-height:1.4em; 
        ::before{ 
            content:''; display:block; width:80px; height:4px; background-color:${(props) => props.barColor ? `${props.barColor}` : `#222` }; 
            
            margin: ${(props) => props.textAlign ? `0 auto 10px auto` : `0 0 10px 0` }; 
        }
        &+.content{ margin-top:20px; }
    }
    .title2{ margin-top:25px; font-size:30px;  }
    .content{ 
        margin-top:40px; line-height:1.5em; font-size:18px; 
        .mobileBr{ display:none;  }
    }

    @media screen and (max-width: 1250px) {
        .title{ 
            font-size:30px;
            ::before{ width:40px; }
        }
        .title2{ font-size:20px; }
        .content{ font-size:16px; }
    }
    @media screen and (max-width: 650px) {
        .title{ 
            font-size:25px;
            ::before{ width:20px; height:1.5px; }
        }
        .title2{ font-size:18px; }
        .content{ 
            font-size:14px; margin-top:20px;
            .mobileBr{ display:inline-block;  } 
        }
    }
`;
const ResponsiveArti = styled.article`
    padding:100px 0;
    background-color:${(props) => props.backgroundColor ? props.backgroundColor : 'transparent' };

    .contentWrap{ 
        display:flex; width: calc(100% + 20px); margin-left: -10px;
        margin-top: 70px; align-items: center;
        >li{ 
            padding: 10px;
            .imgBox{ 
                /* background-color:#dfdfdf;  */
                width: 500px; 
                height: ${(props) => (props.imgSize === "small") ? `350px`: `500px` };
                background-image: ${(props) => props.imgBox ? `url(${props.imgBox})` : `url(${process.env.PUBLIC_URL}/images/example_img.png)` };
                background-repeat:no-repeat; background-position:center; background-size:cover; 

                transform:translateX(50px); opacity:0; transition:transform 1s, opacity 1s;
            }
            .contentBox{
                padding-left: 40px;
                transform:translateX(-50px); opacity:0; transition:transform 1s, opacity 1s;
                .t1{ font-weight:700; font-size:24px; line-height:1.5em; }
                .t2{ margin-top:24px; line-height:1.5em; }
                .moreBtn{ 
                    background-color:#5D7DF6; color: #fff; display: inline-block;
                    margin-top: 40px; padding: 15px; border-radius: 20px; font-size: 18px;
                }


                .stepList{ 
                    margin-top:50px; width:calc(100% + 20px); margin-left:-10px;
                    > li{ 
                        padding:10px; 
                        .item{ 
                            background-color:#F1F5FF; padding:20px 15px 20px 40px; position:relative;
                            ::before{ content:'・'; position:absolute; left:20px;  }
                        }
                    }
                }
            } 
        }
    }   

    &.on{
        .contentWrap{ 
            > li{
                .imgBox{ transform:translateX(0); opacity:1; }
                .contentBox{ 
                    transform:translateX(0); opacity:1;
                }
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .contentWrap{
            >li{ 
                .imgBox{ 
                    width:450px; 
                    height: ${(props) => (props.imgSize === "small") ? `300px`: `450px` };
                }
                .contentBox{
                    padding-left:10px;
                    .t1{ font-size:20px; }
                    .t2{ br{ display:none; } }
                }
            }
        }
    }  
    @media screen and (max-width: 980px) {
        .contentWrap{ 
            flex-wrap:wrap; 
            >li{
                width:100%; 
                .imgBox{ margin:0 auto; }
                .contentBox{ 
                    margin-top:50px; text-align:center; padding-left:0;
                    .t2{ br{ display:inline-block; } }

                    .stepList{ margin-top:24px; text-align:left; }
                }
                
            }
        }
    } 

    @media screen and (max-width: 650px) {
        padding:50px 0;
        .contentWrap{ 
            margin-top:50px;
            >li{ 
                .imgBox{ 
                    width:70vw; 
                    height: ${(props) => (props.imgSize === "small") ? `45vw`: `70vw` };
                }
                .contentBox{
                    margin-top:24px;
                    .t1{ font-size:16px; }
                    .t2{ 
                        font-size:14px;
                        br{ display:none; } 
                    }
                    .moreBtn{ 
                        font-size:16px; padding:10px; border-radius:8px;
                    }

                    .stepList{ 
                        font-size:14px;  
                        .item{ 
                            padding:15px 15px 15px 20px;
                            ::before{ left:10px;  }
                        }
                    }
                }
            }
        }   
    }
`;



export default Service; 
