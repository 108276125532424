import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import MypageContainer from "../../components/mypage/MyPageContainer";
import MyPageContent from "../../components/mypage/MyPageContent";
import MyPageHeader from "../../components/mypage/MyPageHeader";
import MypageMenu from "../../components/mypage/MyPageMenu";

import styles from './Price.module.css'; // CSS Modules 파일 import
import { Link } from "react-router-dom";

const Price = () => {

    const menu01_Ref = useRef(null);
    const menu02_Ref = useRef(null);
    const menu03_Ref = useRef(null);

    const handleButtonClick1 = () => {
        if (menu01_Ref.current) {
            // 스크롤 위치로 이동
            menu01_Ref.current.scrollIntoView({
                behavior: 'smooth', // 부드러운 스크롤을 위해 smooth 옵션 사용
                block: 'start', // 영역의 시작 지점으로 이동
            });
        }
    };

    const handleButtonClick2 = () => {
        if (menu02_Ref.current) {
            // 스크롤 위치로 이동
            menu02_Ref.current.scrollIntoView({
                behavior: 'smooth', // 부드러운 스크롤을 위해 smooth 옵션 사용
                block: 'start', // 영역의 시작 지점으로 이동
            });
        }
    };


    const handleButtonClick3 = () => {
        if (menu03_Ref.current) {
            // 스크롤 위치로 이동
            menu03_Ref.current.scrollIntoView({
                behavior: 'smooth', // 부드러운 스크롤을 위해 smooth 옵션 사용
                block: 'start', // 영역의 시작 지점으로 이동
            });
        }
    };


    return(
        <div className="mypageContainer">
            <MypageMenu />
            <MypageContainer>
                <MyPageHeader  title="가격안내" />
                <MyPageContent>
                    <div className={styles.titleBox}>
                        <h1>유지보수 가격 안내</h1>
                        <ul className={styles.titleMenu}>
                            <li><div className={styles.menuItem} onClick={handleButtonClick1}>사이트 유지보수</div></li>
                            <li><div className={styles.menuItem} onClick={handleButtonClick2}>도메인 / 호스팅</div></li>
                            <li><div className={styles.menuItem} onClick={handleButtonClick3}>디자인 상품</div></li>
                        </ul>
                        <p className={styles.produce}>
                            작업범위, 서비스 안내 등 기타 문의사항이 있으면 <span className={styles.red}>채팅 서비스</span>를 이용해주세요.
                        </p>
                    </div>
                    <div className={styles.priceContainer}>
                        <div className={styles.categoryArti} ref={menu01_Ref}>
                            <h2>사이트 유지보수</h2>
                            <ul className={styles.itemList}>
                                <li>
                                    <div className={styles.item}>
                                        <h3>베이직</h3>
                                        <div className={styles.txt}>
                                            소규모 프로젝트로 일하는 신생 스타트업 및 개발 팀에 추천합니다. 
                                        </div>
                                        <div className={styles.price}>500,000원 부터</div>
                                        <Link to={{ pathname:"/mypage/price/view",search: `?paymentCategory=사이트 유지보수&paymentGrade=베이직`}}>
                                            <button type="button" className={styles.applyBtn}>문의하러 가기</button>
                                        </Link>
                                        <ul className={styles.subList}>
                                            <li>텍스트 수정 무제한 </li>
                                            <li>이미지 제작 월 2건</li>
                                            <li>팝업제작 분기별 1건</li>
                                            <li>배너 제작 </li>
                                            <li>이벤트 페이지 제작</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.item}>
                                        <h3>레어</h3>
                                        <div className={styles.txt}>
                                            소규모 프로젝트로 일하는 신생 스타트업 및 개발 팀에 추천합니다. 
                                        </div>
                                        <div className={styles.price}>1,000,000원 부터</div>
                                        <Link to={{ pathname:"/mypage/price/view",search: `?paymentCategory=사이트 유지보수&paymentGrade=레어`}}>
                                        <button type="button" className={styles.applyBtn}>문의하러 가기</button>
                                        </Link>
                                        <ul className={styles.subList}>
                                            <li>텍스트 수정 무제한 </li>
                                            <li>이미지 제작 월 2건</li>
                                            <li>팝업제작 분기별 1건</li>
                                            <li>배너 제작 </li>
                                            <li>이벤트 페이지 제작</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.item}>
                                        <h3>에픽</h3>
                                        <div className={styles.txt}>
                                            소규모 프로젝트로 일하는 신생 스타트업 및 개발 팀에 추천합니다. 
                                        </div>
                                        <div className={styles.price}>1,500,000원 부터</div>
                                        <Link to={{ pathname:"/mypage/price/view",search: `?paymentCategory=사이트 유지보수&paymentGrade=에픽`}}>
                                        <button type="button" className={styles.applyBtn}>문의하러 가기</button>
                                        </Link>
                                        <ul className={styles.subList}>
                                            <li>텍스트 수정 무제한 </li>
                                            <li>이미지 제작 월 2건</li>
                                            <li>팝업제작 분기별 1건</li>
                                            <li>배너 제작 </li>
                                            <li>이벤트 페이지 제작</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.item}>
                                        <h3>전설</h3>
                                        <div className={styles.txt}>
                                            소규모 프로젝트로 일하는 신생 스타트업 및 개발 팀에 추천합니다. 
                                        </div>
                                        <div className={styles.price}>2,000,000원 부터</div>
                                        <Link to={{ pathname:"/mypage/price/view",search: `?paymentCategory=사이트 유지보수&paymentGrade=전설`}}>
                                        <button type="button" className={styles.applyBtn}>문의하러 가기</button>
                                        </Link>
                                        <ul className={styles.subList}>
                                            <li>텍스트 수정 무제한 </li>
                                            <li>이미지 제작 월 2건</li>
                                            <li>팝업제작 분기별 1건</li>
                                            <li>배너 제작 </li>
                                            <li>이벤트 페이지 제작</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        
                        <div className={styles.categoryArti}  ref={menu02_Ref}>
                            <h2>도메인 / 호스팅</h2>
                            <ul className={styles.itemList}>
                                <li>
                                    <div className={styles.item}>
                                        <h3>신화</h3>
                                        <div className={styles.txt}>
                                            소규모 프로젝트로 일하는 신생 스타트업 및 개발 팀에 추천합니다. 
                                        </div>
                                        <div className={styles.price}>2,500,000원 부터</div>
                                        <Link to={{ pathname:"/mypage/price/view",search: `?paymentCategory=도메인 / 호스팅&paymentGrade=신화`}}>
                                        <button type="button" className={styles.applyBtn}>문의하러 가기</button>
                                        </Link>
                                        <ul className={styles.subList}>
                                            <li>텍스트 수정 무제한 </li>
                                            <li>이미지 제작 월 2건</li>
                                            <li>팝업제작 분기별 1건</li>
                                            <li>배너 제작 </li>
                                            <li>이벤트 페이지 제작</li>
                                        </ul>
                                    </div>
                                </li>
                                {/* <li>
                                    <div className={styles.item}>
                                        <h3>베이직</h3>
                                        <div className={styles.txt}>
                                            소규모 프로젝트로 일하는 신생 스타트업 및 개발 팀에 추천합니다. 
                                        </div>
                                        <div className={styles.price}>500,000원 부터</div>
                                        <button type="button" className={styles.applyBtn}>문의하러 가기</button>
                                        <ul className={styles.subList}>
                                            <li>텍스트 수정 무제한 </li>
                                            <li>이미지 제작 월 2건</li>
                                            <li>팝업제작 분기별 1건</li>
                                            <li>배너 제작 </li>
                                            <li>이벤트 페이지 제작</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.item}>
                                        <h3>베이직</h3>
                                        <div className={styles.txt}>
                                            소규모 프로젝트로 일하는 신생 스타트업 및 개발 팀에 추천합니다. 
                                        </div>
                                        <div className={styles.price}>500,000원 부터</div>
                                        <button type="button" className={styles.applyBtn}>문의하러 가기</button>
                                        <ul className={styles.subList}>
                                            <li>텍스트 수정 무제한 </li>
                                            <li>이미지 제작 월 2건</li>
                                            <li>팝업제작 분기별 1건</li>
                                            <li>배너 제작 </li>
                                            <li>이벤트 페이지 제작</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.item}>
                                        <h3>베이직</h3>
                                        <div className={styles.txt}>
                                            소규모 프로젝트로 일하는 신생 스타트업 및 개발 팀에 추천합니다. 
                                        </div>
                                        <div className={styles.price}>500,000원 부터</div>
                                        <button type="button" className={styles.applyBtn}>문의하러 가기</button>
                                        <ul className={styles.subList}>
                                            <li>텍스트 수정 무제한 </li>
                                            <li>이미지 제작 월 2건</li>
                                            <li>팝업제작 분기별 1건</li>
                                            <li>배너 제작 </li>
                                            <li>이벤트 페이지 제작</li>
                                        </ul>
                                    </div>
                                </li> */}
                            </ul>
                        </div>

                        <div className={styles.categoryArti}  ref={menu03_Ref}>
                            <h2>디자인 상품</h2>
                            <ul className={styles.itemList}>
                                <li>
                                    <div className={styles.item}>
                                        <h3>베이직</h3>
                                        <div className={styles.txt}>
                                            소규모 프로젝트로 일하는 신생 스타트업 및 개발 팀에 추천합니다. 
                                        </div>
                                        <div className={styles.price}>500,000원 부터</div>
                                        <Link to={{ pathname:"/mypage/price/view",search: `?paymentCategory=디자인 상품&paymentGrade=베이직`}}>
                                        <button type="button" className={styles.applyBtn}>문의하러 가기</button>
                                        </Link>
                                        <ul className={styles.subList}>
                                            <li>텍스트 수정 무제한 </li>
                                            <li>이미지 제작 월 2건</li>
                                            <li>팝업제작 분기별 1건</li>
                                            <li>배너 제작 </li>
                                            <li>이벤트 페이지 제작</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.item}>
                                        <h3>레어</h3>
                                        <div className={styles.txt}>
                                            소규모 프로젝트로 일하는 신생 스타트업 및 개발 팀에 추천합니다. 
                                        </div>
                                        <div className={styles.price}>1,000,000원 부터</div>
                                        <Link to={{ pathname:"/mypage/price/view",search: `?paymentCategory=디자인 상품&paymentGrade=레어`}}>
                                        <button type="button" className={styles.applyBtn}>문의하러 가기</button>
                                        </Link>
                                        <ul className={styles.subList}>
                                            <li>텍스트 수정 무제한 </li>
                                            <li>이미지 제작 월 2건</li>
                                            <li>팝업제작 분기별 1건</li>
                                            <li>배너 제작 </li>
                                            <li>이벤트 페이지 제작</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.item}>
                                        <h3>에픽</h3>
                                        <div className={styles.txt}>
                                            소규모 프로젝트로 일하는 신생 스타트업 및 개발 팀에 추천합니다. 
                                        </div>
                                        <div className={styles.price}>1,500,000원 부터</div>
                                        <Link to={{ pathname:"/mypage/price/view",search: `?paymentCategory=디자인 상품&paymentGrade=에픽`}}>
                                        <button type="button" className={styles.applyBtn}>문의하러 가기</button>
                                        </Link>
                                        <ul className={styles.subList}>
                                            <li>텍스트 수정 무제한 </li>
                                            <li>이미지 제작 월 2건</li>
                                            <li>팝업제작 분기별 1건</li>
                                            <li>배너 제작 </li>
                                            <li>이벤트 페이지 제작</li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.item}>
                                        <h3>전설</h3>
                                        <div className={styles.txt}>
                                            소규모 프로젝트로 일하는 신생 스타트업 및 개발 팀에 추천합니다. 
                                        </div>
                                        <div className={styles.price}>2,000,000원 부터</div>
                                        <Link to={{ pathname:"/mypage/price/view",search: `?paymentCategory=디자인 상품&paymentGrade=전설`}}>
                                        <button type="button" className={styles.applyBtn}>문의하러 가기</button>
                                        </Link>
                                        <ul className={styles.subList}>
                                            <li>텍스트 수정 무제한 </li>
                                            <li>이미지 제작 월 2건</li>
                                            <li>팝업제작 분기별 1건</li>
                                            <li>배너 제작 </li>
                                            <li>이벤트 페이지 제작</li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>
                </MyPageContent>
            </MypageContainer>
        </div>
    )
}

export default Price; 