
import React,{useContext,useState,useEffect } from "react";
import MypageContainer from "../../components/mypage/MyPageContainer";
import MyPageContent from "../../components/mypage/MyPageContent";
import MyPageHeader from "../../components/mypage/MyPageHeader";
import MypageMenu from "../../components/mypage/MyPageMenu";
import axios from 'axios';
import styles from './MypageChatList.module.css'; // CSS Modules 파일 import
import {useNavigate } from 'react-router-dom';




const MypageChatList = () => {
    const navigate = useNavigate();
    const [check, setCheck] = useState(null);
    const [userListData, setUserListData] = useState();

    useEffect(() => {
        const fetchUserList = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/manage/chat/userList/0`);
                if (response.status === 200) {
                    setUserListData(response.data.result);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }
        };
        fetchUserList(); // Pass the 'id' value to the fetchTotalData function
    }, []);







    const checkRoom = async (roomNum) => {
        // try {
        //     await setCheck(roomNum);
        //     const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/manage/chat/checkRoom/${roomNum}`);
        //     if (response.status === 200) {
                navigate(`/mypage/chat/${roomNum}`);
        //     } else {
        //         throw new Error('서버 응답 오류');
        //     }
        // } catch (error) {
        //     console.log(error);
        //     alert(error);
        // }
    };

    
    return(
        <div className="mypageContainer">
            <MypageMenu />
            <MypageContainer>
                <MyPageHeader title="채팅리스트" />
                <MyPageContent>
                    <div className={styles.projectContainer}>
                        <ul className={styles.listBox}>
                            <li className={styles.listTitle}>
                                <div className={styles.numberWrap}>번호</div>
                                <div className={styles.titleWrap}>회원 아이디</div>
                                <div className={styles.typeWrap}>대화하기</div>
                            </li>
                            { (userListData) ? 
                            userListData.map((user) => (
                            <li>
                                <div className={styles.numberWrap}>{user.id}</div>
                                <div className={styles.titleWrap}>{user.userEmail}</div>
                                <div className={styles.typeWrap}>
                                    <button onClick={() => checkRoom(user.id)}>참여</button>
                                </div>
                            </li>
                            ))
                            : null
                            }                  
                        </ul>
                    </div>
                </MyPageContent>
            </MypageContainer>
        </div>
    )
}
export default MypageChatList; 