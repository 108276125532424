import { useState } from "react";
import MypageContainer from "../../../components/mypage/MyPageContainer";
import MyPageContent from "../../../components/mypage/MyPageContent";
import MyPageHeader from "../../../components/mypage/MyPageHeader";
import MypageMenu from "../../../components/mypage/MyPageMenu";
import styles from './AdminBannerModify.module.css'; // CSS Modules 파일 import

const AdminBannerModify = () => {

    const [bgImage1, setBgImage1] = useState(null);
    const [bgImage2, setBgImage2] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        console.log(e.target.name)
        if (file && file.type.startsWith('image/')) {
            if(e.target.name === 'banner01'){
                setBgImage1(URL.createObjectURL(file));
            }else if(e.target.name === 'banner02'){
                setBgImage2(URL.createObjectURL(file));
            }
        }
    };

    return (
        <div className="mypageContainer">
            <MypageMenu />
            <MypageContainer>
                <MyPageHeader  title="배너관리" />
                <MyPageContent>
                    <div className={styles.modifyContainer}>
                        <ul className={styles.bannerFormList}>
                            <li>
                                <h2 className={styles.label}># 배너 01</h2>
                                <div className={styles.banner01} style={{ backgroundImage: `url(${bgImage1})` }}></div>
                                <input type="file" name="banner01" className={styles.file_ipt} onChange={handleImageChange}/>
                                <input type="text" name="" className={styles.link} placeholder="링크를 입력해주세요." />
                            </li>
                            <li>
                                <h2 className={styles.label}># 배너 02</h2>
                                <div className={styles.banner02} style={{ backgroundImage: `url(${bgImage2})` }}></div>
                                <input type="file" name="banner02" className={styles.file_ipt}  onChange={handleImageChange}/>
                                <input type="text" name="" className={styles.link} placeholder="링크를 입력해주세요." />
                            </li>
                        </ul>

                        <ul className={styles.btnWrap}>
                            <li><button type="button" className={styles.okBtn}>적용하기</button></li>
                        </ul>
                    </div>
                </MyPageContent>
            </MypageContainer>
        </div>
    );
}

export default AdminBannerModify; 