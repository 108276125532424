import { Link } from 'react-router-dom';
import styled from 'styled-components'

function Location () {
    return(
        <div className="rootConatiner">
             <BannerArti 
                bgImage={`${process.env.PUBLIC_URL}/images/location_banner_bg2.jpeg`}
            >
                <div className='inner'>
                    <div className='textBox'>
                        <p className="title">LOCATION</p>
                        <p className='content'>
                        친절하고 자세하게 안내드리겠습니다
                        </p>
                    </div>
                </div>
            </BannerArti>
            <ContactArti>
                <div className="inner">
                    <ul className="contentContainer">
                        <li>
                            <LocationBox>
                                <div className="titleBox">
                                    <img src={`${process.env.PUBLIC_URL}/images/jb_logo.png`} alt="잡오브브라더스 로고" />
                                    <p>오시는 길 안내</p>
                                </div>

                                <div className="mapBox">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1583.02444416097!2d126.87560966650275!3d37.483172684025156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9e1d2b037865%3A0xa12171157d125153!2z7JeQ7J207IqkIOu5hOymiO2PrOugiA!5e0!3m2!1sko!2skr!4v1712905187336!5m2!1sko!2skr" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                                
                                <div className="produceBox">
                                    <p className="title">회사 개요</p>
                                    <ul>
                                        <li>
                                            <p className="t1">Company</p>
                                            <p className="t2">
                                                잡오브브라더스 (jobofbrothers)
                                            </p>
                                        </li>
                                        <li>
                                            <p className="t1">Since</p>
                                            <p className="t2">
                                                2022년
                                            </p>
                                        </li>
                                        <li>
                                            <p className="t1">Business area</p>
                                            <p className="t2">
                                                Web&App Development, Design System
                                            </p>
                                        </li>
                                        <li>
                                            <p className="t1">Address</p>
                                            <p className="t2">
                                            서울특별시 금천구 가산디지털2로 173(에이스 비즈포레), 11층 1117호
                                            </p>
                                        </li>
                                        <li>
                                            <p className="t1">Contact</p>
                                            <p className="t2">
                                                0507-1409-9559
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                                <JobofbrothersBox>
                                    <img src={`${process.env.PUBLIC_URL}/images/location_logo.png`} alt="job of brothers"/>
                                    <p className="t1">
                                        홈페이지 제작, 서비스 & 소프트웨어 개발
                                    </p>
                                    <p className="t2">
                                        상생을 통한 같이 성장을 지향합니다.
                                    </p>
                                </JobofbrothersBox>
                            </LocationBox>
                        </li>
                        <li>
                            <InfoBox>
                                <ul className="infoList">
                                    <li>
                                        <p className="title">상담원전화</p>
                                        <p className="tel">
                                            <a href="tel:0507-1409-9559" rel='noreferrer'>
                                                0507-1409-9559
                                            </a>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="title">카카오톡 상담</p>
                                        <div className="content">
                                            <a href="https://pf.kakao.com/_RCxoxib" target="_blank" rel='noreferrer'>
                                                <span className="btn">상담하기</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <p className="title">온라인 상담 안내</p>
                                        <div className="content">
                                            <Link to="/contact">
                                                <span className="btn">오시는 길</span>
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </InfoBox>
                        </li>
                    </ul>
                </div>
            </ContactArti>
        </div>
    );
}

const BannerArti = styled.article`
    padding: 200px 0 100px 0; background-size: cover; background-repeat: no-repeat; background-position: center;
    background-image : ${(props) => props.bgImage ? `url(${props.bgImage})` : `url(${process.env.PUBLIC_URL}/images/example_img.png)` };
    color:#FFF; 

    .title{ font-size:56px; font-weight:700; }
    .content{ font-size:18px; margin-top:20px; }

    @media screen and (max-width: 1250px) {
        .title{ font-size:40px; }
        .content{ font-size:16px; }
    }
    @media screen and (max-width: 650px) {
        .title{ font-size:35px; }
        .content{ font-size:14px; margin-top:10px; }
    }
`;
const ContactArti = styled.article`
    padding: 100px 0;
    .contentContainer{ 
        display:flex; flex-wrap:wrap; width:calc(100% + 20px); margin-left:-10px;
        >li{ padding:10px; }
        >li:nth-child(1){ width:calc(100% - 350px);  }  
        >li:nth-child(2){ width:350px }
    } 
    @media screen and (max-width: 1250px) {
        .contentContainer{ 
            >li:nth-child(1){ width:calc(100% - 250px);  }  
            >li:nth-child(2){ width:250px }
        } 
    }
    @media screen and (max-width: 830px) {
        padding:50px 0;
        .contentContainer{ 
            >li:nth-child(1){ width:100%; order:1; }
            >li:nth-child(2){ width:100%; order:0; }
        }
    }
`;
const LocationBox = styled.div`
    .titleBox{ 
        display:flex; align-items:center; padding-bottom:10px; border-bottom:4px solid #222; 
        font-size: 26px; font-weight: 700;
        img{ margin-right:10px; }
    }
    .mapBox{ margin-top:20px; }

    .produceBox{ 
        margin-top:20px; 
        .title{ font-size:20px; padding-bottom:10px; border-bottom:2px solid var(--main-color); }
        ul{ 
            >li{ 
                padding:10px 0; font-size:16px; display:flex; border-bottom:1px solid #d9d9d9;
                .t1{ color: #7f7f7f; width:150px; flex-shrink:0; } 
            }
        }
    }

    @media screen and (max-width: 1250px) {
        .titleBox{ 
            font-size:20px; 
            img{ width:35px; }
        }
        
    }
    @media screen and (max-width: 650px) {
        .produceBox{ 
            ul{ 
                >li{ 
                    .t1{ width:110px; } 
                }
            }
        }
    }

`;

const JobofbrothersBox = styled.div`
    margin-top:70px; text-align: center;
    .t1{ font-size:32px; font-weight:600; margin-top:20px; }
    .t2{ font-size:40px; font-weight:700; }

    @media screen and (max-width: 1250px) {
        .t1{ font-size:20px; }
        .t2{ font-size:25px; margin-top:5px; }
    }
    @media screen and (max-width: 650px) {
        img{ width:140px; }
        .t1{ font-size:16px; }
        .t2{ font-size:18px; }
    }
`;

const InfoBox = styled.div`
    .infoList{ 
        >li{ 
            padding:40px 10px; border-top:2px solid #D9D9D9; 
            &:first-child{ padding-top:0; border-top:none; }
        }
        .title{ font-weight:700; font-size:24px; margin-bottom:20px; }
        .tel{ font-size:36px; font-weight:700; }
        .btn{ 
            text-align:center; padding:10px; font-size:18px; color: #7f7f7f; display:block; 
            border: 2px solid #D9D9D9; border-radius:60px;
        }
    }
    @media screen and (max-width: 1250px) {
        .infoList{  
            >li{ padding:20px 10px; }
            .title{ font-size:18px; }
            .tel{ font-size:24px; }
            .btn{ font-size:16px; }
        }
    }
    @media screen and (max-width: 830px) {
        .infoList{ 
            text-align:center; display:flex; flex-wrap: wrap;
            >li{ 
                &:nth-child(1){ width:100%; }
                &:nth-child(2){ width:50%; }
                &:nth-child(3){ width:50%; }
            }
            .title{ margin-bottom:10px; }
            .tel{ font-size:30px; }
        }
        
    }
    @media screen and (max-width: 650px) {
        .infoList{
            .title{ font-size:16px; }
            .tel{ font-size:24px; }
            .btn{ font-size:14px; }
        }
    }
`;

export default Location;