import styles from './MyPageHeader.module.css'; // CSS Modules 파일 import
import { Link } from 'react-router-dom';
import { useState,useEffect } from "react";
import React, { useContext } from 'react';
import { MyPageContext } from '../mypage/MyPageContext';

const MyPageHeader = ({title}) => {

    const [subMenu, setSubMenu] = useState(false);
    const subMenuHandler = () => {
        setSubMenu(!subMenu)
    }

    const { data } = useContext(MyPageContext);

    useEffect(() => {
        const fetchProjectingData = async () => {
            try {
                const imageUrl = data.userData.userProfile;
                const basicUrl =`${process.env.PUBLIC_URL}/images/mypage/mypage_header_profile.svg`
                if(imageUrl){
                    const profileImage = document.getElementById('profileImage');
                    profileImage.src = `${process.env.REACT_APP_SERVER_URL}/uploads/mypage/${imageUrl}`;
                }else{
                    const profileImage = document.getElementById('profileImage');
                    profileImage.src = basicUrl;
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }
        };
        if(data.userData!=null){
        fetchProjectingData();
        }
    }, [data.userData]);


    return (
        // {data.userData => (

        <div className={styles.header}>
            {/* <button type="button" className={styles.mobileMenuBtn} onClick={subMenuHandler}>
                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                    <path d="M6.25 37.5H43.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.25 25H43.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.25 12.5H43.75" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button> */}
            <div className={styles.logoBox}>
                <Link to="/mypage" >
                    <img src={`${process.env.PUBLIC_URL}/images/mypage/mypage_jb_logo.png`}/> 
                </Link>
                <span>|</span>
                <p>{title}</p>
            </div>
            <ul className={styles.menu}>
                {(data.userData!=null)?
                <li className={styles.myNameBox}>
                    <div className={`${styles.myName}`}>
                        <div className={styles.profile}>
                            <img id="profileImage" alt='이미지 없음' style={{ width: '24px', height: '25px',borderRadius:'30px'}} />
                        </div>
                        <p>{data.userData.userCompany} 시스템 {data.userData.userGrade}</p>
                    </div>
                </li>
                :
                null
                }
                {/* <li>
                    <div className={`${styles.item} ${styles.new}`} onClick={subMenuHandler}> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M18 8.98584C18 7.39454 17.3679 5.86842 16.2426 4.7432C15.1174 3.61798 13.5913 2.98584 12 2.98584C10.4087 2.98584 8.88258 3.61798 7.75736 4.7432C6.63214 5.86842 6 7.39454 6 8.98584C6 15.9858 3 17.9858 3 17.9858H21C21 17.9858 18 15.9858 18 8.98584Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.73 21.9858C13.5542 22.2889 13.3019 22.5405 12.9982 22.7154C12.6946 22.8902 12.3504 22.9823 12 22.9823C11.6496 22.9823 11.3054 22.8902 11.0018 22.7154C10.6982 22.5405 10.4458 22.2889 10.27 21.9858" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <ul className={`${styles.subMenu} ${(subMenu) ? styles.active : ""}`}>
                            <li className={styles.new}> 
                                채팅 알림 {data.alarmData.chatAlarm}건 
                            </li>
                            <li className={styles.new}>
                                유지보수 알림 {data.alarmData.maintanceAlarm}건
                            </li>
                            <li className={styles.new}>
                                프로젝트 알림 {data.alarmData.projectAlarm}건
                            </li>
                        </ul>
                    </div>
                </li> */}
                <li>
                    <Link to="/"> {/* 홈으로 */}
                    <div className={styles.item}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9 22V12H15V22" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                    </Link>
                </li>
               

            </ul>
        </div>
        // )}

    );
}

export default MyPageHeader; 
