import App from 'components/App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import "./Fonts/Fonts.css";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    {/* <React.StrictMode>
    </React.StrictMode> */}
    <App />
  </>
);

