
import { useState } from 'react';
import styled from 'styled-components';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Helmet } from 'react-helmet';
import { useRef } from 'react';

function Contact ({setPrivacyPopup, privacyPopup}){
    
    const PROJECT_OPTION = ['웹서비스', '기업소개', '제품 소개 랜딩페이지', '이커머스' , '마이크로 사이트', '디지털 마케팅', '브랜딩'];
    const agreeRef = useRef(null);

    const privacyPopupHandler = () => {
        setPrivacyPopup(!privacyPopup);
    }

    const navigate = useNavigate();
    //input 정의
    const [inputValues, setInputValues] = useState({
        contactName: '',
        contactCompany: '',
        contactCategory: '',
        contactPhone: '',
        contactEmail: '',
        contactUrl: '',
        contactRoute: '',
        contactPage: '',
        contactContent: '',
        contactReference: '',
        contactSchedule: ''
    });

    //Submit
    const onSubmitHandler = async (e) => {
        e.preventDefault();

        if(agreeRef.current.checked === false){
            alert('개인정보 처리방침에 동의해주세요.');
            return false;
        }
        try {
            const formData = new FormData();
            formData.append('contactName', inputValues.contactName);
            formData.append('contactCompany', inputValues.contactCompany);
            formData.append('contactCategory', inputValues.contactCategory);
            formData.append('contactPhone', inputValues.contactPhone);
            formData.append('contactEmail', inputValues.contactEmail);
            formData.append('contactUrl', inputValues.contactUrl);
            formData.append('contactRoute', inputValues.contactRoute);
            formData.append('contactPage', inputValues.contactPage);
            formData.append('contactContent', inputValues.contactContent);
            formData.append('contactReference', inputValues.contactReference);
            formData.append('contactSchedule', inputValues.contactSchedule);
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/contact/write`, formData);
                if (response.status === 200) {
                    const insertedId = response.data.insertedId; // 삽입된 ID 값
                    // const redirectURL = `/contact/view/${insertedId}`; // 리디렉션할 URL 생성
                    alert('상담신청이 완료되었습니다.')
                    console.log(response);
                    // navigate(redirectURL);
                    window.location.reload();// 페이지 새로고침
                } else {
                  throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }


        } catch (error) {
            console.error(error);
        }
    };

    //input 핸들러
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
        }));
    };
    //file 핸들러
    const handleFileChange = (event) => {
        const { name, files } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: files[0],
        }));
    };

    //date 핸들러
    const handleDateChange = (name, value) => {
        setInputValues((prevInputValues) => ({
          ...prevInputValues,
          [name]: value,
        }));
    };


    return(
        <div className="rootConatiner">
            <Helmet>
                {/* SEO 코드를 넣는 부분*/}
                <title>잡오브브라더스 : CONTACT</title>
                <meta name="description" content="친절하고 자세하게 안내드리겠습니다." />
            </Helmet>
            <BannerArti 
                bgImage={`${process.env.PUBLIC_URL}/images/contact_banner_bg.png`}
            >
                <div className='inner'>
                    <div className='textBox'>
                        <p className="title">CONTACT</p>
                        <p className='content'>
                        친절하고 자세하게 안내드리겠습니다
                        </p>
                    </div>
                </div>
            </BannerArti>
            <ContactArti>
                <div className="inner">
                    <ul className="contentContainer">
                        <li>
                            <FormBox>
                                <form onSubmit={onSubmitHandler}  autoComplete="off">
                                    <div className="titleBox">
                                        <img src={`${process.env.PUBLIC_URL}/images/jb_logo.png`} alt="잡오브브라더스 로고" />
                                        <p>프로젝트 신청하기</p>
                                    </div>

                                    <div className="inputWrap">
                                        <div className="title">01. 기본 정보를 입력해주세요.</div>
                                        <ul className="iptList">
                                            <li>
                                                <label>기관 혹은 기업명</label>
                                                <div className="iptWrap">
                                                <input 
                                                    type="text" 
                                                    name="contactCompany"
                                                    onChange={handleInputChange}
                                                    maxLength={255}
                                                    required 
                                                    placeholder=""
                                                    className="ipt"
                                                />
                                                </div>
                                            </li>
                                            <li>
                                                <label>담당자 성함</label>
                                                <div className="iptWrap">
                                                <input 
                                                    type="text" 
                                                    name="contactName"
                                                    onChange={handleInputChange}
                                                    maxLength={255}
                                                    required 
                                                    placeholder=""
                                                    className="ipt"
                                                />
                                                </div>
                                            </li>
                                            <li>
                                                <label>연락처</label>
                                                <div className="iptWrap">
                                                <input 
                                                    type="text" 
                                                    name="contactPhone"
                                                    onChange={handleInputChange}
                                                    maxLength={255}
                                                    required 
                                                    placeholder=""
                                                    className="ipt"
                                                />
                                                </div>
                                            </li>
                                            <li>
                                                <label>이메일</label>
                                                <div className="iptWrap">
                                                <input 
                                                    type="email" 
                                                    name="contactEmail"
                                                    onChange={handleInputChange}
                                                    maxLength={255}
                                                    required 
                                                    placeholder=""
                                                    className="ipt"
                                                />
                                                </div>
                                            </li>
                                            <li>
                                                <label>홈페이지 URL <span className="gray">(선택)</span></label>
                                                <div className="iptWrap">
                                                <input 
                                                    type="text" 
                                                    name="contactUrl"
                                                    onChange={handleInputChange}
                                                    maxLength={255}
                                                    placeholder=""
                                                    className="ipt"
                                                />
                                                </div>
                                            </li>
                                            <li>
                                                <label>홈페이지 알게된 경로</label>
                                                <div className="iptWrap">
                                                    <select name="contactRoute" onChange={handleInputChange} value={inputValues.contactRoute}>
                                                        <option value="">경로를 설정해주세요.</option>
                                                        <option value="네이버 검색">네이버 검색</option>
                                                        <option value="구글 검색">구글 검색</option>
                                                        <option value="네이버 블로그">네이버 블로그</option>
                                                        <option value="지인추천">지인추천</option>
                                                    </select>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="inputWrap">
                                        <div className="title">02. 작업해야 할 프로젝트를 알려주세요</div>
                                        <ul className="iptList">
                                            <li>
                                                <label>대략적인 페이지 수 </label>
                                                <div className="iptWrap">
                                                <input 
                                                    type="text" 
                                                    name="contactPage"
                                                    onChange={handleInputChange}
                                                    maxLength={255}
                                                    required 
                                                    placeholder=""
                                                    className="ipt"
                                                />
                                                </div>
                                            </li>
                                            <li>
                                                <label>오픈예정일</label>
                                                <div className="iptWrap">
                                                    {/* <input type="text" name="date" required placeholder="일정을 선택해주세요"/>  */}
                                                    <ReactDatePicker 
                                                        className="ipt" 
                                                        dateFormat="yyyy-MM-dd"
                                                        name="contactSchedule"
                                                        placeholderText="YYYY-MM-DD"
                                                        selected={inputValues.contactSchedule}
                                                        onChange={(value) => handleDateChange('contactSchedule', value)}
                                                    />
                                                </div>
                                            </li>
                                            <li>
                                                <label>홈페이지 분야</label>
                                                <div className="iptWrap">
                                                    <select name="contactCategory" onChange={handleInputChange}>
                                                        <option value="">홈페이지 분야를 선택해주세요</option>
                                                        {PROJECT_OPTION.map((item, idx)=> (
                                                            <option key={idx} value={item}>{item}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </li>
                                            <li>
                                                <label>참고 레퍼런스</label>
                                                <div className="iptWrap">
                                                <input 
                                                    type="text" 
                                                    name="contactReference"
                                                    onChange={handleInputChange}
                                                    maxLength={255}
                                                    required 
                                                    placeholder=""
                                                    className="ipt"
                                                />
                                                </div>
                                            </li>
                                            <li className="full">
                                                <label>자세한 의뢰 내용을 입력해주세요.</label>
                                                <div className="iptWrap">
                                                    <textarea name="contactContent" onChange={handleInputChange}></textarea>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="checkboxWrap">
                                        <input type="checkbox" id="agree" ref={agreeRef}/>
                                        <label htmlFor="agree">개인정보 처리방침에 동의합니다. <span className="black"  onClick={privacyPopupHandler}>(전문보기)</span></label>
                                    </div>
                                    <button type="submit" className="submitBtn">상담신청</button>
                                </form>
                            </FormBox>
                        </li>
                        <li>
                            <InfoBox>
                                <ul className="infoList">
                                    <li>
                                        <p className="title">상담원전화</p>
                                        <p className="tel">
                                            <a href="tel:0507-1409-9559" rel='noreferrer'>
                                                0507-1409-9559
                                            </a>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="title">카카오톡 상담</p>
                                        <div className="content">
                                            <a href="https://pf.kakao.com/_RCxoxib" target="_blank" rel='noreferrer'>
                                                <span className="btn">상담하기</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <p className="title">방문상담 안내</p>
                                        <div className="content">
                                            <Link to="/location">
                                                <span className="btn">오시는 길</span>
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </InfoBox>
                        </li>
                    </ul>
                </div>
            </ContactArti>
        </div>
    )
}

const BannerArti = styled.article`
    padding: 200px 0 100px 0; background-size: cover; background-repeat: no-repeat; background-position: center;
    background-image : ${(props) => props.bgImage ? `url(${props.bgImage})` : `url(${process.env.PUBLIC_URL}/images/example_img.png)` };
    color:#FFF; 

    .title{ font-size:56px; font-weight:700; }
    .content{ font-size:18px; margin-top:20px; }

    @media screen and (max-width: 1250px) {
        .title{ font-size:40px; }
        .content{ font-size:16px; }
    }
    @media screen and (max-width: 650px) {
        .title{ font-size:35px; }
        .content{ font-size:14px; margin-top:10px; }
    }
`;
const ContactArti = styled.article`
    padding: 100px 0;
    .contentContainer{ 
        display:flex; flex-wrap:wrap; width:calc(100% + 20px); margin-left:-10px;
        >li{ padding:10px; }
        >li:nth-child(1){ width:calc(100% - 350px);  }  
        >li:nth-child(2){ width:350px }
    } 
    @media screen and (max-width: 1250px) {
        .contentContainer{ 
            >li:nth-child(1){ width:calc(100% - 250px);  }  
            >li:nth-child(2){ width:250px }
        } 
    }
    @media screen and (max-width: 830px) {
        padding:50px 0;
        .contentContainer{ 
            >li:nth-child(1){ width:100%; order:1; }
            >li:nth-child(2){ width:100%; order:0; }
        }
    }
`;
const FormBox = styled.div`
    .titleBox{ 
        display:flex; align-items:center; padding-bottom:10px; border-bottom:4px solid #222; 
        font-size: 26px; font-weight: 700;
        img{ margin-right:10px; }
    }
    .inputWrap{ 
        margin-top:20px; padding:20px 0; 
        + .inputWrap{ border-top:1px solid #d9d9d9; }
        
        .title{ font-size:20px; }
        .iptList{ 
            margin-top:10px; display:flex; flex-wrap:wrap; width:calc(100% + 20px); margin-left: -10px;
            > li{ 
                padding:10px; width:calc(100% / 2); 
                &.full{ width:100%; }
                .iptWrap{ 
                    margin-top:10px; 
                    .ipt{ border:1px solid #d9d9d9; padding:10px; width:100%; font-size:16px;  }
                    select{ border:1px solid #d9d9d9; padding:10px; width:100%; font-size:16px;  }
                    textarea{ padding:10px; border:1px solid #d9d9d9; width:100%; height:200px; resize:none; font-size:16px;  }
                }
            }
        }
    }
    .checkboxWrap{
        input[type="checkbox"]{ 
            display:none; 
            + label{ 
                display:flex; align-items: center; color: #7f7f7f;
                &::before{ content:''; display:block; width:20px; height:20px; border:1px solid #d9d9d9; margin-right:10px; }
                span{
                    &.black{ margin-left:10px; color:#222; cursor:pointer; }
                }
            }
            &:checked{
                + label{
                    &::before{ 
                        background-image: ${`url(${process.env.PUBLIC_URL}/images/catact_check_icon.png)`};
                        background-size:cover; background-repeat:no-repeat; background-position:center; 
                    }
                }
            }
        }
    }

    .submitBtn{ 
        margin:50px auto 0 auto; background-color:var(--main-color); color:#fff; padding:15px; width:100%; max-width:300px; border-radius:60px;  
        border: none; display:block; font-size: 18px;
    }

    @media screen and (max-width: 1250px) {
        .titleBox{ 
            font-size:20px; 
            img{ width:35px; }
        }
        .inputWrap{ 
            .title{ font-size:16px; }
        }
    }
    @media screen and (max-width: 830px) {
        
    }
    @media screen and (max-width: 650px) {
        .inputWrap{ 
            .iptList{
                >li{ width:100%; }
            }
        }
    }

`;
const InfoBox = styled.div`
    .infoList{ 
        >li{ 
            padding:40px 10px; border-top:2px solid #D9D9D9; 
            &:first-child{ padding-top:0; border-top:none; }
        }
        .title{ font-weight:700; font-size:24px; margin-bottom:20px; }
        .tel{ font-size:36px; font-weight:700; }
        .btn{ 
            text-align:center; padding:10px; font-size:18px; color: #7f7f7f; display:block; 
            border: 2px solid #D9D9D9; border-radius:60px;
        }
    }
    @media screen and (max-width: 1250px) {
        .infoList{  
            >li{ padding:20px 10px; }
            .title{ font-size:18px; }
            .tel{ font-size:24px; }
            .btn{ font-size:16px; }
        }
    }
    @media screen and (max-width: 830px) {
        .infoList{ 
            text-align:center; display:flex; flex-wrap: wrap;
            >li{ 
                &:nth-child(1){ width:100%; }
                &:nth-child(2){ width:50%; }
                &:nth-child(3){ width:50%; }
            }
            .title{ margin-bottom:10px; }
            .tel{ font-size:30px; }
        }
        
    }
    @media screen and (max-width: 650px) {
        .infoList{
            .title{ font-size:16px; }
            .tel{ font-size:24px; }
            .btn{ font-size:14px; }
        }
    }
`;

export default Contact; 