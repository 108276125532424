
import { useState,useEffect } from 'react';
import styled from 'styled-components';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate,useParams } from 'react-router-dom';

import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { useRef } from 'react';

const ColumnWrite = () => {
    //데이터를 불러옴
    const navigate = useNavigate();
    const { id } = useParams(); 
    
    const [columnData, setColumnData] = useState(null);
    const [delColumnFiles, setDelColumnFiles] = useState([false, false]);

    //에디터 Ref
    const editorRef = useRef(null);

    //input 정의
    const [inputValues, setInputValues] = useState({
        columnTitle: '',
        columnSummary: '',
        columnTeam: '',
        columnContent: '',
        columnLink1: '',
        columnLink2: '',
        columnRecommend : '',
        columnFile1: null,
        columnFile2:null,
    });


    //input 핸들러
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
        }));
    };

    //checkbox handler 
    const handleCheckChange=(event) =>{
        const {name, checked} = event.target;
        // console.log(checked)
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: (checked) ? "1" : "" //check값이 true이면 1 저장
        }))
        console.log(inputValues)
    };

    //file 핸들러
    const handleFileChange = (event) => {
        const { name, files } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: files[0],
        }));
        console.log(inputValues)
    };

    // checkbox  핸들러
    const handleCheckboxChange = (e, fileNum) => {
        const checked = e.target.checked;
        setDelColumnFiles((prevState) => {
            const newState = [...prevState];
            newState[fileNum - 1] = checked;
            return newState;
        });
    };


    useEffect(() => {
        const fetchColumnData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/column/${id}`);
                if (response.status === 200) {
                    console.log(response);
                    setColumnData(response.data.result[0]);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }
        };

        fetchColumnData();
    }, [id]);

    //Submit
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            const editorContent = editorRef.current.getContent();

            const formData = new FormData();
            formData.append('columnCategory', inputValues.columnCategory);
            formData.append('columnTitle', inputValues.columnTitle);
            formData.append('columnSummary', inputValues.columnSummary);
            formData.append('columnTeam', inputValues.columnTeam);
            formData.append('columnContent', editorContent);
            formData.append('columnLink1', inputValues.columnLink1);
            formData.append('columnLink2', inputValues.columnLink2);
            formData.append('columnFile1', inputValues.columnFile1);
            formData.append('columnFile2', inputValues.columnFile2);
            formData.append('columnRecommend', inputValues.columnRecommend);
            formData.append('id', id);

            // 삭제할 파일 정보를 formData에 추가
            for (let i = 0; i < delColumnFiles.length; i++) {
                if (delColumnFiles[i]) {
                formData.append(`delColumnFile${i + 1}`, true);
                console.log(i);
                }
            }

            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/column/update/${id}`, formData);
                if (response.status === 200) {
                    const redirectURL = `/column/view/${id}`; // 리디렉션할 URL 생성
                    console.log(response);
                    navigate(redirectURL);
                } else {
                  throw new Error('서버 응답 오류');
                }
              } catch (error) {
                console.log(error);
                alert(error);
              }


        } catch (error) {
            console.error(error);
        }
    };
    
    useEffect(() => {
        if (columnData) {
          const { ColumnFile1, ColumnFile2, ...rest } = columnData;
          setInputValues(rest);
        }
    }, [columnData]);

    if (!columnData) {
        return <div>Loading...</div>;
    }

    const { columnCategory, columnTitle,columnSummary, columnTeam, columnContent, columnLink1, columnLink2, columnFile1, columnFile2} = columnData;

    console.log(inputValues)
    
    return(
        <div className="rootConatiner">
            <div className='inner'>

                {
                    (columnData) 
                    ?
                    <WriteBox>
                        <form onSubmit={onSubmitHandler}>
                            <div className="write_page">
                                <ul className="full_box title_box">
                                    <li>
                                        <span className="label">분류</span>
                                        <span className="label_content">
                                            <select name="columnCategory" 
                                                onChange={handleInputChange}
                                                value={inputValues.columnCategory}
                                                required
                                        >
                                                <option value="">분류를 선택해주세요</option>
                                                <option value="잡오브브라더스 시스템">잡오브브라더스 시스템</option>
                                                <option value="홈페이지 제작안내">홈페이지 제작안내</option>
                                                <option value="IT 정보">IT 정보</option>
                                            </select>
                                        </span>
                                    </li>
                                </ul>
                                <ul className="full_box title_box">
                                    <li>
                                        <span className="label">설정</span>
                                        <span className="label_content">
                                            <label htmlFor="notice">
                                                <input type="checkbox" id="notice" name="columnRecommend"  value="1" checked={inputValues.columnRecommend === "1"} onChange={handleCheckChange}/>
                                                추천컬럼</label>
                                        </span>
                                    </li>
                                </ul>
                                <ul className="full_box title_box">
                                    <li>
                                        <span className="label">제목</span>
                                        <span className="label_content">
                                            <input type="text" name="columnTitle" defaultValue={inputValues.columnTitle} onChange={handleInputChange} placeholder="제목" required />
                                        </span>
                                    </li>
                                </ul>
                                <ul className="half_box title_box">
                                    <li>
                                        <span className="label">요약</span>
                                        <span className="label_content">
                                            <input type="text" name="columnSummary" onChange={handleInputChange} defaultValue={inputValues.columnSummary} placeholder="내용 요약을 넣어주세요." required/>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="label">팀</span>
                                        <span className="label_content">
                                            <input type="text" name="columnTeam" onChange={handleInputChange} defaultValue={inputValues.columnTeam} placeholder="팀을 입력해주세요 ex)디자인팀" required/>
                                        </span>
                                    </li>
                                </ul>

                                <div className="dhtml_box">

                                    <Editor
                                        apiKey='h1qsn5by3dfyecmfgo98mwqbc07bz31gaupmyfaanahhlc1z'
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue={inputValues.columnContent}
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            language: "ko",
                                            plugins: 
                                                [   
                                                    'lists',
                                                    'link',
                                                    // 'image',
                                                    // 'charmap',
                                                    // 'preview',
                                                    'searchreplace',
                                                    'fullscreen',
                                                    // 'media',
                                                    'table',
                                                    'code',
                                                    'help',
                                                    'emoticons',
                                                    'codesample',
                                                    'quickbars',
                                                ],
                                                toolbar: 'undo redo | blocks | ' +
                                                'bold italic forecolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'lists table link charmap searchreplace | ' +
                                                'image media codesample emoticons fullscreen preview | ' +
                                                'removeformat | help | insert | hr ',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                        }}
                                    />
                                </div>
                                <div className="add_form">
                                    <div className="add_box link_box"><input name="columnLink1" defaultValue={inputValues.columnLink1} type="text" placeholder="링크를 입력해주세요." /></div>
                                    <div className="add_box link_box"><input name="columnLink2" defaultValue={inputValues.columnLink2} type="text" placeholder="링크를 입력해주세요." /></div>
                                    <div className="add_box file_box">
                                        <p className="help">썸네일 이미지(960 * 800)</p>
                                        <input 
                                            name="columnFile1"
                                            onChange={handleFileChange}
                                            type="file"
                                        />
                                    </div>
                                    {columnFile1 && (
                                    <div className="file_del_box">
                                        <label htmlFor="file_01" className="file_title">
                                            <div>
                                                <input
                                                    id="file_01"
                                                    type="checkbox"
                                                    checked={delColumnFiles[0]}
                                                    onChange={(e) => handleCheckboxChange(e, 1)}
                                                />
                                                <span>{columnFile1} 파일 삭제</span>
                                            </div>
                                        </label>
                                    </div>
                                    )}
                                    <div className="add_box file_box">
                                        <p className="help">배너 이미지 ( 1400 * 350 )</p>
                                        <input 
                                            name="columnFile2"
                                            onChange={handleFileChange}
                                            type="file"
                                        />
                                    </div>
                                    </div>
                                    {columnFile2 && (
                                    <div className="file_del_box">
                                        <label htmlFor="file_02" className="file_title">
                                            <div>
                                                <input
                                                    id="file_02"
                                                    type="checkbox"
                                                    checked={delColumnFiles[1]}
                                                    onChange={(e) => handleCheckboxChange(e, 2)}
                                                />
                                                <span>{columnFile2} 파일 삭제</span>
                                            </div>
                                        </label>
                                    </div>
                                    )}
                                <div className="write_btn_box">
                                    <Link to="/column">
                                    <button type="button" className="cancelBtn">취소</button>
                                    </Link>
                                    <button type="submit" className="submitBtn">작성완료</button>
                                </div>
                            </div>
                        </form>
                    </WriteBox>
                    :
                    null
                }
                
                
            </div>
        </div>
    );
}

const WriteBox = styled.div`
    padding: 100px 0;
`;

export default ColumnWrite;