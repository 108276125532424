
import { useState,useContext } from "react";
import MypageContainer from "../../components/mypage/MyPageContainer";
import MyPageContent from "../../components/mypage/MyPageContent";
import MyPageHeader from "../../components/mypage/MyPageHeader";
import MypageMenu from "../../components/mypage/MyPageMenu";
import styles from './ModifyMember.module.css'; // CSS Modules 파일 import
import axios from 'axios';
import { phone_check } from '../../middlewares/textTest';
import { MyPageContext } from "../../components/mypage/MyPageContext";

const ModifyMember = () => {
    const { data } = useContext(MyPageContext);
    const [selectedImage, setSelectedImage] = useState(data.userData!=null&&data.userData.userProfile!=null ?`${process.env.REACT_APP_SERVER_URL}/uploads/mypage/${data.userData.userProfile}`:null);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith('image/')) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setSelectedImage(reader.result);
          };
          reader.readAsDataURL(file);
          const { name, files } = event.target;
            setInputValues((prevInputValues) => ({
                ...prevInputValues,
                [name]: files[0],
            }));
        } else {
          setSelectedImage(null);
          alert('Please select an image file.');
        }
    };

    const [inputValues, setInputValues] = useState({
        userCompany:(data.userData)? data.userData.userCompany : null,
        userName:(data.userData)? data.userData.userName : null,
        userPhone:(data.userData)? data.userData.userPhone : null,
        userGrade:(data.userData)? data.userData.userGrade : null,
        userProfile: (data.userData)? data.userData.userGrade : null
    });

    //input 핸들러
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateUserInfoHandler=async(event)=>{
    event.preventDefault();
    if(inputValues.userPhone.length!=0){
        if(!phone_check(inputValues.userPhone)){
            alert('전화번호 형식이 맞지 않습니다.');
            return
        }
    }
    const formData = new FormData();
    formData.append('userCompany', inputValues.userCompany);
    formData.append('userName', inputValues.userName);
    formData.append('userPhone', inputValues.userPhone);
    formData.append('userEmail', data.userData.userEmail);
    formData.append('userGrade', inputValues.userGrade);
    formData.append('userProfile', inputValues.userProfile);
    console.log(inputValues.userProfile)
    try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/mypage/updateprofile`, formData);
        if (response.status === 200) {
                if (response.data.message == "S") {
                    alert('수정 되었습니다.');
                    const redirectURL = `/`; // 리디렉션할 URL 생성
                    window.location.href = redirectURL;
                    return;
                } else {
                    console.log("하하하하")

                    alert(response.data.message);
                }
        } else {
            throw new Error('서버 응답 오류');
        }
    } catch (error) {
        console.log(error);
        alert(error);
    }
}

const phonehandleInputChange = (event) => {
    const { name, value } = event.target;
    const filteredValue = value.replace(/[^0-9]/g,"");
    event.target.value = filteredValue;
    setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: filteredValue,
    }));
};
    return(
        <div className="mypageContainer">
            <MypageMenu 
            />
            <MypageContainer>
                <MyPageHeader  title="정보수정" 
                />
                <MyPageContent>
                    {/* 여기에 컨텐츠 (내용)을 디자인 해주세요*/}
                    <div className={styles.modifyContainer}>
                        <div className={styles.titleBox}>
                            <h2>회원정보 수정</h2>
                            <p className={styles.subTitle}>내 프로필을 공개하고 교류할 수 있어요. </p>
                        </div>
                        <div className={styles.formWrap}>
                            <ul className={styles.formList}>
                                <li>
                                    <div className={styles.label}>
                                        <p className={styles.t1}>내 정보</p>
                                        <p className={styles.t2}>아이콘 이미지 설정</p>
                                    </div>
                                    <div className={styles.iptWrap}>
                                    <label
                                        className={styles.profileImgBox}
                                        htmlFor="MyProfileImage"
                                        style={selectedImage ? { backgroundImage: `url(${selectedImage})` } : {}} // 이미지 파일이 선택되면 배경 이미지 변경
                                    ></label>
                                    <input
                                        type="file"
                                        id="MyProfileImage"
                                        className={styles.fileBox}
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        name="userProfile"
                                    />
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.label}>
                                        <p className={styles.t2}>기업 혹은 기업명</p>
                                    </div>
                                    <div className={styles.iptWrap}>
                                        <input type="text" value={inputValues.userCompany} name="userCompany" onChange={handleInputChange} className={styles.ipt} />
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.label}>
                                        <p className={styles.t2}>담당자 이름</p>
                                    </div>
                                    <div className={styles.iptWrap}>
                                        <input type="text" value={inputValues.userName} name="userName" onChange={handleInputChange} className={styles.ipt} />
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.label}>
                                        <p className={styles.t2}>연락처</p>
                                    </div>
                                    <div className={styles.iptWrap}>
                                    <input 
                                        type="text" 
                                        name="userPhone"
                                        onChange={phonehandleInputChange}
                                        maxLength={11}
                                        className={`${styles.ipt} ${styles.big}`} 
                                        placeholder="연락처를 입력해주세요."
                                        value={inputValues.userPhone}

                                    />
                                    </div>
                                </li>
                                <li>
                                    <div className={styles.label}>
                                        <p className={styles.t2}>이메일</p>
                                    </div>
                                    {(data.userData!=null)?
                                    <div className={styles.iptWrap}>
                                        <input type="email" value={data.userData.userEmail} readOnly name="userEmail" onChange={handleInputChange} className={`${styles.ipt} ${styles.big}`} />
                                    </div>
                                    :
                                    null
                                    }
                                </li>
                                <li>
                                    <div className={styles.label}>
                                        <p className={styles.t2}>계정유형 변경(직급)</p>
                                    </div>
                                    <div className={styles.iptWrap}>
                                        <input type="email" value={inputValues.userGrade} name="userGrade" onChange={handleInputChange} className={`${styles.ipt} ${styles.big}`} />
                                    </div>
                                </li>
                            </ul>
                            <ul className={styles.btnWrap}>
                                <li><button type="submit" onClick={updateUserInfoHandler} className={styles.modifyBtn}>수정완료</button></li>
                                <li><button type="button" className={styles.memberdelBtn}>회원탈퇴신청</button></li>
                            </ul>
                        </div>
                    </div>
                </MyPageContent>
            </MypageContainer>
        </div>
    )
}

export default ModifyMember; 