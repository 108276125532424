import "./FloatingMenu.css"
import { useLocation } from 'react-router-dom';

function FloatingMenu () {

    const {pathname} = useLocation();

    if(!pathname.startsWith('/mypage')){
    return(
        <>
            <ul className="floatingMenu">
                <li>
                    <a href="https://www.instagram.com/jobofbrothers/" target="_blank" rel="noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/images/floating_instagram.svg`} alt="instagram"/>
                    </a>
                </li>
                <li>
                    <a href="https://blog.naver.com/jobofbrothers" target="_blank" rel="noreferrer">
                        <img src={`${process.env.PUBLIC_URL}/images/floating_blog.svg`} alt="instagram"/>
                    </a>
                </li>
            </ul>
        </>
    )
    }
}

export default FloatingMenu; 