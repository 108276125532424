import { useEffect, useRef, useState } from "react";
import "./ContactModal.css"
import ReactDatePicker from "react-datepicker";
import axios from 'axios';

function ContactModal({contactStatus , contactModal, privacyPopup ,setPrivacyPopup}){

    const [startDate, setStartDate] = useState(false);

    const privacyPopupHandler = (e) => {
        e.preventDefault();
        setPrivacyPopup(!privacyPopup);
    }

    const PROJECT_OPTION = ['분야를 선택해주세요.', '웹서비스', '기업소개', '제품 소개 랜딩페이지', '이커머스' , '마이크로 사이트', '디지털 마케팅', '브랜딩'];
    const BUDGET_OPTION = ['미정', '1백만 원 미만', '1백만 원 ~ 3백만 원 미만', '3백만 원 ~ 5백만 원 미만', '5백만 원 ~ 1천만 원 미만', '1천만 원 ~ 5천만 원 미만', '5천만 원 ~ 1억 원 미만', '1억원 이상 ~'];
    
    const closeHandler = () => { contactModal(false); }
    const agreeRef = useRef();

    const [inputValues, setInputValues] = useState({
        contactName: '',
        contactCompany: '',
        contactCategory: '',
        contactPhone: '',
        contactEmail: '',
        contactUrl: '',
        contactRoute: '',
        contactPage: '',
        contactContent: '',
        contactReference: '',
        contactSchedule: ''
    });

    //input 핸들러
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
        }));
    };

    //date 핸들러
    const handleDateChange = (name, value) => {
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
        }));
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        if(agreeRef.current.checked === false){
            alert('개인정보 처리방침에 동의해주세요.');
            return false;
        }

        try {
            const formData = new FormData();
            formData.append('contactName', inputValues.contactName);
            formData.append('contactCompany', inputValues.contactCompany);
            formData.append('contactCategory', inputValues.contactCategory);
            formData.append('contactPhone', inputValues.contactPhone);
            formData.append('contactEmail', inputValues.contactEmail);
            formData.append('contactUrl', inputValues.contactUrl);
            formData.append('contactRoute', inputValues.contactRoute);
            formData.append('contactPage', inputValues.contactPage);
            formData.append('contactContent', inputValues.contactContent);
            formData.append('contactReference', inputValues.contactReference);
            formData.append('contactSchedule', inputValues.contactSchedule);
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/contact/write`, formData);
                if (response.status === 200) {
                    const insertedId = response.data.insertedId; // 삽입된 ID 값
                    alert('상담신청이 완료되었습니다.')
                    console.log(response);
                    window.location.reload();// 페이지 새로고침
                } else {
                  throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }
        } catch (error) {
            console.error(error);
        }
    }


    return(
        <section className={`opacityBg ${(contactStatus) ? 'active':''} `} onClick={closeHandler}>
            <form onSubmit={onSubmitHandler} autoComplete="off">
                <div className="contentBox" onClick={(e)=> {e.stopPropagation()}}>
                    <h3>프로젝트 신청하기</h3>
                    <div className="formDiv">
                        <p className="helper">기본정보 [ <span className="orange">•</span> 항목은 필수 입니다 ]</p>
                        <ul className="iptList">
                            <li>
                                <div className="required"> 
                                    <input 
                                        type="text" 
                                        name="contactName"
                                        placeholder="담당자" 
                                        required 
                                        onChange={handleInputChange}
                                        className="ipt"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="required"> 
                                    <input 
                                        type="text" 
                                        name="contactCompany"
                                        placeholder="회사명/서비스명" 
                                        required 
                                        onChange={handleInputChange}
                                        className="ipt"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="required">
                                    <input 
                                        type="text" 
                                        name="contactPhone"
                                        placeholder="연락처" 
                                        required 
                                        onChange={handleInputChange}
                                        className="ipt"
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="required">
                                    <input 
                                        type="text" 
                                        name="contactEmail"
                                        placeholder="이메일" 
                                        required 
                                        className="ipt"
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="formDiv">
                        <p className="helper">제작구분(필수)</p>
                        <ul className="iptList">
                            {/* <li className="">
                                <label className="title">프로젝트 유형</label>
                                <ul className="checkList">
                                    <li>
                                        <input type="checkbox" value="신규구축" id="project1"/>
                                        <label htmlFor="project1">신규구축</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" value="리뉴얼" id="project2"/>
                                        <label htmlFor="project2">리뉴얼</label>
                                    </li>
                                    <li>
                                        <input type="checkbox" value="유지보수" id="project3"/>
                                        <label htmlFor="project3">유지보수</label>
                                    </li>
                                </ul>
                            </li> */}
                            <li>
                                <label className="title">프로젝트 분야</label>
                                <SelectOption 
                                    option={PROJECT_OPTION}
                                    onChange={handleInputChange}
                                    name="contactCategory" 
                                />
                            </li>
                            <li className="">
                                <label className="title">프로젝트 오픈 예정일</label>
                                <div className="required"> 
                                    <ReactDatePicker
                                        showIcon
                                        name="contactSchedule"
                                        dateFormat="yyyy-MM-dd"
                                        minDate={new Date()}
                                        selected={inputValues.contactSchedule}
                                        onChange={(value) => handleDateChange('contactSchedule', value)}
                                        className="ipt datepicker"
                                    />
                                </div>
                            </li>
                            {/* <li className="full">
                                <label className="title">기존 페이지</label>
                                <div className=""> 
                                    <input 
                                        type="homepage" 
                                        placeholder="http://" 
                                        className="ipt" 
                                    />
                                </div>
                            </li> */}
                            {/* <li className="">
                                <label className="title">제작 예산</label>
                                <SelectOption 
                                    option={BUDGET_OPTION}
                                />
                            </li> */}
                        </ul>
                    </div>
                    <div className="formDiv">
                        <p className="helper">기타 문의사항을 적어주세요.</p>
                        <ul className="iptList">
                            <li className="full">
                                <div> 
                                    <textarea 
                                        name="contactContent" 
                                        onChange={handleInputChange}
                                        placeholder="기타 문의사항을 적어주세요."
                                    ></textarea>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="agreeBox">
                        <input type="checkbox" id="agree" ref={agreeRef}/>
                        <label htmlFor="agree">
                            (필수)개인정보수집 및 이용에 동의합니다.&nbsp;&nbsp;
                            <span onClick={privacyPopupHandler}>자세히보기</span>
                        </label>
                    </div>

                    <ul className="btnWrap">
                        <li>
                            <button type="button" className="closeBtn" onClick={closeHandler}>닫기</button>
                        </li>
                        <li>
                            <button type="submit" className="submitBtn">상담신청서보내기</button>
                        </li>
                    </ul>
                    
                </div>
            </form>
        </section>
    );
}

function SelectOption ({option , onChange="", name }) {
    
    const [ view, setView ] = useState(false);
    const SelectViewHandler = () => { setView(!view); }

    useEffect(() => {
        function optionEventHandler () {
            const option_index = Number(this.dataset.index); //몇번째인지
            const option_name = this.innerText;
            this.parentNode.parentNode.parentNode.querySelector('.selectTxt').innerHTML = option_name;
            if(option_index !== 0){
                this.parentNode.parentNode.parentNode.querySelector('.selectTxt').classList.add('active')
            }else{
                this.parentNode.parentNode.parentNode.querySelector('.selectTxt').classList.remove('active')
            }
            this.parentNode.parentNode.parentNode.parentNode.querySelector('.select').querySelectorAll('option').forEach((element, index) => {
                if(index === option_index){ element.selected = true; }
            })
        }

        document.querySelectorAll('.opacityBg .contentBox .formDiv .iptList > li .selectBox .optionBox .optionList > li').forEach(element => {
            element.addEventListener("click", optionEventHandler)
        });

        return (() => {
            document.querySelectorAll('.opacityBg .contentBox .formDiv .iptList > li .selectBox .optionBox .optionList > li').forEach(element => {
                element.removeEventListener("click", optionEventHandler)
            });
    
        })
    },[])

    return(
        <div 
            className={`selectBox ${(view) ? 'active' : null}`} 
            onClick={SelectViewHandler} 
        >
            <label className="customBox">
                <div className="selectTxt">분야를 선택해주세요.</div>
                <div className="optionBox">
                    <ul className="optionList">
                        {option.map((item, index) => (<li key={index} data-index={index}>{item}</li>))}
                    </ul>
                </div>
            </label>
            <select name={name} className="select" onChange={onChange}>
                {option.map((item, index) => (<option key={index} value={`${(index === 0) ? '': item}`}>{item}</option>))}
            </select>
        </div>
    );
}

export default ContactModal; 
