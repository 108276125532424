  // 날짜를 데이터베이스의 date 타입에 맞게 가공하는 함수
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}.${month}.${day}`;
  }
  
  function formatPhone(userPhone){
    //전화번호 형식 바꾸기
    if(userPhone){
        let formattedPhone = userPhone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        return formattedPhone;
    }else{
        return false;
    }
  }

  module.exports = { formatDate,formatPhone };