import React, { useEffect, useState,useRef } from 'react';
import { Link ,useNavigate} from 'react-router-dom';
import axios from 'axios';
import './LoginPage.css';
import {useGoogleLogin, GoogleLogin} from "@react-oauth/google";
const LoginPage = ({ user, setUser }) => {
  const navigate = useNavigate();

    //kakao
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
        script.async = true;
        script.onload = () => {
            window
                .Kakao
                .init(process.env.REACT_APP_KAKAO_KEY);
        };
        document
            .body
            .appendChild(script);
        return() => {
            document
                .body
                .removeChild(script);
        };
    }, []);

    //naver
    useEffect(() => {
        const clientId = process.env.REACT_APP_NAVER_CLIENTID;
        const callbackUrl =process.env.REACT_APP_NAVER_CALLBACKURL;
        const script = document.createElement('script');
        script.src = "https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js";
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
          const initNaverLogin = () => {
              const naverLogin = new window.naver.LoginWithNaverId({
                clientId,
                callbackUrl,
                loginButton: {
                  color: "green",
                  type: 1,
                  height: 50,
                }
              });
              naverLogin.init();
              naverLogin.getLoginStatus(async function (status) {
                  if (status) {
                      const userId = naverLogin.user.getId();
                      const userName = naverLogin.user.getName();
                      const userEmail = naverLogin.user.getEmail();
                    platformlogin(userId,userName,userEmail,"naver");
                  }
              });
          };
          initNaverLogin();
        };
    }, []);

    const [userId, setUsername] = useState('');
    const [userPassword, setPassword] = useState('');

    const loginRequestHandler = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/users/login`,
                {
                    userId: userId,
                    userPassword: userPassword
                }, 
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                }
            );
            if (response.data.message == "S") {
                alert('로그인 되었습니다.');
                let userData = JSON.stringify(response.data.data);
                localStorage.setItem('user', userData);
                const redirectURL = `/mypage`; // 리디렉션할 URL 생성
                navigate(redirectURL);
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.log(error);
            alert(error);
        }
    };


    const inputHandler = (e) => {
        if (e.target.name === 'userId') {
            setUsername(e.target.value);
        } else if (e.target.name === 'userPassword') {
            setPassword(e.target.value);
        }
    };
    const kakaoLogin = () => {
        window
            .Kakao
            .Auth
            .login({
                success: function (response) {
                    window
                        .Kakao
                        .API
                        .request({
                            url: '/v2/user/me',
                            success: async function (response) {
                                const userId = response.id
                                const userName = response.properties.nickname
                                const userEmail = response.kakao_account.email
                                //여기다가 data를 이용해서 디비에 넣기
                                platformlogin(userId,userName,userEmail,"kakao");
                            },
                            fail: function (error) {
                                console.log(error)
                            }
                        })
                },
                fail: function (error) {
                    console.log(error)
                }
            })
    }

    const googleLogin = useGoogleLogin({
        onSuccess: async (res) => {
            const apiURL = "https://www.googleapis.com/oauth2/v3/userinfo";
            const headers = {
                Authorization: `Bearer ${res.access_token}`,
            };
            const response = await axios.get(apiURL, { headers });
            const userId = response.data.sub
            const userName =   response.data.name         
            const userEmail = response.data.email
            //여기다가 data를 이용해서 디비에 넣기
            platformlogin(userId,userName,userEmail,"google");
        }
    })
    
    const handleLoginClick = () => {
        googleLogin(); // 커스텀 버튼 클릭 시 Google 로그인 실행
    };

    const platformlogin = async (userId,name,email,platform) => {
        const formData = new FormData();
                          formData.append('userId', userId);
                          formData.append('userName', name);
                          formData.append('userEmail', email);
                          formData.append('userRoute', platform);
                          try {
                            //   localStorage.clear();
                              const response = await axios.post(
                                `${process.env.REACT_APP_SERVER_URL}/users/platform`,
                                formData,
                                {
                                    headers: {
                                        'Content-Type': 'multipart/form-data', // Content-Type을 'multipart/form-data'로 설정
                                    },
                                    withCredentials: true
                                }
                            );
                              if (response.status === 200) {
                                  if (response.data.message == 'kakaoupdate') {
                                      const redirectURL = `/infoadd/${userId}`;
                                      navigate(redirectURL);
                                  }else if(response.data.message == 'N'){
                                      alert('이미 존재하는 회원입니다.');
                                      return;
                                  }
                                  else {
                                      alert('로그인 되었습니다.');
                                      let userData = JSON.stringify(response.data.data);
                                      localStorage.setItem('user', userData);
                                      setUser(userData);
                                      const redirectURL = `/signup_success`; // 리디렉션할 URL 생성
                                      navigate(redirectURL);
                                  }
                              } else {
                                  throw new Error('서버 응답 오류');
                              }
                          } catch (error) {
                              console.log(error);
                              alert(error);
                          }
    };
    
    return (
        <div className="rootConatiner">
        <div className="loginContainer">
            <div className="inner">
            <form onSubmit={loginRequestHandler}>
                <div className="loginWrap">
                <div className="logoBox">
                    <img src={`${process.env.PUBLIC_URL}/images/login_logo.png`} alt="jobofbrothers" />
                    <p>제작 및 유지보수 채팅 서비스</p>
                </div>
                <div className="loginBox">
                    <h2>LOGIN</h2>
                    <ul className="socialMenu">
                        <li>
                            <div id="naverIdLogin"></div>
                        </li>
                        <li onClick={kakaoLogin}>
                            <img src={`${process.env.PUBLIC_URL}/images/member/kakao_icon.png`} alt="kakaotalk" />
                        </li>
                        <li>
                            <img src={`${process.env.PUBLIC_URL}/images/member/google_icon.png`} 
                                alt="google" 
                                onClick={handleLoginClick}
                            />
                        </li>
                    </ul>
                    <ul className="loginForm">
                    <li>
                        <label>Username</label>
                        <div className="iptWrap">
                        <input
                            type="text"
                            name="userId"
                            placeholder=""
                            className="ipt"
                            onChange={inputHandler}
                        />
                        </div>
                    </li>
                    <li>
                        <label>Password</label>
                        <div className="iptWrap">
                        <input
                            type="password"
                            name="userPassword"
                            placeholder=""
                            className="ipt"
                            onChange={inputHandler}
                        />
                        </div>
                    </li>
                    </ul>
                    <div className="info">
                    <div className="checkboxWrap">
                        <input type="checkbox" id="auto_login" />
                        <label htmlFor="auto_login">자동 로그인</label>
                    </div>
                    <p className="underline"><Link to={`${process.env.PUBLIC_URL}/signup`}>잡브 멤버십 가입하기</Link></p>
                    </div>
                    <button type="submit" className="loginBtn">
                    Sign In
                    </button>

                    {/* <p className="signupBtn">
                    <Link to={`${process.env.PUBLIC_URL}/signup`}>회원가입</Link>
                    </p> */}
                    <ul className="findMenu">
                        <li><Link to="/idInquiry"><button type="button">아이디찾기</button></Link></li>
                        <li><Link to="/pwInquiry"><button type="button">비밀번호 찾기</button></Link></li>
                    </ul>
                </div>
                </div>
            </form>
            </div>
        </div>
        </div>
    );
};

export default LoginPage;
