import "./Signup.css"
import {useState,useRef} from 'react';
import axios from 'axios';
import { useNavigate,useParams} from 'react-router-dom';
import { phone_check } from '../middlewares/textTest.js';

function InfoAdd ({setPrivacyPopup, privacyPopup,user,setUser}) {
const { userId } = useParams(); 
const navigate = useNavigate();
const agreeRef = useRef(null);
const privacyPopupHandler = () => {
    setPrivacyPopup(!privacyPopup);
}
 //Submit
 const onSubmitHandler = async (e) => {

    e.preventDefault();
    try {
        if(agreeRef.current.checked === false){
            alert('개인정보 처리방침에 동의해주세요.');
            return false;
        }

        if (inputValues.userPassword !== inputValues.userPassword2) {
            alert('비밀번호가 일치하지 않습니다.');
            return;
        }

        if(inputValues.userPhone.length!=0){
            if(!phone_check(inputValues.userPhone)){
                alert('전화번호 형식이 맞지 않습니다.');
                return
            }
        }

        const formData = new FormData();
        formData.append('userCompany', inputValues.userCompany);
        formData.append('userPhone', inputValues.userPhone);
        formData.append('userId', userId);
        console.log(userId)
        console.log(inputValues.userCompany)
        console.log(inputValues.userPhone)
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/users/update`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Content-Type을 'multipart/form-data'로 설정
                    },
                    withCredentials: true
                }
            );
            if (response.status === 200) {
                    if (response.data.message == "S") {
                        alert('로그인 되었습니다.');
                        let userData = JSON.stringify(response.data.data);
                        console.log(userData);
                        localStorage.setItem('user', userData);
                        setUser(userData);
                        const redirectURL = `/signup_success`; // 리디렉션할 URL 생성
                        navigate(redirectURL);
                        return;
                    } else {
                        alert(response.data.message);
                        return;
                    }
            } else {
              throw new Error('서버 응답 오류');
            }
        } catch (error) {
            console.log(error);
            alert(error);
        }
    } catch (error) {
        console.error(error);
    }
   
};
const [inputValues, setInputValues] = useState({
    userCompany: '',
    userPhone: '',
});


//input 핸들러
const handleInputChangeId = async (event) => {
    let { name, value } = event.target;
    let checkInfo;
    if(value.length<4){
        checkInfo="4자리 이상 입력해주세요.";
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
            checkId:checkInfo
        }));
        return
    }
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/checkId/${value}`);
    if (response.status === 200) {
        if(response.data.message=="S"){
            checkInfo="중복되지 않은 아이디입니다.";
        }else{
            checkInfo="중복된 아이디입니다.";
        }
    }
    setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: value,
        checkId:checkInfo
    }));

};


//input 핸들러
const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: value,
    }));
};


const phonehandleInputChange = (event) => {
    const { name, value } = event.target;
    const filteredValue = value.replace(/[^0-9]/g,"");
    event.target.value = filteredValue;
    setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [name]: filteredValue,
    }));
};
    return (
        <div className="rootConatiner">
            <div className="signupContainer">
                <div className="inner">
                    <div className="titleBox">
                        <h2>Jobofbrothers</h2>
                        <p>서비스 가입하기</p>
                    </div>
                    <form onSubmit={onSubmitHandler}>
                    <div className="signupForm">
                        <p className="produce">추가 정보를 입력해주세요</p>
                        <ul className="formList">
                            <li className="half">
                                <label>기관 혹은 기업명</label>
                                <div className="iptWrap">
                                    <input 
                                        type="text" 
                                        name="userCompany"
                                        onChange={handleInputChange}
                                        maxLength={255}
                                        className="ipt"
                                        placeholder="기업 또는 기관명을 입력해주세요."
                                    />
                                </div>
                            </li>
                            <li className="half">
                                <label>연락처</label>
                                <div className="iptWrap">
                                    <input 
                                        type="text" 
                                        name="userPhone"
                                        onChange={phonehandleInputChange}
                                        maxLength={11}
                                        className="ipt"
                                        placeholder="연락처를 입력해주세요."

                                    />
                                </div>
                            </li>
                        </ul>
                        <div className="checkboxWrap">
                            <input type="checkbox" id="agree" ref={agreeRef}/>
                            <label htmlFor="agree">개인정보 처리방침에 동의합니다. <span className="black"  onClick={privacyPopupHandler}>(전문보기)</span></label>
                        </div>
                        <button className="okBtn">가입 완료</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default InfoAdd;