import styled from 'styled-components';
import {Editor} from '@tinymce/tinymce-react';
import {useRef, useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import axios from 'axios';
const ColumnWrite = () => {
    const navigate = useNavigate();
    const editorRef = useRef(null);

    //Submit
    const onSubmitHandler = async (e) => {
        const formData = new FormData();
        e.preventDefault();
        console.log(inputValues.projectStart);
        try {
            const editorContent = editorRef.current.getContent();
            formData.append('columnCategory', inputValues.columnCategory);
            formData.append('columnTitle', inputValues.columnTitle);
            formData.append('columnSummary', inputValues.columnSummary);
            formData.append('columnTeam', inputValues.columnTeam);
            formData.append('columnLink1', inputValues.columnLink1);
            formData.append('columnLink2', inputValues.columnLink2);
            formData.append('columnFile1', inputValues.columnFile1);
            formData.append('columnFile2', inputValues.columnFile2);
            formData.append('columnContent', editorContent);
            formData.append('columnRecommend', inputValues.columnRecommend);
            for (let key of formData.keys()) {
                console.log(key, ":", formData.get(key));
            }
            
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/column/write`,formData);
                if (response.status === 200) {
                    const insertedId = response.data.insertedId; // 삽입된 ID 값
                    const redirectURL = `/column/view/${insertedId}`; // 리디렉션할 URL 생성
                    console.log(response);
                    navigate(redirectURL);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }
        } catch (error) {
            console.error(error);
        }
    };
    //input 정의
    const [inputValues, setInputValues] = useState({
        columnTitle: '',
        columnSummary: '',
        columnTeam: '',
        columnContent: '',
        columnLink1: '',
        columnLink2: '',
        columnFile1: '',
        columnFile2: '',
        columnRecommend:''
    });


    const handleCheckChange=(event) =>{
        const {name, checked} = event.target;
        // console.log(checked)
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: (checked) ? "1" : "" //check값이 true이면 1 저장
        }))
    };
    
    //input 핸들러
    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value
        }));
    };
    //file 핸들러
    const handleFileChange = (event) => {
        const {name, files} = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: files[0]
        }));
    };

    const handleFileCheckboxChange = (event) => {
        const {name, checked} = event.target;
        if (checked) {
            document.getElementById(name).value = '';
            setInputValues((prevInputValues) => ({...prevInputValues,[name]: null
            }));
        } else {
            setInputValues((prevInputValues) => ({...prevInputValues,[name]: ''
            }));
        }
    };

    return (
        <div className="rootConatiner">
            <div className='inner'>
                <WriteBox>
                    <form onSubmit={onSubmitHandler}>
                        <div className="write_page">
                            <ul className="full_box title_box">
                                <li>
                                    <span className="label">분류</span>
                                    <span className="label_content">
                                        <select
                                            name="columnCategory"
                                            onChange={handleInputChange}
                                            value={inputValues.columnCategory}>
                                            <option value="">분류를 선택해주세요</option>
                                            <option value="잡오브브라더스 시스템">잡오브브라더스 시스템</option>
                                            <option value="홈페이지 제작안내">홈페이지 제작안내</option>
                                            <option value="IT 정보">IT 정보</option>
                                        </select>
                                    </span>
                                </li>
                            </ul>
                            <ul className="full_box title_box">
                                <li>
                                    <span className="label">설정</span>
                                    <span className="label_content">
                                        <label htmlFor="notice">
                                            <input type="checkbox" id="notice" name="columnRecommend" value="1" onChange={handleCheckChange} />           
                                            추천컬럼</label>
                                    </span>
                                </li>
                            </ul>
                            <ul className="full_box title_box">
                                <li>
                                    <span className="label">제목</span>
                                    <span className="label_content">

                                        <input type="text" onChange={handleInputChange} name="columnTitle" value={inputValues.columnTitle} placeholder="제목"
                                            // required
                                        />
                                    </span>
                                </li>
                            </ul>
                            <ul className="half_box title_box">
                                <li>
                                    <span className="label">요약</span>
                                    <span className="label_content">
                                        <input
                                            type="text"
                                            name="columnSummary"
                                            onChange={handleInputChange}
                                            value={inputValues.columnSummary}
                                            placeholder="내용 요약을 넣어주세요."/>
                                    </span>
                                </li>
                                <li>
                                    <span className="label">팀</span>
                                    <span className="label_content">
                                        <input
                                            type="text"
                                            name="columnTeam"
                                            onChange={handleInputChange}
                                            value={inputValues.columnTeam}
                                            placeholder="팀을 입력해주세요 ex)디자인팀"/>
                                    </span>
                                </li>
                            </ul>

                            <div className="dhtml_box">

                                <Editor
                                    apiKey='h1qsn5by3dfyecmfgo98mwqbc07bz31gaupmyfaanahhlc1z'
                                    onInit={(evt, editor) => editorRef.current = editor}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        language: "ko",
                                        plugins: [
                                            'lists',
                                            'link',
                                            // 'image',
                                            // 'charmap',
                                            // 'preview',
                                            'searchreplace',
                                            'fullscreen',
                                            // 'media',
                                            'table',
                                            'code',
                                            'help',
                                            'emoticons',
                                            'codesample',
                                            'quickbars'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'lists table link charmap searchreplace | ' +
                                        'image media codesample emoticons fullscreen preview | ' +
                                        'removeformat | help | insert | hr ',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}/>
                            </div>
                            <div className="add_form">
                                <div className="add_box link_box"><input
                                    name="columnLink1"
                                    onChange={handleInputChange}
                                    value={inputValues.columnLink1}
                                    type="text"
                                    placeholder="링크를 입력해주세요."/></div>
                                <div className="add_box link_box"><input
                                    name="columnLink2"
                                    onChange={handleInputChange}
                                    value={inputValues.columnLink2}
                                    type="text"
                                    placeholder="링크를 입력해주세요."/></div>
                                <div className="add_box file_box">
                                    <input
                                        name="columnFile1"
                                        id="columnFile1"
                                        type="file"
                                        onChange={handleFileChange}/>
                                </div>
                                {
                                    inputValues.columnFile1 && (
                                        <div className="file_del_box">
                                            <label htmlFor="file_01" className="file_title">
                                                <input
                                                    name="columnFile1"
                                                    id="file_01"
                                                    type="checkbox"
                                                    checked={inputValues.columnFile1 === null}
                                                    onChange={handleFileCheckboxChange}/>
                                                <span>
                                                    {inputValues.columnFile1.name}
                                                    파일 삭제
                                                </span>
                                            </label>
                                        </div>
                                    )
                                }
                                <div className="add_box file_box">
                                    <input
                                        name="columnFile2"
                                        id="columnFile2"
                                        type="file"
                                        onChange={handleFileChange}/>
                                </div>
                                {
                                    inputValues.columnFile2 && (
                                        <div className="file_del_box">
                                            <label htmlFor="file_02" className="file_title">
                                                <input
                                                    name="columnFile2"
                                                    id="file_02"
                                                    type="checkbox"
                                                    checked={inputValues.columnFile2 === null}
                                                    onChange={handleFileCheckboxChange}/>
                                                <span>
                                                    {inputValues.columnFile2.name}
                                                    파일 삭제
                                                </span>
                                            </label>
                                        </div>
                                    )
                                }
                            </div>

                            <div className="write_btn_box">
                                <Link to="/column">
                                    <button type="button" className="cancelBtn">취소</button>
                                </Link>
                                <button type="submit" className="submitBtn">작성완료</button>
                            </div>
                        </div>
                    </form>
                </WriteBox>
            </div>
        </div>
    );
}

const WriteBox = styled.div `
    padding: 100px 0;
`;

export default ColumnWrite;
