import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollTop = ({handleScroll}) => {
    const {pathname} = useLocation();

    useEffect(() => {
        handleScroll()
        window.scrollTo(0,0);
    }, [pathname])

    return null; 
}

export default ScrollTop;