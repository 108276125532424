import React from 'react';
import { useState, useEffect } from "react";
import AdminMenu from 'components/AdminMenu';
import { Link,useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { formatDate } from '../middlewares/formatDate';

import { Helmet } from 'react-helmet';

const ProjectView = () =>{
    const { id } = useParams(); // Get the value of 'id' from the URL

    const [projectData, setProjectData] = useState(null);
  
    useEffect(() => {
        const fetchProjectData = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/project/${id}`);
            console.log(response);
            if (response.status === 200) {
              console.log(response);
              setProjectData(response.data.result[0]);
            } else {
              throw new Error('서버 응답 오류');
            }
          } catch (error) {
            console.log(error);
            alert(error);
          }
        };
    
        fetchProjectData();
      }, [id]);
    return (
        <div className="rootConatiner">

            {(projectData) ? 
                <>
                    <Helmet>
                        {/* SEO 코드를 넣는 부분*/}
                        <title>잡오브브라더스 : {projectData.projectName}</title>
                        <meta name="description" content={projectData.projectIntro} />
                    </Helmet>
                    <ProjectBanner bannerImg={`${process.env.REACT_APP_SERVER_URL}/uploads/project/${projectData.projectFile2}`}>
                        <div className="bgWrap">
                            <div className="bg"></div>
                        </div>
                        <div className="content">
                            <p className="t1">{projectData.projectCategory}</p>
                            <p className="title">{projectData.projectName}</p>
                            <p className="produce">{projectData.projectIntro}</p>
                        </div>
                    </ProjectBanner>

                    <InfoArticle>
                        <div className="inner">

                            <AdminMenu boardName = "project" id={projectData.id} />
                            
                            <ul className="info">
                                <li>
                                    <div className="title">고객사</div>
                                    <div className="content">{projectData.projectCompany}</div>
                                </li>
                                {/* <li>
                                    <div className="title">참여기간</div>
                                    <div className="content">{formatDate(projectData.projectStart)}
                                    {(projectData.projectEnd !== "1899-11-29T15:32:08.000Z") ? `~ ${formatDate(projectData.projectEnd)}` : null}</div>
                                </li>
                                <li>
                                    <div className="title">URL</div>
                                    <div className="content">
                                        {(projectData.projectUrl) ? 
                                            <a href={projectData.projectUrl} target="_blank" rel="noreferrer"> 
                                                {projectData.projectUrl}
                                            </a>
                                            :
                                            `-`
                                        }
                                    </div>
                                </li> */}
                            </ul>
                        </div>
                    </InfoArticle>

                    {
                        (projectData.projectFile3) ? 
                        <ResponsiveArticle>
                            <img src={`${process.env.REACT_APP_SERVER_URL}/uploads/project/${projectData.projectFile3}`} alt="반응형 시안" />
                        </ResponsiveArticle>
                        :
                        null
                    }
                    
                    {
                        ( projectData.projectFile4 ) ? 
                        <PortfolioMain>
                            <div className="inner">
                                {/* <img src={`${process.env.REACT_APP_SERVER_URL}/uploads/project/${projectFile4}`} alt="메인 시안"/> */}
                                <img src={`${process.env.REACT_APP_SERVER_URL}/uploads/project/${projectData.projectFile4}`} alt="메인 시안"/>
                            </div>
                        </PortfolioMain>
                        :
                        null
                    }

                    {
                        (projectData.projectFile5) ? 
                        <ResponsiveArticle imgMaxWidth bg={projectData.projectColorM}>
                            <img src={`${process.env.REACT_APP_SERVER_URL}/uploads/project/${projectData.projectFile5}`} alt="반응형 시안" />
                        </ResponsiveArticle>
                        :
                        null

                    }

                    <HomepageColor>
                        <div className="inner">
                            <p className="title">Homepage Color</p>
                            <ul className="color_list">
                                <li style={{backgroundColor : projectData.projectColor1}}>
                                    <div className="color">
                                        <span style={{color:(projectData.projectColor1 === '#ffffff') ? '#000' : '#FFF' }}>{projectData.projectColor1}</span>
                                    </div>
                                </li>
                                <li style={{backgroundColor : projectData.projectColor2}}>
                                    <div className="color">
                                        <span style={{color:(projectData.projectColor2 === '#ffffff') ? '#000' : '#FFF' }}>{projectData.projectColor2}</span>
                                    </div>
                                </li>
                                <li style={{backgroundColor : projectData.projectColor3}}>
                                    <div className="color">
                                        <span style={{color:(projectData.projectColor3 === '#ffffff') ? '#000' : '#FFF' }}>{projectData.projectColor3}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </HomepageColor>

                    <ProjectMoreBtn>
                        <div className="inner">
                            <Link to="/project" className="moreBtn" >
                                다른 포트폴리오 더 보기
                            </Link>
                        </div>
                    </ProjectMoreBtn>
                </>
                            
            :null
            }
        </div>
    );
}

const ProjectBanner = styled.article`
    padding: 100px 0; position: relative; 
    display: flex; align-items: center; justify-content: center;
    min-height: 350px;
    .bgWrap{     
        position: absolute; clip: rect(0, auto, auto, 0);
        left: 0; top: 0; width: 100%; height: 100%;
        background-color: #000;
        .bg{ 
            position: relative; display: block;
            top: 0; left: 0; width: 100%; height: 100%;
            background-size: cover; background-position: center; background-repeat: no-repeat;
            background-image : ${(props) => props.bannerImg ? `url(${props.bannerImg})` : `url(${process.env.PUBLIC_URL}/images/example_img.png)` }; 
            transform: translateZ(0); 
            opacity: 0.4;
        }
    }
    .content{ 
        position: relative; z-index: 1; transform: translateZ(0); text-align: center; 
        color: #fff;
        .t1{ font-weight: 300; }
        .title{ font-size: 40px; margin: 10px 0; font-weight: 600; }
        .produce{ font-size: 16px; }
    }

    @media screen and (max-width: 1250px) { 
        .content{ 
            .title{ font-size:30px; }
        }
    }
    @media screen and (max-width: 650px) { 
        .content{ 
            .t1{ font-size:14px; }
            .title{ font-size:22px; }
            .produce{ font-size: 14px; }
        }
    }
`;

const InfoArticle = styled.article`
    padding: 40px 0;
    .info{ 
        padding: 0 10px ; display: flex; flex-wrap: wrap; 
        > li{ 
            width: calc(100% / 3); padding: 0 10px; border-left: 2px solid var(--sub-color); 
            .title{ color: #252c41; font-weight: 500; font-size: 18px; }
            .content{ 
                margin-top: 5px; word-break: break-all; 
                a{ word-break: break-all; } 
            } 
        }
    }
    @media screen and (max-width: 1250px) {
        .inner{ width:100%; }
    }
    @media screen and (max-width: 800px) { 
        .info{ 
            
            > li{ 
                width:100%;
                + li{ margin-top:10px; }
            }
        }
    }
    @media screen and (max-width: 650px) { 
        .info{ 
            > li{ 
                .title{ font-size:16px; }
                .content{ font-size:14px; }
            }
        }
    }
`;

const ResponsiveArticle = styled.article`
    /* 
        - imgMaxWidth : 이미지가 width 100% 됩니다.
        - bg : 색상을 넘겨주면 해당 색상이 들어가고, 값을 안넘기면 배경색은 흰색입니다. (헥사코드로 props를 넘겨주세요)
    */
    overflow: hidden;
    img{ 
        display: block; margin: 0 auto; width:100%;
    }
    background-color:${(props) => props.bg ? props.bg : `#fff` };
    width: ${(props) => (props.imgMaxWidth) ? '100%':'auto' };
    @media screen and (max-width: 800px) { 
        display:flex; justify-content:center;
        img{ 
            /* transform: scale(1.5); */ 
            /* width: 150%; */
        }
    }
`;

const PortfolioMain = styled.article`
    padding: 50px 0; background-color: #f7f7f7; 
    img{ 
        margin: 0 auto; display: block; max-width: 100%; box-shadow: 5px 5px 5px rgb(200,200,200, 0.5); 
    }
`;

const HomepageColor = styled.article`
    margin: 100px 0;
    .title{ font-weight: 600; }
    .color_list{ 
        display: flex; flex-wrap: wrap; margin-top: 20px; 
        > li{ 
            flex-grow: 1; height: 150px; background-color: gray; 
            padding: 20px; display: flex; align-items: flex-end; color: #fff; font-weight: 300;
        } 
    }
    @media screen and (max-width: 1250px) { 
        margin: 60px 0;
    }
    @media screen and (max-width: 650px) { 
        margin:50px 0;
        .color_list{ font-size: 14px; }
    }
`;

const ProjectMoreBtn = styled.article`
    margin-bottom:100px; 
    .moreBtn{ 
        display: block; border:1px solid var(--sub-color); padding: 20px; text-align: center; 
        transition: background 0.5s, color 0.5s; cursor: pointer;
        &:hover{ background-color: var(--sub-color); color: #fff; }
    }
    
    @media screen and (max-width: 650px) { 
        margin-bottom: 50px;
        .moreBtn{ font-size:14px; padding:15px; }
    }
`


export default ProjectView;
