import { Link } from "react-router-dom";
import "./SignupSuccess.css"

function SignupSuccess () {
    return (
        <div className="rootConatiner">
            <div className="loginContainer">
                <div className="inner">
                    <div className="successBox">
                        <div className="contentBox">
                            <img src={`${process.env.PUBLIC_URL}/images/success_logo.png`} alt="jobofbrothers"/>
                            <h2>서비스 가입 완료</h2>
                            <div class="content">
                                원활한 사이트 이용을 위하여<br/>
                                마이페이지 {'>'} 사이트 이용방법 안내에서 <br/>
                                이용 방법을 확인해주세요.
                            </div>
                            <div className="btnWrap"> 
                                <Link to="/"><div class="mainBtn">메인으로</div></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignupSuccess;