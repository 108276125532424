import { Link } from 'react-router-dom';
import styled from 'styled-components'

function ServiceBottom () {
    return(
        <ServiceBox bgImg={`${process.env.PUBLIC_URL}/images/about_servicebox_bg.png`}>
            <div className="bgWrap">
                <div className="bg"></div>
            </div>
            <div className="inner">
                <div className="textBox">
                    <p className="title">90% 고객님의 서비스 유치</p>
                    <div className="content">
                        단순 웹사이트 제작뿐만이 아니라 고객님의 <br/>
                        서비스를 같이 고민하고 협업합니다.<br/>
                        개발부터 운영까지, 성공적인 온라인 비즈니스를 도와드립니다.
                    </div>
                    <Link to="/contact/write">
                        <div className="goBtn">
                            <span>상담하기</span>
                        </div>
                    </Link>
                </div>
            </div>
        </ServiceBox>
    ); 
}

const ServiceBox = styled.article`
    padding: 100px 0; position:relative; color:#fff;
    .bgWrap{
        clip: rect(0, auto, auto, 0); position: absolute; top: 0; left: 0; width: 100%; height: 100%;
        .bg{ 
            background-image: ${(props) => props.bgImg ? `url(${props.bgImg})` : `url(${process.env.PUBLIC_URL}/images/example_img.png)` }; 
            position: fixed; transform: translateZ(0); display: block;
            top: 0; left: 0; width: 100%; height: 100%; background-size: cover; background-position: center center;
        }
    }
    .inner{ position:relative; z-index: 1; transform:translateZ(0); }
    .textBox{ 
        text-align:center;  
        .title{ font-size:32px; font-weight:600; }
        .content{ margin-top:20px; font-size:20px; line-height:1.5em; font-weight:300; }
    }

    .goBtn{ 
        border:1px solid #fff; font-size:18px; display:block; max-width:200px; padding:15px; text-align:center; 
        margin:50px auto 0 auto; position:relative;
        span{ position:relative; z-index:1; transition:color 1s;  }
        :before{ 
            position:absolute; content:''; display:block; width:0%; height:100%; background-color:#fff; left:0; top:0; 
            transition: width 1s;
        } 
        &:hover{ 
            span{ color:#222; }
            :before{ width:100%;  }
        }
    }
    @media screen and (max-width: 1250px) {
        .textBox{ 
            .title{ font-size:25px; }
            .content{ font-size:16px; }
        }
        .goBtn{ font-size:16px; max-width:150px; padding:10px; }
    }

    @media screen and (max-width: 650px) {
        .textBox{ 
            .title{ font-size:20px; }
            .content{ font-size:14px; }
        }

        .goBtn{ font-size:14px; }
    }
`

export default ServiceBottom ; 

