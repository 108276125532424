
import React from "react";
import MypageContainer from "../../../components/mypage/MyPageContainer";
import MyPageContent from "../../../components/mypage/MyPageContent";
import MyPageHeader from "../../../components/mypage/MyPageHeader";
import MypageMenu from "../../../components/mypage/MyPageMenu";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './MemberAdditional.module.css'; // CSS Modules 파일 import


const MemberAdditional = () => {

    const navigate = useNavigate();
    // const [inputValues, setInputValues] = useState({
    //     userId: '',
    //     userPassword: '',
    //     userPassword2: '',
    //     userName: '',
    //     userCompany: '',
    //     userPhone: '',
    //     userEmail: '',
    //     userLevel : '',
    //     checkId: '4자리 이상 입력해주세요.',
    // });


    const onSubmitHandler = (e) => {
        e.preventDefault();

    }
    

    return(
        <div className="mypageContainer">
            <MypageMenu />
            <MypageContainer>
                <MyPageHeader  title="회원관리" />
                <MyPageContent>
                    <form onSubmit={onSubmitHandler}>
                        <div className={styles.memberContainer}>
                            <div className="write_page">
                                <ul className="half_box title_box">
                                    <li>
                                        <span className="label">아이디</span>
                                        <span className="label_content">
                                            <input type="email" name="" value="" placeholder="아이디를 4자리 이상 입력해주세요."/>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="label">비밀번호</span>
                                        <span className="label_content">
                                            <input type="password" name="" value="" placeholder="비밀번호를 입력해주세요"/>
                                        </span>
                                    </li>
                                </ul>
                                <ul className="full_box title_box">
                                    <li>
                                        <span className="label">권한설정</span>
                                        <span className="label_content">
                                            <select name="">
                                                <option value="">권한을 선택해주세요</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                            </select>
                                        </span>
                                    </li>
                                </ul>
                                <ul className="half_box title_box">
                                    <li>
                                        <span className="label">기관 혹은 기업명</span>
                                        <span className="label_content">
                                            <input type="password" name="" value="" placeholder="기관 혹은 기업명을 입력해주세요."/>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="label">성함</span>
                                        <span className="label_content">
                                            <input type="text" name="" value="" placeholder="성함을 입력해주세요."/>
                                        </span>
                                    </li>
                                </ul>
                                <ul className="half_box title_box">
                                    <li>
                                        <span className="label">이메일</span>
                                        <span className="label_content">
                                            <input type="email" name="" value="" placeholder="이메일을 입력해주세요."/>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="label">연락처</span>
                                        <span className="label_content">
                                            <input type="text" name="" value="" placeholder="연락처를 입력해주세요 (-)제외"/>
                                        </span>
                                    </li>
                                </ul>
                                <ul className="half_box title_box">
                                    <li>
                                        <span className="label">직급</span>
                                        <span className="label_content">
                                            <input type="email" name="" value="" placeholder="직급을 입력해주세요."/>
                                        </span>
                                    </li>
                                </ul>

                                <div className="write_btn_box">
                                    <button type="button" 
                                        onClick={() => {navigate(-1)}} 
                                        className="cancelBtn"
                                        style={{cursor:"pointer"}}
                                    >
                                        취소
                                    </button>
                                    <button 
                                        type="submit" 
                                        className="submitBtn"
                                        style={{cursor:"pointer"}}
                                    >
                                        회원등록
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </MyPageContent>
            </MypageContainer>
        </div>
    )
}

export default MemberAdditional; 