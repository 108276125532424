import "./Signup.css"
import {useState} from 'react';
import axios from 'axios';

const MemberFindId = () => {
    //Submit
    const onSubmitHandler = async (e) => {

    e.preventDefault();
    try {
        const formData = new FormData();
        formData.append('userName', inputValues.userName);
        formData.append('userEmail', inputValues.userEmail);
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/findId`, formData);
            if (response.status === 200) {
                    if (response.data.message == "S") {
                        alert("아이디는 "+response.data.data+"입니다.");
                        return
                    } else {
                        alert(response.data.message);
                        return;
                    }
            } else {
              throw new Error('서버 응답 오류');
            }
        } catch (error) {
            console.log(error);
            alert(error);
        }
    } catch (error) {
        console.error(error);
    }
    };
    const [inputValues, setInputValues] = useState({
        userName: '',
        userEmail: '',
    });
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
        }));
    };
    return(
        <div className="rootConatiner">
            <div className="signupContainer">
                <div className="inner">
                    <div className="titleBox">
                        <h2>Jobofbrothers</h2>
                        <p>아이디 찾기</p>
                    </div>
                    <form onSubmit={onSubmitHandler}>
                        <div className="signupForm">
                            <p className="produce">아이디 찾기</p>
                            <ul className="formList">
                                <li className="half">
                                    <label>성함</label>
                                    <div className="iptWrap">
                                        <input 
                                            type="text" 
                                            name="userName"
                                            maxLength={255}
                                            className="ipt"
                                            placeholder=""
                                            onChange={handleInputChange}
                                        />
                                        <p class="help">가입하신 이름을 입력 해주세요.</p>
                                    </div>
                                </li>
                                <li className="half">
                                    <label>이메일</label>
                                    <div className="iptWrap">
                                        <input 
                                            type="email" 
                                            name="userEmail"
                                            maxLength={255}
                                            className="ipt"
                                            placeholder=""
                                            onChange={handleInputChange}

                                        />
                                        <p class="help">회원가입 시 등록한 이메일을 입력해주세요.</p>
                                    </div>
                                </li>
                            </ul>

                            <button className="okBtn">확인</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default MemberFindId;