import { useState,useEffect } from "react";
import { useNavigate,useParams } from "react-router-dom";

import MypageContainer from "../../components/mypage/MyPageContainer";
import MyPageContent from "../../components/mypage/MyPageContent";
import MyPageHeader from "../../components/mypage/MyPageHeader";
import MypageMenu from "../../components/mypage/MyPageMenu";
import axios from 'axios';
import styles from './PayMentView.module.css'; // CSS Modules 파일 import
import { Link } from "react-router-dom";



const PayMentView = () => {
    const { id } = useParams(); // Get the value of 'id' from the URL
    const navigate = useNavigate(); // useHistory 대신 useNavigate 사용
    const goBack = () => {
        navigate(-1); // 이전 페이지로 이동
    };

    const [paymentData, setPaymentData] = useState(null);
    useEffect(() => {
        const fetchPaymentData = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/mypage/payment/${id}`);
            if (response.status === 200) {
                setPaymentData(response.data.paymentdata);
            } else {
              throw new Error('서버 응답 오류');
            }
          } catch (error) {
            console.log(error);
            alert(error);
          }
        };
        fetchPaymentData();
    }, [id]);
    let gradePay=0;
    let sumPay=0;
    const numberWithCommas = (number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    if(paymentData){
        switch(paymentData.paymentGrade){
            case '베이직': gradePay=500000; break;
            case '레어': gradePay=1000000; break;
            case '에픽': gradePay=1500000; break;
            case '전설': gradePay=2000000; break;
            case '신화': gradePay=2500000; break;
        }
        if(paymentData.paymentNew){
            sumPay=gradePay-50000;
        }else{
            sumPay=gradePay
        }
    }


    const formattedAmountGrade = numberWithCommas(gradePay);
    const formattedAmountSum = numberWithCommas(sumPay);
    let [inputValues, setInputValues] = useState({
        paymentClientName:'',
        paymentClientPhone:'',
        paymentClientApplicantEmail:'',
        paymentCategory:'',
        paymentMethod:'',
        paymentNew:''
    });

   //input 핸들러
   const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
        }));
    };
    const handleInputRadioChange = (event) => {
        setInputValues({
          ...inputValues,
          paymentMethod: event.target.value // 선택된 라디오 버튼의 값으로 업데이트
        });
    };
    //Submit
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('paymentClientName', inputValues.paymentClientName);
            formData.append('paymentClientPhone', inputValues.paymentClientPhone);
            formData.append('paymentMethod', inputValues.paymentMethod);
            formData.append('paymentClientApplicantEmail', inputValues.paymentClientApplicantEmail);
            formData.append('paymentCategory', inputValues.paymentCategory);
            formData.append('sumPay', sumPay);
            console.log(sumPay)
            console.log(inputValues.paymentClientName)
            console.log(inputValues.paymentClientPhone)
            console.log(inputValues.paymentMethod)
            console.log(inputValues.paymentClientApplicantEmail)
            console.log(inputValues.paymentCategory)
            return
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/pay/${id}`, formData);
                if (response.status === 200) {
                    const redirectURL = `/project/view/${id}`; // 리디렉션할 URL 생성
                    console.log(response);
                    navigate(redirectURL);
                } else {
                  throw new Error('서버 응답 오류');
                }
              } catch (error) {
                console.log(error);
                alert(error);
              }


        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (paymentData) {
            setInputValues({
                paymentClientName: paymentData.paymentClientName,
                paymentClientPhone: paymentData.paymentClientPhone,
                paymentClientApplicantEmail: paymentData.paymentClientEmail,
                paymentCategory: paymentData.paymentCategory,
                paymentMethod: paymentData.paymentMethod,
            });
        }
    }, [paymentData]);

    return (
        (paymentData)?
        <div className="mypageContainer">
            <MypageMenu />
            <MypageContainer>
                <MyPageHeader  title="결제관리" />
                <MyPageContent>
                <form onSubmit={onSubmitHandler}>
                    <div className={styles.menuBar}>
                        <div className={styles.back_icon} onClick={goBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M13.3334 7.33317H5.22008L8.94675 3.6065L8.00008 2.6665L2.66675 7.99984L8.00008 13.3332L8.94008 12.3932L5.22008 8.6665H13.3334V7.33317Z" fill="#0C0C0C"/>
                            </svg>
                        </div>
                        <p>OOO건 결제 진행상황</p>
                    </div>
                    <div className={styles.paymentContainer}>
                        <h2>요금 상세정보</h2>
                        <div className={styles.payInfoBox}>
                            <ul className={styles.payInfoWrap}>
                                <li>
                                    <label>{paymentData.paymentGrade} 요금</label>
                                    <p>{(formattedAmountGrade)?formattedAmountGrade:null}원</p>
                                </li>
                                {/* {(inputValues.paymentCategory)?
                                <li>
                                    <label>{inputValues.paymentCategory} 요금</label>
                                    <p>{(formattedAmountCategory)?formattedAmountCategory:null}원</p>
                                </li>
                                :
                                null
                                } */}
                                {(paymentData.paymentNew)?
                                <li>
                                    <label>신규 할인</label>
                                    <p className={styles.red}>-50,000원</p>
                                </li>
                                :
                                null
                                }
                            </ul>
                            <div className={styles.totalBox}>
                                <label>총 합계</label>
                                <p className={styles.price}>{formattedAmountSum}원</p>
                            </div>
                        </div>
                        <ul className={styles.payList}>
                            <li>
                                <div className={styles.label}>이름</div>
                                <div className={styles.iptWrap}>
                                    <input type="text" name="paymentClientName" className={styles.ipt} value={inputValues.paymentClientName} onChange={handleInputChange}/>
                                </div>
                            </li>
                            <li>
                                <div className={styles.label}>휴대폰번호</div>
                                <div className={styles.iptWrap}>
                                    <input type="text" name="paymentClientPhone" className={styles.ipt} value={inputValues.paymentClientPhone} onChange={handleInputChange} />
                                </div>
                            </li>
                            <li>
                                <div className={styles.label}>이메일</div>
                                <div className={styles.iptWrap}>
                                    <input type="text" name="paymentClientApplicantEmail" placeholder={paymentData.paymentClientEmail} className={styles.ipt} value={inputValues.paymentClientApplicantEmail} onChange={handleInputChange} />
                                </div>
                            </li>
                            <li>
                                <div className={styles.label}>작업내용</div>
                                <div className={styles.iptWrap}>
                                   <select className={styles.select}  name="paymentCategory"  value={inputValues.paymentCategory} onChange={handleInputChange} >
                                        <option value="사이트 유지보수">사이트 유지보수</option>
                                        <option value="디자인 상품">디자인 상품</option>
                                        {/* <option value="">작업 내용을 선택해주세요</option>
                                        <option value="">작업 내용을 선택해주세요</option>
                                        <option value="">작업 내용을 선택해주세요</option> */}
                                   </select>
                                </div>
                            </li>
                            <li className={styles.full}>
                                <div className={styles.label}>결제수단</div>
                                <div className={styles.iptWrap}>
                                    <div className={styles.radioBox}>
                                        <input type="radio" name="paymentMethod" id="pay_radio_01" value='가상계좌'  onChange={handleInputRadioChange} required/>
                                        <label htmlFor="pay_radio_01">가상계좌</label>
                                    </div>
                                    <div className={styles.radioBox}>
                                        <input type="radio" name="paymentMethod" id="pay_radio_02" value='계좌이체'  onChange={handleInputRadioChange}/>
                                        <label htmlFor="pay_radio_02">계좌이체</label>
                                    </div>
                                    <div className={styles.radioBox}>
                                        <input type="radio" name="paymentMethod" id="pay_radio_03" value='휴대폰'  onChange={handleInputRadioChange}/>
                                        <label htmlFor="pay_radio_03">휴대폰</label>
                                    </div>
                                    <div className={styles.radioBox}>
                                        <input type="radio" name="paymentMethod" id="pay_radio_04" value='신용카드'   onChange={handleInputRadioChange}/>
                                        <label htmlFor="pay_radio_04">신용카드</label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul className={styles.btnWrap}>
                            <li>
                                <button type="button" className={styles.whiteBtn} >문의하기</button>
                                {/* 문의하기 버튼 클릭 시 채팅으로 이동합니다. */}
                            </li>
                            <li>
                                <button type="submit" className={styles.blackbtn} >주문하기</button>
                                {/* 해당 버튼 클릭시 결제 화면이 나옵니다 (API연동일것 같습니다.) */}
                            </li>
                        </ul>
                    </div>
                </form>
                </MyPageContent>
            </MypageContainer>
        </div>
        :
        null
    );
}

export default PayMentView; 