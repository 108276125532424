import React, { useEffect } from 'react';
import './MouseEffect.css';
import { useState } from 'react';

export default function MouseEffect() {

  const [xy, setXY] = useState({x : 0, y : 0})
  const isTouchDevice = (navigator.maxTouchPoints || 'ontouchstart' in document.documentElement);


  const xyHandler = (e) => {
    const mouseX = e.clientX;
    const mouseY = e.clientY;

    setXY({x : mouseX, y: mouseY});
  }

  useEffect(() => {
    if(!isTouchDevice) { document.addEventListener("mousemove", xyHandler); }
    
    return () => {
      if(!isTouchDevice) {  document.removeEventListener("mousemove", xyHandler); }
    }
  }, [])

  return (
      (!isTouchDevice) ? 
        <div className='pointer' style ={{ transform : `translate(${xy.x}px, ${xy.y}px)` }} ></div>
      : 
        null
  );
}