import { useEffect, useState } from 'react';
import styled from 'styled-components'

function Intro(){
    const [showIntro, setShowIntro] = useState(true); //true일때 인트로가 보이고, false일때 안보임! 


    useEffect(() => {
        // 3초 후에 인트로 화면을 숨김
        document.body.style.overflow = 'hidden';

        const timer = setTimeout(() => {
          setShowIntro(false);
          document.body.style.overflow = 'auto';
        }, 1500);
    
        // 컴포넌트가 언마운트될 때 타이머를 클리어
        return () => {
            clearTimeout(timer);
            document.body.style.overflow = 'auto';
        };
    }, []);

    return(
        <IntroSection className={showIntro ? null : 'hidden'}>
            <img src={`${process.env.PUBLIC_URL}/images/loading-icon.gif`} alt="JOB OF BROTHERS"/>
        </IntroSection>
    );
}

export default Intro; 

const IntroSection = styled.article`
    
    position: fixed; z-index: 9999; width: 100%; height: 100%; 
    background-color: #fff; display: flex; align-items: center; justify-content: center;
    transition: opacity 0.5s, visibility 0.5s; overflow: hidden;

    img{ width:10%; max-width:100px; }

    &.hidden{ 
        opacity: 0; visibility: hidden;
    }
`;