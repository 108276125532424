import AdminMenu from 'components/AdminMenu';
import styled from 'styled-components';
import { useState, useEffect } from "react";
import axios from 'axios';
import { Link,useParams } from 'react-router-dom';
import { formatDate } from '../middlewares/formatDate';


const ContactView = () => {
    const { id } = useParams(); // Get the value of 'id' from the URL

    const [contactData, setcontactData] = useState(null);
  
    useEffect(() => {
        const fetchcontactData = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/contact/${id}`);
    
            if (response.status === 200) {
              console.log(response);
              setcontactData(response.data.result[0]);
            } else {
              throw new Error('서버 응답 오류');
            }
          } catch (error) {
            console.log(error);
            alert(error);
          }
        };
    
        fetchcontactData();
      }, [id]);
    
    //   if (!contactData) {
    //     return <div>Loading...</div>;
    //   }
    //   const {contactCategory,contactTitle,contactSummary,contactContent,contactTeam,contactDate} = contactData;

    return(
        <div className="rootConatiner">
            <div className='inner'>
                {(contactData) ? 
                    <ViewContainer>
                        <div className='titleBox'>
                            <p className="category">{contactData.contactCategory}</p>
                            <p className='title'>{contactData.contactTitle}</p>
                            <p className="content">{contactData.contactSummary}</p>
                            <p className="info">{contactData.contactTeam}/ <span className='date'>{formatDate(contactData.contactSchedule)}</span></p>
                        </div>
                        <AdminMenu 
                            boardName="contact" 
                            id={id}
                            listLink = "/contact"
                        />
                        <div className='contentBox'>
                            <h2 style={{fontSize : '1.2em'}}>01. 기본정보를 입력해주세요</h2><br/>
                            {(contactData.contactCompany) && <>- 기관 혹은 기업명 : {contactData.contactCompany} <br/></>}
                            {(contactData.contactName) && <> - 담당자 성함 : {contactData.contactName} <br/> </>}
                            {(contactData.contactReference) && <> - 연락처 : {contactData.contactReference} <br/> </>}
                            {(contactData.contactEmail) && <> - 이메일 : {contactData.contactEmail} <br/> </>}
                            {(contactData.contactUrl) && <> - 홈페이지 URL : {contactData.contactUrl} <br/> </>}
                            {(contactData.contactRoute) && <> - 홈페이지를 알게 된 경로 : {contactData.contactRoute} <br/> </>}
                            <br/>
                            {
                                (contactData.contactPage || contactData.contactSchedule || contactData.contactPage || contactData.contactReference || contactData.contactContent ) &&
                                <>
                                    <h2 style={{fontSize : '1.2em'}}>02. 작업해야 할 프로젝트를 알려주세요</h2><br/>
                                </>
                            }
                            
                            {(contactData.contactPage) && <> - 대략적인 페이지 수 : {contactData.contactPage} <br/> </>}
                            {(contactData.contactSchedule) && <> - 오픈예정일 : {formatDate(contactData.contactSchedule)} <br/> </>}
                            {(contactData.contactPage) && <> - 홈페이지 분야 : {contactData.contactCategory} <br/> </>}
                            {(contactData.contactReference) && <> - 참고 레퍼런스 : {contactData.contactReference} <br/> </>}
                            {(contactData.contactContent) && 
                                <>
                                <br/>
                                <h2 style={{fontSize : '1.2em'}}>03. 자세한 의뢰 내용</h2><br/>
                                {contactData.contactContent}
                                </>
                            }
                        </div>
                    </ViewContainer>
                : 
                    null
                }
                
            </div>
        </div>
    )
}

const ViewContainer = styled.div`
    padding: 100px 0;

    .titleBox{ 
        padding-bottom:40px; margin-bottom:40px; border-bottom: 1px solid #dfdfdf;

        .category{ font-weight:700;  }
        .title{ font-weight:700; margin:10px 0; font-size:35px; }
        .content{ margin-top:10px; font-size:18px; }
        .info{ margin-top:40px; font-size:14px; .date{ color: #7f7f7f; } }
    }
    .contentBox{ 
        img{ max-width:100%;  }
    }

    @media screen and (max-width: 1250px) {
        .titleBox{ 
            .title{ font-size:30px; }
            .content{ font-size:16px; }
        }
    }

    @media screen and (max-width: 650px) {
        padding: 50px 0;
        .titleBox{ 
            .category{ font-size:14px; }
            .title{ font-size:25px; }
        }
    }

`;


export default ContactView;