import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import Login from 'routes/LoginPage';
// import Mypage from 'components/Mypage';

const App_login = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState(null);

  const loginHandler = () => {
    setIsLogin(true);
  };

  const setUserInfo = (object) => {
    setUserData(object);
  };

  const logoutHandler = () => {
    setIsLogin(false);
  };

  return (
    <div className="App">
      {isLogin ? (
        // <Mypage logoutHandler={logoutHandler} userData={userData} />
        <Link to="/mypage">
            MYPAGE
        </Link>
        // <Link to="/member" onClick={MenuHandler}>
        //  MYPAGE
        // </Link>
      ) : (
        // <Login loginHandler={loginHandler} setUserInfo={setUserInfo} />
        <Link to="/login">
            LOGIN
        </Link>
        // <Link to="/member" onClick={MenuHandler}>
        //  MYPAGE
        // </Link>
      )}
    </div>
  );
};

export default App_login;
