import AdminMenu from 'components/AdminMenu';
import {Link, useParams} from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import {useState, useEffect} from 'react';
import axios from 'axios';
import {formatDate} from '../middlewares/formatDate';
import {Helmet} from 'react-helmet';
import { AiOutlineLoading } from "react-icons/ai";
import InfiniteScroll from 'react-infinite-scroll-component';

const Column = () => {
    let {category} = useParams();

    const [columnData, setColumnData] = useState([]);
    const [columnRecommendData, setColumnRecommendData] = useState();
    const [loading, setLoading] = useState(true); 
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true); //hasMore이 있으면 더 불러올 값이 있다는것임! 
    useEffect(() => {
        window.onbeforeunload = function pushRefresh() {
          window.scrollTo(0, 0);
        };
    
    }, []);

    const fetchcolumnData = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/column`,
                {
                    params: {
                      category: category || 'all',
                      page: page,
                      sumPerPage: 4,
                    },
                    headers: { 'Content-Type': 'application/json' }
                }
            );    
            if (response.status === 200) {
                setPage(page + 1)
                setColumnData((prevData) => ([...prevData, ...response.data.category[0]]));
                setColumnRecommendData(response.data.recommend[0])
                if(response.data.category[0].length === 0 ){
                    setHasMore(false)
                }
                setLoading(false);
            }  else {
                throw new Error('서버 응답 오류');
            }
        } catch (error) {
            alert(error);
        }
    };

    useEffect(() => {
        setColumnData([]);
        setHasMore(true);
        fetchcolumnData();
    }, [category])

    const categoryChangeHandler = (e) => {
        setPage(1); 
    }


    return (
        <div className="rootConatiner">
            <Helmet>
                {/* SEO 코드를 넣는 부분*/}
                <title>잡오브브라더스 : COLUMN</title>
                <meta name="description" content="잡오브브라더스를 만들어가는 개발자들의 이야기"/>
            </Helmet>
            <BannerArti bgImage={`${process.env.PUBLIC_URL}/images/column_banner_bg.png`}>
                <div className='inner'>
                    <div className='textBox'>
                        <p className="title">COLUMN</p>
                        <p className='content'>
                            잡오브브라더스를 만들어가는 개발자들의 이야기
                        </p>
                    </div>
                </div>
            </BannerArti>

                <ContentArti>
                    <div className="inner">
                        
                        <AdminMenu boardName="column" type="list"/>
                        <div className="listBox">
                            {/* <label htmlFor="allCheck" className='allCheck'>
                                <input type="checkbox"
                                    id="allCheck"
                                    name="check"
                                    value='selectall'
                                    // onClick={selectAll()}
                                />
                                전체선택
                            </label> */
                            }   
                            

                                <ColumnList>
                                    <InfiniteScroll
                                        dataLength={columnData.length} // 현재 데이터 배열의 길이
                                        next={fetchcolumnData} // 스크롤이 끝에 도달하면 실행될 함수
                                        hasMore={hasMore} // 더 많은 데이터가 있는지 여부
                                        loader={
                                            <LoadingBox>
                                                {/* <img src={`${process.env.PUBLIC_URL}/images/column-loading-icon.gif`} alt="loading"/> */}
                                                <AiOutlineLoading />
                                            </LoadingBox>
                                        } // 데이터를 가져올 때 표시될 로딩 표시기
                                    >
                                        <ul>
                                        {
                                            (!columnData)?
                                            <LoadingBox>
                                                {/* <img src={`${process.env.PUBLIC_URL}/images/column-loading-icon.gif`} alt="loading"/> */}
                                                <AiOutlineLoading />
                                            </LoadingBox>
                                            :
                                            columnData.map((column) => (
                                                //컬럼 수정시 31번 라인으로 이동
                                                <li key={column.id}>
                                                    {/* <input type="checkbox" name='check'/> */}
                                                    <Link to={`${process.env.PUBLIC_URL}/column/view/${column.id}`}>
                                                        <ColumnItem
                                                            bgImage={`${process.env.REACT_APP_SERVER_URL}/uploads/column/${column.columnFile1}`}>
                                                            <div className="imgBox"></div>
                                                            <div className="contentBox">
                                                                <p className='category'>{column.columnCategory}</p>
                                                                <p className='title'>{column.columnTitle}</p>
                                                                <p className="info">{column.columnTeam}
                                                                    / {formatDate(column.columnDate)}</p>
                                                            </div>
                                                        </ColumnItem>
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                        {/* {
                                            (loading && !hasMore) && 
                                            <LoadingBox>
                                                <AiOutlineLoading />
                                            </LoadingBox>
                                        } */}
                                        </ul>
                                    </InfiniteScroll>
                                </ColumnList>


                                <FloatingMenu>
                                    <li>
                                        <p className="title">당신이 찾는 주제들</p>
                                        <ul className="list">
                                            <li>
                                                <Link to="/column/category/잡오브브라더스 시스템" onClick={() => categoryChangeHandler()}><span>잡오브브라더스 시스템</span></Link>
                                            </li>
                                            <li>
                                                <Link to="/column/category/홈페이지 제작안내" onClick={() => categoryChangeHandler()}><span>홈페이지 제작안내</span></Link>
                                            </li>
                                            <li>
                                                <Link to="/column/category/IT 정보" onClick={() => categoryChangeHandler()}><span>IT 정보</span></Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p className="title">추천 컬럼</p>
                                        {
                                            (columnRecommendData)
                                                ? columnRecommendData.map((column,index) => (
                                                    <ul className="list" key={`column-${index}`}>
                                                        <li>
                                                        <Link to={`/column/view/${column.id}`}>
                                                            <span>{column.columnTitle}</span>
                                                        </Link>
                                                        </li>
                                                    </ul>
                                                ))
                                                : null
                                        }
                                    </li>
                                </FloatingMenu>


                            </div>
                        </div>
                </ContentArti>
        </div>
    );
}

const rotate = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const LoadingBox = styled.div`
    padding: 50px 0;
    display: flex; align-items: center; justify-content: center;
    width: 100%; font-size: 35px;
    svg{ animation: ${rotate} 1.5s linear infinite; }

    img{ 
        width: 50%; max-width: 100px;
    }
    @media screen and (max-width: 650px) {
        img{ max-width:60px; }
    }
`;



const BannerArti = styled.article `
    padding: 200px 0 100px 0; background-size: cover; background-repeat: no-repeat; background-position: center;
    background-image : ${ (
    props
) => props.bgImage
    ? `url(${props.bgImage})`
    : `url(${process.env.PUBLIC_URL}/images/example_img.png)`};
    color: #fff;

    .title{ font-size:56px; font-weight:700; }
    .content{ font-size:18px; margin-top:20px; }

    @media screen and (max-width: 1250px) {
        .title{ font-size:40px; }
        .content{ font-size:16px; }
    }
    @media screen and (max-width: 650px) {
        .title{ font-size:35px; }
        .content{ font-size:14px; margin-top:10px; }
    }
`;
const ContentArti = styled.article `
    padding: 100px 0; 
    .listBox{ 
        position:relative; display: flex; flex-wrap: wrap;
        .allCheck{ 
            input[type="checkbox"]{ margin-right:5px; }
        }
        .ListContainer{ display:flex; }
    }
    @media screen and (max-width: 900px) {
        .ListContainer{ flex-wrap:wrap; }
    }
`;
const ColumnList = styled.div `
    width: calc(100% - 400px); margin-left: -10px;
    ul{
        display: flex; flex-wrap: wrap;
        > li { 
            width: calc(100% / 2); padding: 10px;
        }
    }
     
    @media screen and (max-width: 1250px) {
        /* padding-right: 300px; */
        width: calc(100% - 250px);
    }
    @media screen and (max-width: 900px) {
        padding-right: 0;
        width: calc(100% + 10px);  order: 1; margin-left: -5px;
        ul{
            li { 
                padding: 5px;
            }
        } 
    }
    @media screen and (max-width: 300px) {
        ul{
            li { width:100%; }
        }
    }
`;
const ColumnItem = styled.div `
    background-color: #dfdfdf; overflow: hidden;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    .imgBox{ 
        background-image : ${ (
    props
) => props.bgImage
    ? `url(${props.bgImage})`
    : `url(${process.env.PUBLIC_URL}/images/example_img.png)`};
        background-size:cover; background-repeat:no-repeat; background-position:center; transition:transform 0.5s;
        height:24vw; max-height:460px;
    }

    .contentBox{
        background-color:#fff; padding: 20px 0;
        .category{ font-weight:700; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
        .title{ margin-top:10px; font-weight:700; font-size:24px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
        .content{ margin-top:10px; color: #7f7f7f; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; }
        .info{ margin-top:20px; color: #7f7f7f;  }
    }

    @media screen and (max-width: 900px) {
        .imgBox{ max-height:unset; height:35vw; }
    }

    @media screen and (max-width: 650px) {
        .contentBox{
            padding:15px 10px;
            .category{ font-size:14px; }
            .title{ font-size:16px; }
            .content{ font-size:14px; }
            .info{ font-size:14px; }
        }
    }
    @media screen and (max-width: 300px) {
        .imgBox{ height:74vw; }
    }
`;
const FloatingMenu = styled.ul `
    /* position: absolute; right: 0; top: 0; */
    width: 370px; padding: 0 20px; padding-left: 30px; overflow: hidden;
    > li{
        & + li{ margin-top:20px; }
    }
    .title{ font-size:25px; font-weight:700; }
    .list{
        display: flex; flex-wrap: wrap; width: calc(100% + 10px); margin-left: -5px;
        margin-top: 10px; font-size: 16px; color: #7f7f7f;
        > li{ 
            padding:5px; 
            span{ 
                display:inline-block; border:1px solid #7f7f7f; padding:10px 20px; border-radius:60px; 
                background-color: #fff; word-break: break-all;
            } 
        }
    }

    @media screen and (max-width: 1250px) {
        width: 250px;
        .list{
            >li{
                span{ font-size:14px; padding: 5px 10px; } 
            }
        }
    }
    @media screen and (max-width: 900px) {
        width: 100%;
        padding:0; margin:10px 0 40px 0; 
    }
`;

export default Column;


