import MypageContainer from "../../../components/mypage/MyPageContainer";
import MyPageContent from "../../../components/mypage/MyPageContent";
import MyPageHeader from "../../../components/mypage/MyPageHeader";
import MypageMenu from "../../../components/mypage/MyPageMenu";
import styles from '../MyProject.module.css'; // CSS Modules 파일 import
import styled from 'styled-components';
import PagingBox from '../../../components/PagingBox';
import {Link} from 'react-router-dom';


const AdmPayMentView = () => {
    return (
        <div className="mypageContainer">
            <MypageMenu />
            <MypageContainer>
                <MyPageHeader  title="결제관리" />
                <MyPageContent>
                    <div className={styles.memberContainer}>
                        <div className="view_page">
                            <ul className="full_title titleBox">
                                <li>
                                    <span className="label">결제내역번호</span>
                                    <span className="label_content">
                                        결제내역번호
                                    </span>
                                </li>
                            </ul>
                            <ul className="three_title titleBox">
                                <li>
                                    <span className="label">고객성함</span>
                                    <span className="label_content">
                                        고객성함
                                    </span>
                                </li>
                                <li>
                                    <span className="label">플랜내용</span>
                                    <span className="label_content">플랜내용</span>
                                </li>
                                <li>
                                    <span className="label">결제금액</span>
                                    <span className="label_content">플랜내용</span>
                                </li>
                            </ul>
                            <ul className="three_title titleBox">
                                <li>
                                    <span className="label">상태</span>
                                    <span className="label_content">플랜내용</span>
                                </li>
                                <li>
                                    <span className="label">날짜</span>
                                    <span className="label_content">20-05-18 13:22</span>
                                </li>
                                <li>
                                    <span className="label">관리</span>
                                    <span className="label_content"> 
                                        <AdmBtn type="button">목록</AdmBtn>
                                        <Link to="/mypage/admpayment/modify">
                                            <AdmBtn type="button">수정</AdmBtn>
                                        </Link>
                                        <AdmBtn type="button">삭제</AdmBtn>
                                    </span>
                                </li>
                            </ul>
                            

                            <div className="view_content" style={{padding:"10px"}}>
                                <img src="./img/board_img/about_img04.png" />
                                어떤 디자이너들은 명쾌한 방법론을 갈망하는 한편, 어떤 디자이너들은 창의적인 발상을 제약하는 사고 방식이라며 발끈하기도 한다. <br/>
                                하지만, 혼란스럽거나 즉흥적인 접근 방식은 바람직하다고 이야기하기 어렵다. <br/>
                                여기서 설명하고 있는 방법론은 제약적인 프로세스라기보다는 모든 상황에서 따라 할 수 있는 조리법처럼 받아들여지기를 바란다. <br/>
                                하지만 기계적으로 따라야만 하는 방법은 없다. <br/>
                                디자이너의 창의적인 번뜩임은 전기와 같고 그 방법은 전기를 가장 잘 이용할 수 있는 곳으로 전달하는 전력망 이라고 상상해보자.
                            </div>

                            <div className="add_box" style={{padding:"10px"}}>
                                <a href="#" className="add_item file">
                                    <span className="add_title">File.jpg (741.5K)</span>
                                    <span className="add_content">1회 다운로드 | DATE : 2020-05-05- 17:05:20</span>
                                </a>
                                <a href="#" className="add_item file">
                                    <span className="add_title">File.jpg (741.5K)</span>
                                    <span className="add_content">1회 다운로드 | DATE : 2020-05-05- 17:05:20</span>
                                </a>
                                <a href="#" className="add_item link" target="_blank">
                                    <span className="add_title">http://www.link.com</span>
                                    <span className="add_content">34회 연결</span>
                                </a>
                                <a href="#" className="add_item link" target="_blank">
                                    <span className="add_title">http://www.link.com</span>
                                    <span className="add_content">34회 연결</span>
                                </a>
                            </div>

                            <div className="p_n_box">
                                <div className="prev_box p_n_item">
                                    <span className="label">이전글</span>
                                    <span className="p_n_title">
                                        <a href="#">
                                            이전글 제목영역입니다. 
                                        </a>
                                    </span>
                                </div>
                                <div className="next_box p_n_item">
                                    <span className="label">다음글</span>
                                    <span className="p_n_title">
                                        <a href="#">
                                            다음글 제목영역입니다. 다음글 제목영역입니다. 다음글 제목영역입니다. 
                                            다음글 제목영역입니다. 다음글 제목영역입니다. 다음글 제목영역입니다. 
                                            다음글 제목영역입니다. 다음글 제목영역입니다. 
                                        </a>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                </MyPageContent>
            </MypageContainer>
        </div>
    )
}

const AdmBtn = styled.button`
  font-size: 14px; padding: 5px; cursor: pointer; border-radius: 4px;
  background-color: #fff; color: #5f5f5f; border:1px solid #e6e6e6;
  margin-right: 10px;
`;


export default AdmPayMentView; 