import AdminMenu from 'components/AdminMenu';
import styled from 'styled-components';
import { useState, useEffect } from "react";
import axios from 'axios';
import { Link,useParams } from 'react-router-dom';
import { formatDate } from '../middlewares/formatDate';

import { Helmet } from 'react-helmet';

const ColumnView = () => {
    const { id } = useParams();
    const [columnData, setcolumnData] = useState(null);
    useEffect(() => {
        const fetchcolumnData = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/column/${id}`);
    
            if (response.status === 200) {
              console.log(response);
              setcolumnData(response.data.result[0]);
            } else {
              throw new Error('서버 응답 오류');
            }
          } catch (error) {
            console.log(error);
            alert(error);
          }
        };
    
        fetchcolumnData();
      }, [id]);
    
    //   if (!columnData) {
    //     return <div>Loading...</div>;
    //   }
    //   const {columnCategory,columnTitle,columnSummary,columnContent,columnTeam,columnDate} = columnData;

    return(
        <div className="rootConatiner">
            <div className='inner'>
                {
                    (columnData) ? 
                    <ViewContainer>
                        <Helmet>
                            {/* SEO 코드를 넣는 부분*/}
                            <title>잡오브브라더스 : {columnData.columnTitle}</title>
                            <meta name="description" content={columnData.columnSummary} />
                        </Helmet>
                        <div className='titleBox'>
                            <p className="category">{columnData.columnCategory}</p>
                            <p className='title'>{columnData.columnTitle}</p>
                            <p className="content">{columnData.columnSummary}</p>
                            <p className="info">{columnData.columnTeam}/ <span className='date'>{formatDate(columnData.columnDate)}</span></p>
                        </div>
                        <AdminMenu boardName="column" id={id} />
                        <div className='contentBox' dangerouslySetInnerHTML={ {__html: columnData.columnContent} } >
                            {/* {columnContent} */}
                        </div>
                    </ViewContainer>
                    : 
                    null
                }
                
            </div>
        </div>
    )
}

const ViewContainer = styled.div`
    padding: 100px 0;

    .titleBox{ 
        padding-bottom:40px; margin-bottom:40px; border-bottom: 1px solid #dfdfdf;
        .category{ font-weight:700;  }
        .title{ font-weight:700; margin:10px 0; font-size:35px; word-break:break-all; }
        .content{ margin-top:10px; font-size:18px; }
        .info{ margin-top:40px; font-size:14px; .date{ color: #7f7f7f; } }
    }
    .contentBox{ 
        line-height:2em;
        img{ max-width:100%; padding:10px 0; } 
        h1{ font-size:24px; line-height:1.5em; }
        h2{ font-size:22px; line-height:1.5em; }
        h3{ font-size:20px; line-height:1.5em; }
        h4{ font-size:18px; }
        h5{ font-size:16px; }
        h6{ font-size:14px; }
        pre{ 
            background-color:#eee; text-align:left; padding:10px; margin:10px 0; 
            overflow-x: scroll;
            code{ 
                word-break:break-all; overflow-wrap: break-word; 
            }
        }
        hr{ background-color: #eee; margin:10px 0; }
        blockquote{ 
            position:relative; padding-left:15px; 
            ::before{ 
                    content:''; display:block;
                    position:absolute; width: 4px; height: 100%;
                    background-color: rgb(223, 223, 223); left: 0; top: 0;
                }
        }

    }

    @media screen and (max-width: 1250px) {
        .titleBox{ 
            .title{ font-size:30px; }
            .content{ font-size:16px; }
        }
        .contentBox{ 
            h1{ font-size:22px; }
            h2{ font-size:20px; }
            h3{ font-size:18px; }
            h4{ font-size:16px; }
            h5{ font-size:14px; }
            h6{ font-size:12px; }
        }
    }

    @media screen and (max-width: 650px) {
        padding: 50px 0;
        .titleBox{ 
            .category{ font-size:14px; }
            .title{ font-size:25px; }
        }
        .contentBox{ 
            h1{ font-size:20px; }
            h2{ font-size:18px; }
            h3{ font-size:16px; }
            h4{ font-size:14px; }
            h5{ font-size:12px; }
            h6{ font-size:10px; }
        }
    }

`;


export default ColumnView;