import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import {GoogleOAuthProvider} from "@react-oauth/google";
import About from "routes/About";
import Column from "routes/Column";
import ColumnView from "routes/ColumnView";
import ColumnUpdate from "routes/ColumnUpdate";
import ContactView from "routes/ContactView";
import ContactUpdate from "routes/ContactUpdate";
import InfoAdd from "routes/InfoAdd";
import ColumnWrite from "routes/ColumnWrite";
import Home from "routes/Home";
import Project from "routes/Project";
import ProjectView from "routes/ProjectView";
import ProjectWrite from "routes/ProjectWrite";
import ProjectUpdate from "routes/ProjectUpdate";
import Service from "routes/Service";
import './App.css';
import Footer from "./Footer";
import Header from "./Header";

import Contact from "routes/Contact";
import Location from "routes/Location";
import ContactList from "routes/ContactList";
import ScrollTop from "./ScrollTop";
import ContactModal from "./ContactModal";
import MouseEffect from "./MouseEffect";
import FloatingMenu from "./FloatingMenu";
import Intro from "./Intro";
import RouteChangeTracker from './RouteChangeTracker';

import axios from 'axios';


//session관련 추가
import App_login from "./App_login";
// import Mypage from 'routes/Mypage';
import LoginPage from "routes/LoginPage";
import Signup from "routes/Signup";
import SignupSuccess from "routes/SignupSuccess";
import MemberFindId from "../routes/MemberFindId";
import MemberFindPW from "../routes/MemberFindPW";
import Mypage from "../routes/mypage/Mypage";
import ModifyMember from "routes/mypage/ModifyMember";
import MyProject from "routes/mypage/MyProject";
import MyProjectView from "routes/mypage/MyProjectView";
import Price from "routes/mypage/Price";
import PriceView from "routes/mypage/PriceView";
import PayMent from "routes/mypage/PayMent";
import PayMentView from "routes/mypage/PayMentView";
import { MyPageContextProvider } from "./mypage/MyPageContext";
import MypageChatList from "../routes/mypage/MypageChatList";
import MypageChat from "../routes/mypage/MypageChat";
import MyProject_e from "../routes/mypage/employee/MyProject_e";
import MypageChat_e from "../routes/mypage/employee/MyPageChat_e";
import Member from "../routes/mypage/admin/Member";
import MemberAdditional from "../routes/mypage/admin/MemberAdditional";
import MemberModify from "../routes/mypage/admin/MemberModify";
import ProjectManagement from "../routes/mypage/admin/Project";
import ProjectModify from "../routes/mypage/admin/ProjectModify";
import AdmProjectWrite from "../routes/mypage/admin/ProjectWrite";
import AdmProjectView from "../routes/mypage/admin/ProjectView";
import ChatManage from "../routes/mypage/admin/ChatManage";
import AdmPayment from "../routes/mypage/admin/AdmPayment";
import AdmPayMentView from "../routes/mypage/admin/AdmPayMentView";
import AdmPaymentModify from "../routes/mypage/admin/AdmPaymentModify";
import AdminBannerModify from "../routes/mypage/admin/AdminBannerModify";



// import MyPageContext from "./mypage/MyPageContext";
//session관련 추가 끝


const handleScroll = () => {
    const scroll_y = window.scrollY + (window.innerHeight / 1.5) ;
    const animationContainer = document.querySelectorAll('.ani')
    animationContainer.forEach(element => {
        const itemTop = element.getBoundingClientRect().top + window.scrollY;
        if((scroll_y) > itemTop){
            element.classList.add('on');
        }else{
            element.classList.remove('on');
        }
    });
};

const App = () => {
    // RouteChangeTracker();
    const [menuStatus, setMenuStatus] = useState(false); /* 헤더 메뉴 active 상태, true일땐 보이고, false일땐 안보임 */
    const [privacyPopup, setPrivacyPopup ] = useState(false); /* 개인정보 처리방침 active상태, true일때 보이고, false일때 안보임 */
    const [contactModal , setContact] = useState(false); /* contact 모달창 active , true일땐 보이고, false일땐 안보임. */
    const [user , setUser] = useState(localStorage.getItem('user')); 
    const [userData, setUserdata] = useState(null);
    const [alarmData, setAlarmData] = useState(null);


    //1. 스토리지에 저장된 시간과 현재 시간을 비교한다.
    //2. 시간이 넉넉하면, 서버의 쿠키와 비교한다.
    //3. 모두다 통과하면 원하는 데이터를 출력해준다.
    // setUser(localStorage.getItem('user'));
    
    const session = async () => {
        if (user) {
            const userdData = JSON.parse(user);
            const dateObject = new Date(userdData.cookie.expires);
            if (dateObject.getTime() > new Date().getTime()) {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_SERVER_URL}/mypage/userinfo`,
                        {headers: {'Content-Type': 'application/json',}, withCredentials: true,}
                    );
                    if (response.status === 200) {
                       if(response.data.message=='N'){
                        console.log(response);
                    }
                    } else {
                        throw new Error('서버 응답 오류');
                    }
                } catch (error) {
                    alert(error);
                }
            } else {
                localStorage.removeItem('user');
            }
        }
    };
      
    useEffect(() => {
        const fetchData = async () => {
            await session();
        };
        fetchData();
        // clean-up 함수 (선택사항)
        return () => {
            // clean-up 로직 수행 (필요한 경우)
        };
    }, [user]);

    /* 스크롤 이벤트 */
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll); //clean up
        }
    }, [])
    
    useEffect(() => {
        const getUserData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_SERVER_URL}/mypage/userinfo`,
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        withCredentials: true
                    }
                );
                if (response.status === 200) {
                    setUserdata(response.data.data[0])
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                alert(error);
            }
        }
        if(user){
            getUserData();
        }
    }, [user]);

    // useEffect(() => {
    //     const getAlarmData = async () => {
    //         try {
    //             const response = await axios.get(
    //                 `${process.env.REACT_APP_SERVER_URL}/mypage/alarm/${userData.userEmail}`
    //             );
    //             if (response.status === 200) {
    //                 setAlarmData(response.data)
    //             } else {
    //                 throw new Error('서버 응답 오류');
    //             }
    //         } catch (error) {
    //             alert(error);
    //         }
    //     }
    //     if(userData){
    //         getAlarmData();
    //     }
    // }, [userData]);
    // if (user !== null &&( !userData || !alarmData)) {
    //     return <Intro />;
    // }
    return (
        <>
            <BrowserRouter>
                <MyPageContextProvider value={{ user, userData, alarmData }}>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
                    <Header 
                    setMenuStatus={setMenuStatus} 
                    menuStatus={menuStatus}
                    setPrivacyPopup={setPrivacyPopup} 
                    privacyPopup={privacyPopup}
                    user={user}
                    setUser={setUser}
                    setUserdata={setUserdata}
                    setAlarmData={setAlarmData}
                    />
                    <ScrollTop handleScroll={handleScroll}/>
                    <Intro />
                    <MouseEffect />
                    <Routes>
                        <Route path="/" element={<Home contactModal={setContact}/>}/>
                        <Route path="/about" element={<About />}/>
                        <Route path="/service" element={<Service />}/>
                        <Route path="/project" element={<Project />}/>
                        <Route path="/project/view/:id" element={<ProjectView />} />
                        <Route path="/project/write" element={<ProjectWrite />} />
                        <Route path="/project/update/:id" element={<ProjectUpdate />} />
                        <Route path="/project/write/:id" element={<ProjectWrite />} /> 
                        <Route path="/column" element={<Column />} />
                        <Route path="/column/category/:category" element={<Column />} />
                        <Route path="/column/write" element={<ColumnWrite />} />
                        <Route path="/column/view/:id" element={<ColumnView />} />
                        <Route path="/column/update/:id" element={<ColumnUpdate />} />
                        <Route path="/contact/update/:id" element={<ContactUpdate />} />
                        <Route path="/location" element={<Location />}></Route>
                        <Route path="/contact/write" element={<Contact setPrivacyPopup={setPrivacyPopup} privacyPopup={privacyPopup} />} />
                        <Route path="/contact/view/:id" element={<ContactView />} />
                        <Route path="/contact/:pageNum?" element={<ContactList user={user}/>}></Route>
                        <Route path="/login"  element={<LoginPage setUser={setUser} user={user} />}/> 
                        <Route path="/signup" element={<Signup setPrivacyPopup={setPrivacyPopup} privacyPopup={privacyPopup}/>} /> 
                        <Route path="/signup_success" element={<SignupSuccess />}/> 
                        <Route path="/infoadd/:userId" element={<InfoAdd user={user} setUser={setUser} />}/> 
                        <Route path="/app_login" element={<App_login />}/> 

                            {/* 마이페이지 - 일반  */}
                            { /* 
                                나중에 조건부 랜더링으로 주소를 통합할 예정입니다!
                                ex ) 아래는 switch 문이라고 생각하시면 됩니다! 
                                <Route path="/mypage/chat" element={
                                    level === 1 ? <MypageChatLevel1 /> :
                                    level === 2 ? <MypageChatLevel2 /> :
                                    level === 3 ? <MypageChatLevel3 /> :
                                    <FallbackComponent />
                                } />
                            */}
                            <Route path="/mypage" element={<Mypage/>}/>
                            <Route path="/mypage/modify" element={<ModifyMember userData={userData}/>} />
                            <Route path="/mypage/myproject/:pageNum?" element={<MyProject/>}/>
                            <Route path="/mypage/myproject/view" element={<MyProjectView user={user} setUser={setUser} />} />
                            <Route path="/mypage/price" element={<Price user={user} setUser={setUser} />} />
                            <Route path="/mypage/price/view" element={<PriceView user={user} setUser={setUser} />} />
                            <Route path="/mypage/payment/:pageNum?" element={<PayMent user={user} setUser={setUser} />} />
                            <Route path="/mypage/payment/view/:id" element={<PayMentView user={user} setUser={setUser} />} />
                            
                            <Route path="/mypage/myproject/:pageNum?" element={<MyProject/>}/>
                            <Route path="/mypage/mypageChatList" element={<MypageChatList />} />
                            <Route path="/mypage/chat/:id" element={<MypageChat />} />
                            
                            {/* 마이페이지 - 직원 */}
                            <Route path="/mypage/e/myproject/:pageNum?" element={<MyProject_e />} />
                            <Route path="/mypage/e/chat" element={<MypageChat_e />} />
                            {/* 마이페이지 - 최고관리자*/}
                            <Route path="/mypage/member_list" element={<Member />} />
                            <Route path="/mypage/member_list/write" element={<MemberAdditional />} />
                            <Route path="/mypage/member_list/modify" element={<MemberModify />} />
                            <Route path="/mypage/project" element={<ProjectManagement />} />
                            <Route path="/mypage/project/view" element={<AdmProjectView />} />
                            <Route path="/mypage/project/write" element={<AdmProjectWrite />} />
                            <Route path="/mypage/project/modify" element={<ProjectModify />} />
                            <Route path="/mypage/chatmanage" element={<ChatManage />} />
                            <Route path="/mypage/chatmanage" element={<ChatManage />} />
                            <Route path="/mypage/admpayment" element={<AdmPayment />} />
                            <Route path="/mypage/admpayment/view" element={<AdmPayMentView />} />
                            <Route path="/mypage/admpayment/modify" element={<AdmPaymentModify />} />
                            <Route path="/mypage/banner_modify" element={<AdminBannerModify />} />
                        <Route path="/idInquiry" element={<MemberFindId />}/>
                        <Route path="/pwInquiry" element={<MemberFindPW />}/>
                    </Routes>

                    <ContactModal 
                        contactStatus={contactModal}
                        contactModal={setContact}
                        setPrivacyPopup={setPrivacyPopup} 
                        privacyPopup={privacyPopup}
                    />
                    <FloatingMenu />
                    <Footer setPrivacyPopup={setPrivacyPopup} privacyPopup={privacyPopup} />
                </GoogleOAuthProvider>
                </MyPageContextProvider>
            </BrowserRouter>
        </>
    )
}

export default App;