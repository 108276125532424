import { useEffect } from 'react';
import styled from 'styled-components';

const MyPageContinaerBox = styled.div`
    height: calc(100vh - 40px); flex-grow: 1;
    display: flex; flex-direction: column;
    position: relative;
    /* overflow-y: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    &::-webkit-scrollbar{
        display: none; 
    } */
    @media screen and (max-width: 1680px) {
        height: 100vh;
        height: -webkit-fill-available;
        height: fill-available;
    }
`;

const MypageContainer = (props) => {

    let vh = 0;

    useEffect(() => {
        vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, []);

    return( 
        <MyPageContinaerBox>
            {props.children}
        </MyPageContinaerBox>
    )
}

export default MypageContainer;