import React, { createContext, useState } from 'react';

// Context 객체 생성
const MyPageContext = createContext();

// Context의 Provider 컴포넌트 생성
const MyPageContextProvider = ({ children, value }) => {
    const [data, setData] = useState(value);
    return (
        <MyPageContext.Provider value={{ data, setData }}>
        {children}
        </MyPageContext.Provider>
    );
};

export { MyPageContext, MyPageContextProvider };
