import MypageContainer from "../../../components/mypage/MyPageContainer";
import MyPageContent from "../../../components/mypage/MyPageContent";
import MyPageHeader from "../../../components/mypage/MyPageHeader";
import MypageMenu from "../../../components/mypage/MyPageMenu";
import {Editor} from '@tinymce/tinymce-react';


import styles from './MemberAdditional.module.css'; // CSS Modules 파일 import

const AdmPaymentModify = () => {

    return(
        <div className="mypageContainer">
            <MypageMenu />
            <MypageContainer>
                <MyPageHeader  title="결제관리" />
                <MyPageContent>
                <div className={styles.memberContainer}>
                        <div className="write_page">
                            <ul className="full_box title_box">
                                <li>
                                    <span className="label">결제내역번호</span>
                                    <span className="label_content">
                                        결제내역번호(수정불가)
                                    </span>
                                </li>
                            </ul>
                            <ul className="full_box title_box">
                                <li>
                                    <span className="label">상태</span>
                                    <span className="label_content">
                                        <select name="">
                                            <option value="">상태를 선택해주세요</option>
                                            <option value="접수완료">접수완료</option>
                                            <option value="디자인 작업중">디자인 작업중</option>
                                            <option value="퍼블리싱 작업중">퍼블리싱 작업중</option>
                                            <option value="개발 작업중">개발 작업중</option>
                                        </select>
                                    </span>
                                </li>
                            </ul>
                            <ul className="half_box title_box">
                                <li>
                                    <span className="label">플랜내용</span>
                                    <span className="label_content">
                                        <input type="text" name="" value="" placeholder="플랜내용을 입력해주세요"/>
                                    </span>
                                </li>
                                <li>
                                    <span className="label">결제금액</span>
                                    <span className="label_content">
                                        <input type="text" name="" value="" placeholder="결제금액을 입력해주세요(,제외 원 제외하고 입력)"/>
                                    </span>
                                </li>
                            </ul>

                            <div className="dhtml_box">
                                <Editor
                                    apiKey='h1qsn5by3dfyecmfgo98mwqbc07bz31gaupmyfaanahhlc1z'
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        language: "ko",
                                        plugins: [
                                            'lists',
                                            'link',
                                            // 'image',
                                            // 'charmap',
                                            // 'preview',
                                            'searchreplace',
                                            'fullscreen',
                                            // 'media',
                                            'table',
                                            'code',
                                            'help',
                                            'emoticons',
                                            'codesample',
                                            'quickbars'
                                        ],
                                        toolbar: 'undo redo | blocks | ' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'lists table link charmap searchreplace | ' +
                                        'image media codesample emoticons fullscreen preview | ' +
                                        'removeformat | help | insert | hr ',
                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                    }}/>
                            </div>

                            <div className="add_form">
                                <div className="add_box link_box"><input name="" type="text" placeholder="링크를 입력해주세요." /></div>
                                <div className="add_box link_box"><input name="" type="text" placeholder="링크를 입력해주세요." /></div>
                                <div className="add_box file_box">
                                    <input name="" type="file" />
                                </div>
                                <div className="file_del_box">
                                    <label for="file_01" className="file_title"><input type="checkbox" id="file_01"/>file.jpg(11.4K) 파일 삭제</label>
                                </div>
                                <div className="add_box file_box">
                                    <input name="" type="file"/>
                                </div>
                                <div className="file_del_box">
                                    <label for="file_02" className="file_title"><input type="checkbox" id="file_02"/>file.jpg(11.4K) 파일 삭제</label>
                                </div>
                            </div>

                            <div className="write_btn_box">
                                <a href="#" className="cancelBtn">취소</a>
                                <button type="submit" className="submitBtn">작성완료</button>
                            </div>
                        </div>
                    </div>
                </MyPageContent>
            </MypageContainer>
        </div>
    )


}

export default AdmPaymentModify;