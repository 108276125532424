import { useState,useContext } from "react";
import { useNavigate,useLocation} from "react-router-dom";
import MypageContainer from "../../components/mypage/MyPageContainer";
import MyPageContent from "../../components/mypage/MyPageContent";
import MyPageHeader from "../../components/mypage/MyPageHeader";
import MypageMenu from "../../components/mypage/MyPageMenu";
import axios from 'axios';
import styles from './PriceView.module.css'; // CSS Modules 파일 import
import { MyPageContext } from "../../components/mypage/MyPageContext";


const PriceView = () => {
    const { data } = useContext(MyPageContext);
     //Submit
     const onSubmitHandler = async (e) => {
        const formData = new FormData();
        e.preventDefault();
        try {
            formData.append('paymentContent', inputValues.paymentContent);
            formData.append('paymentCategory', paymentCategory);
            formData.append('paymentGrade', paymentGrade);
            formData.append('paymentClientEmail', data.userData.userEmail);
            formData.append('paymentPhone', data.userData.userPhone);
            formData.append('paymentName', data.userData.userName);
            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/mypage/paymentwrite`,formData);
                if (response.status === 200) {
                    alert('신청이 완료되었습니다');
                    const redirectURL = `/mypage`; // 리디렉션할 URL 생성
                    navigate(redirectURL);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const [inputValues, setInputValues] = useState({
        paymentContent: '',
    });
    //input 핸들러
    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value
        }));
    };
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const paymentCategory = searchParams.get('paymentCategory');
    const paymentGrade = searchParams.get('paymentGrade');
    const navigate = useNavigate(); // useHistory 대신 useNavigate 사용
    const goBack = () => {
        navigate(-1); // 이전 페이지로 이동
    };
    return(
        <div className="mypageContainer">
            <MypageMenu />
            <MypageContainer>

                <MyPageHeader  title="가격안내" />
                <form onSubmit={onSubmitHandler}>

                <MyPageContent>
                    <div className={styles.menuBar}>
                        <div className={styles.back_icon} onClick={goBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M13.3334 7.33317H5.22008L8.94675 3.6065L8.00008 2.6665L2.66675 7.99984L8.00008 13.3332L8.94008 12.3932L5.22008 8.6665H13.3334V7.33317Z" fill="#0C0C0C"/>
                            </svg>
                        </div>
                        <p>플랜  신청하기</p>
                    </div>
                    <div className={styles.planContainer}>
                        <ul className={styles.planForm}>
                            <li>
                                <div className={styles.label}>서비스 유형</div>
                                <div className={styles.inptWrap}>
                                    <div className={styles.txt}>{paymentCategory}</div>
                                </div>
                            </li>
                            <li>
                                <div className={styles.label}>서비스 유형</div>
                                <div className={styles.inptWrap}>
                                    <div className={styles.txt}>{paymentGrade}</div>
                                </div>
                            </li>
                            <li>
                                <div className={styles.label}>기타 문의사항</div>
                                <div className={styles.inptWrap}>
                                    <textarea className={styles.textArea}
                                    name="paymentContent"
                                    onChange={handleInputChange}
                                    value={inputValues.paymentContent}
                                    ></textarea>
                                </div>
                            </li>
                        </ul>
                        <button type="submit" className={styles.submitBtn}>신청완료</button>
                        {/*
                            "신청이 완료되었습니다."기본 얼럿창 위 
                            기본 얼럿창 위 문구로 부탁드리고,
                            "메인 대시보드"으로 화면 떨어지게 해주세요!
                        */}
                    </div>
                </MyPageContent>
                </form>
            </MypageContainer>
        </div>
    )

}

export default PriceView; 