import { useEffect, useRef } from 'react';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import "./Header.css"
import axios from 'axios';



const Header = ({setMenuStatus , menuStatus, setPrivacyPopup, privacyPopup, user, setUser,setUserdata,setAlarmData}) => { 
    /* 
        menuStatus는 현재 메뉴 active상태  ( true이면 보이고, false이면 안보이게!! )
    */






    const navigate = useNavigate();

    const {pathname} = useLocation();

    const MenuHandler = () => { setMenuStatus(!menuStatus); } //메뉴 열기
    const LogoHandler = () => { 
        setMenuStatus( false ); 
        window.scrollTo({
            top: 0,
            left: 0, 
            behavior: 'smooth'
        }); 
    } //메뉴 닫기

    const privacyPopupHandler = (e) => {
        e.preventDefault();
        setPrivacyPopup(!privacyPopup);
    }
    
    const logoutRequestHandler = async (e) => {
        e.preventDefault();
        // try {
    //     if (window.Kakao.Auth.getAccessToken()) {
    //     window.Kakao.API.request({
    //     url: '/v1/user/unlink',
    //     success: function (response) {
    //     	console.log(response)
    //     },
    //     fail: function (error) {
    //       console.log(error)
    //     },
    //   })
    //   window.Kakao.Auth.setAccessToken(undefined)
    // }
            localStorage.clear();
            setUser(null);
            setUserdata(null);
            setAlarmData(null);
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/users/logout`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                }
            );
            console.log(response.data);
            if (response.data.message == "S") {
                alert('로그아웃 되었습니다.');
                const redirectURL = `/`;
                navigate(redirectURL);
            } else {
                alert('존재하지 않는 회원입니다.');
            }
        // } catch (error) {
        //     console.log(error);
        //     alert(error);
        // }
    };

    if(!pathname.startsWith('/mypage')){
        return(
            <header  className={(pathname === '/' ) ? '':'on'}> {/* class on넣으면 색 검정색 */}
                <div></div>
                <div className="inner">
                    <div className="logoBox">
                        <Link to="/" onClick={LogoHandler}>
                            <img src={`${process.env.PUBLIC_URL}/images/header_logo.svg`} alt="잡오브브라더스" />
                            <img src={`${process.env.PUBLIC_URL}/images/logo_black.svg`} alt="잡오브브라더스" />
                        </Link>
                    </div>
                    <div className={(menuStatus) ? "menuBtn closeBtn" : "menuBtn"} onClick={MenuHandler}>
                        <span className="menu">menu</span>
                        <div className="btn">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div className={`menuBox ${(menuStatus) ? 'open' : ''}`}>
                    <div className="inner">
                        <ul className="menuList">
                            <li>
                                <Link to="/about" onClick={MenuHandler}>
                                    ABOUT
                                </Link>
                            </li>
                            <li>
                                <Link to="/service" onClick={MenuHandler}>
                                    SERVICE
                                </Link>
                            </li>
                            <li>
                                <Link to="/project" onClick={MenuHandler}>
                                    PROJECT
                                </Link>
                            </li>
                            <li>
                                <Link to="/column" onClick={MenuHandler}>
                                    COLUMN
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact/write" onClick={MenuHandler}>
                                    CONTACT
                                </Link>
                            </li>
                        </ul>
    
                        <ul className="LoginBox">
                            {user ? (
                                <>
                                    <li><Link to="/mypage" user={user}  onClick={MenuHandler}>MyPage</Link></li>
                                    <li><Link to="/mypage" onClick={logoutRequestHandler}>Logout</Link></li>
                                </>
                            ) : (
                                <li><Link to="/login" user={user}  onClick={MenuHandler}>Membership Login</Link></li>
                            )}
                        </ul>
    
                        <div className="company_txt">
                            Elevating the Potential of Web
                        </div>
    
                        <div className="menuBottom">
                            <div className="leftWrap">
                                <div className="infoMenu1">
                                    <ul className="infoWrap1">
                                        <li>
                                            <p onClick={privacyPopupHandler} className="cursor">개인정보처리방침</p>
                                        </li>
                                        {/* <li>
                                            <p>이용약관</p>
                                        </li> */}
                                    </ul>
                                    <ul className="infoWrap2">
                                        <li className="popupList">
                                            소재지
                                        </li>
                                    </ul>
                                </div>
    
                                <div className="infoMenu2">
                                    <ul className="infoWrap1">
                                        <li>
                                            <p>JOB Of Brothers</p>
                                        </li>
                                        <li>
                                            <p>사업자 등록번호 : 457-15-01816</p>
                                        </li>
                                    </ul>
                                    <ul className="infoWrap2">
                                        <li className="popupList address">
                                            서울특별시 금천구 가산디지털2로 173, 11층 1117호
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <ul className="company">
                                <li><a href="https://www.instagram.com/jobofbrothers/" target="_blank" rel="noreferrer">instagram</a></li>
                                <li><a href="https://pf.kakao.com/_RCxoxib" target="_blank" rel="noreferrer">Kakao</a></li>
                                <li><a href="https://blog.naver.com/jobofbrothers" target="_blank" rel="noreferrer">Blog</a></li>
                            </ul>
    
                            <ul className="company">
                                <li>business email</li>
                                <li className="underline"><a href="mailto:jobofbrothers@naver.com">jobofbrothers@naver.com</a></li>
                                <li><span className="tel">T</span> <a href="tel:050714099559">0507-1409-9559</a></li>
                            </ul>
                        </div>
                        
                        <p className="copy_right">JOB OF BROTHERS All rights reserved.</p>
                    </div>
                </div>
            </header>
        );
    }
    
}


export default Header; 