import { Link } from "react-router-dom";
import "./Home.css"
import axios from 'axios';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";


//P5
import { useRef, useState } from "react";
import { useEffect } from "react";

import { Helmet } from 'react-helmet';

//Three.js
import * as THREE from 'three';

//create
function CreateThree() {
    const sceneRef = useRef(null);

    useEffect(() => {
        const scene = new THREE.Scene();
        const canvasWidth = 350; // 캔버스의 가로 크기
        const canvasHeight = 350; // 캔버스의 세로 크기
    
        const aspectRatio = canvasWidth / canvasHeight;
        const pixelRatio = window.devicePixelRatio; // 현재 화면의 픽셀 비율 가져오기
    
        const camera = new THREE.PerspectiveCamera(75, aspectRatio, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(canvasWidth * pixelRatio, canvasHeight * pixelRatio);
        renderer.setPixelRatio(pixelRatio);
        sceneRef.current.appendChild(renderer.domElement);
    
        // 원뿔의 크기와 세그먼트 수 설정
        const coneRadius = 110;
        const coneHeight = 210;
        const numSegments = 60;
    
        const coneGeometry = new THREE.ConeGeometry(coneRadius, coneHeight, numSegments);
        const coneMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff, wireframe: true, transparent: true, opacity: 0.5 });
        const coneMesh = new THREE.Mesh(coneGeometry, coneMaterial);
    
        scene.add(coneMesh);
    
        // 카메라 위치를 설정합니다.
        camera.position.z = 250;
    
        // 애니메이션 루프를 생성합니다.
        function animate() {
          requestAnimationFrame(animate);
    
          // 원뿔을 회전시킵니다. (속도)
          coneMesh.rotation.x += 0.008;
          coneMesh.rotation.y += 0.008 ;
    
          renderer.render(scene, camera);
        }
    
        animate();

        return () => {
        // 컴포넌트가 언마운트되면 Three.js 리소스를 정리합니다.
        renderer.dispose();
        };
    }, []);

    return <div ref={sceneRef} style={{ width : '100%' , height : '100%'}}/>;
}
//design
function DesginThree(){
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvasWidth = 280; // 캔버스의 가로 크기
        const canvasHeight = 280; // 캔버스의 세로 크기

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, canvasWidth / canvasHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, antialias: true });
        renderer.setSize(canvasWidth, canvasHeight);

        const boxSize = 70;
        const material = new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 1, transparent: true, opacity: 0.8 });
        const pixelRatio = window.devicePixelRatio; // 현재 화면의 픽셀 비율 가져오기
        renderer.setPixelRatio(pixelRatio); // 현재 화면의 픽셀 넣기 

        for (let x = -boxSize; x < boxSize; x += 30) {
            for (let y = -boxSize; y < boxSize; y += 30) {
                for (let z = -boxSize; z < boxSize; z += 30) {
                    const geometry = new THREE.BufferGeometry();
                    const positions = new Float32Array([
                        x, y, z,
                        x + 30, y, z,
                        x + 30, y, z,
                        x + 20, y + 20, z,
                        x + 20, y + 20, z,
                        x, y + 20, z,
                        x, y + 20, z,
                        x, y, z
                    ]);
                    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
                    const line = new THREE.LineSegments(geometry, material);
                    scene.add(line);
                }
            }
        }

        camera.position.z = 200;

        const animate = () => {
            requestAnimationFrame(animate);
            scene.rotation.x += 0.008;
            scene.rotation.y += 0.008;
            renderer.render(scene, camera);
        };

        animate();

        return () => {
            scene.remove(...scene.children);
            renderer.dispose();
        };
    }, []);

    return <canvas ref={canvasRef} />;
}
//develop
function DevelopThree(){
    const canvasRef = useRef();

    useEffect(() => {
        let renderer, scene, camera, mesh;

        const init = () => {
        renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current , antialias: true });
        renderer.setSize(390, 400);

        scene = new THREE.Scene();

        camera = new THREE.PerspectiveCamera(75, 390 / 400, 0.1, 1000);
        camera.position.z = 3;

        const pixelRatio = window.devicePixelRatio; // 현재 화면의 픽셀 비율 가져오기
        renderer.setPixelRatio(pixelRatio); //현재 화면의 픽셀 넣기 

        const geometry = new THREE.BufferGeometry();
        const positions = [
            0, -1, 0,
            1, 1, -1,
            -1, 1, -1,

            0, -1, 0,
            1, 1, 1,
            -1, 1, 1,

            0, -1, 0,
            1, 1, -1,
            1, 1, 1,

            0, -1, 0,
            -1, 1, -1,
            -1, 1, 1,

            0, -1, 0,
            1, 1, -1,
            -1, 1, -1,

            0, -1, 0,
            1, 1, -1,
            -1, 1, -1,

            0, -1, 0,
            1, 1, 1,
            -1, 1, 1,

            0, -1, 0,
            1, 1, 1,
            1, 1, -1,

            0, -1, 0,
            -1, 1, -1,
            -1, 1, 1,

            0, -1, 0,
            -1, 1, -1,
            1, 1, -1,
        ];
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));

        const material = new THREE.LineBasicMaterial({ color: 0xffffff });
        mesh = new THREE.LineSegments(geometry, material);
        scene.add(mesh);

        animate();
        };

        const animate = () => {
        requestAnimationFrame(animate);

        mesh.rotation.x += 0.008;
        mesh.rotation.y += 0.008;

        renderer.render(scene, camera);
        };

        init();

        return () => {
        // Cleanup
        renderer.dispose();
        scene.remove(scene.children);
        };
    }, []);

    return <canvas ref={canvasRef} />;
  
}
//branding & marketing
function BrandingThree(){
    const canvasRef = useRef();

    useEffect(() => {
      let renderer, scene, camera, cylinder;
  
      const init = () => {
        renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, antialias: true });
        renderer.setSize(390, 400);
  
        scene = new THREE.Scene();
  
        camera = new THREE.PerspectiveCamera(75, 390 / 400, 0.1, 1000);
        camera.position.z = 2.5;
  
        const pixelRatio = window.devicePixelRatio;
        renderer.setPixelRatio(pixelRatio);
  
        const geometry = new THREE.CylinderGeometry(1, 1, 2, 32);
        const material = new THREE.MeshBasicMaterial({ color: 0xffffff, wireframe: true, transparent: true, opacity: 0.5 });
        cylinder = new THREE.Mesh(geometry, material);
        scene.add(cylinder);
  
        animate();
      };
  
      const animate = () => {
        requestAnimationFrame(animate);
  
        cylinder.rotation.x += 0.008;
        cylinder.rotation.y += 0.008;
  
        renderer.render(scene, camera);
      };
  
      init();
  
      return () => {
        // Cleanup
        renderer.dispose();
        scene.remove(scene.children);
      };
    }, []);
  
    return <canvas ref={canvasRef} />;
}
//지원사업 MVP 기획 및 제작
function MVPThree(){
    const canvasRef = useRef();

    useEffect(() => {
      let renderer, scene, camera, mesh;
  
      const init = () => {
        renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });
        renderer.setSize(400, 500);
        renderer.setClearColor(0x000000);
  
        scene = new THREE.Scene();
  
        camera = new THREE.PerspectiveCamera(75, 390 / 500, 0.1, 1000);
        camera.position.z = 350;
  
        const geometry = new THREE.TorusGeometry(110, 60, 16, 80);
        const material = new THREE.MeshBasicMaterial({ color: 0xffffff, wireframe: true, transparent: true, opacity: 0.4 });
        mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);

        const pixelRatio = window.devicePixelRatio;
        renderer.setPixelRatio(pixelRatio);
  
        animate();
      };
  
      const animate = () => {
        requestAnimationFrame(animate);
  
        mesh.rotation.x += 0.008;
        mesh.rotation.y += 0.008;
  
        renderer.render(scene, camera);
      };
  
      init();
  
      return () => {
        // Cleanup
        renderer.dispose();
        scene.remove(mesh);
      };
    }, []);
  
    return <canvas ref={canvasRef} />;
}
//Contact
function ContactTree(){
    const canvasRef = useRef();

    useEffect(() => {
        let renderer, scene, camera;
        let angle = 0;
        const rotationSpeed = 0.01;
        const radius = 100;
        const height = 200;
        const numLines = 30;
        const angleStep = (2 * Math.PI) / numLines;
        const currCoordinates = [];

        const init = () => {
        const canvas = canvasRef.current;
        renderer = new THREE.WebGL1Renderer({ canvas, antialias: true });
        renderer.setSize(400, 400);

        scene = new THREE.Scene();

        camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
        camera.position.z = 350;

        for (let i = 0; i < numLines; i++) {
            const currAngle = i * angleStep;
            currCoordinates.push({
            x: radius * Math.cos(currAngle),
            y: radius * Math.sin(currAngle),
            });
        }

        renderer.setClearColor(0x000000);

        animate();
        };

        const animate = () => {
        requestAnimationFrame(animate);

        angle += rotationSpeed;

        scene.rotation.x = angle;
        scene.rotation.y = angle;

        renderer.render(scene, camera);
        };

        const createLine = (x1, y1, z1, x2, y2, z2) => {
        // const material = new THREE.LineBasicMaterial({ color: 0xffffff, wireframe: true, transparent: true, opacity: 0.7 });
        const material = new THREE.LineBasicMaterial({ color: 0xffffff, linewidth: 1, transparent: true, opacity: 0.7 });
        const geometry = new THREE.BufferGeometry();
        const vertices = [];
        vertices.push(x1, y1, z1);
        vertices.push(x2, y2, z2);
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        const line = new THREE.Line(geometry, material);
        scene.add(line);
        };

        init();

        for (let i = 0; i < numLines; i++) {
        const currCoord = currCoordinates[i];
        const nextCoord = currCoordinates[(i + 1) % numLines];

        createLine(currCoord.x, currCoord.y, -height / 2, nextCoord.x, nextCoord.y, -height / 2);
        createLine(currCoord.x, currCoord.y, height / 2, nextCoord.x, nextCoord.y, height / 2);
        createLine(currCoord.x, currCoord.y, -height / 2, currCoord.x, currCoord.y, height / 2);
        }

        return () => {
        // Cleanup
        renderer.dispose();
        scene.remove(...scene.children);
        };
    }, []);

    return <canvas ref={canvasRef} />;
}

function Home ({contactModal}) {

    const contactModalHandler = () => { contactModal(true) } //contact 모달창 보이기.
    const [activeSlide, setActiveSlide] = useState(0);
    const handleSlideChange = (swiper) => { setActiveSlide(swiper.realIndex); };

    const [project, setProject] = useState(); 
    const [column, setColumn] = useState(); 

    useEffect(() => {
        const getProjectData = async() => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/project/main`);
                if (response.status === 200) {
                    setProject(response.data.result[0]);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                alert(error);
            }
        }
        getProjectData();
    }, [])

    useEffect(() => {
        const getColumnData = async() => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/column/main`);
                if (response.status === 200) {
                    setColumn(response.data.result[0]);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                alert(error);
            }
        }
        getColumnData();
        console.log("column data 담기");
        console.log(column);
    }, [])

    return(
        <>  
            <Helmet>
                {/* SEO 코드를 넣는 부분*/}
                <title>잡오브브라더스 : JOB OF BROTHERS</title>
                <meta name="description" content="반응형 홈페이지, 웹서비스, 지원사업 MVP 책임 제작, 청창사 수행 기업, 믿음에 책임감으로 대답해드립니다." />
            </Helmet>
            <section className="main">
                <article className="BannerArti ani">
                    <div className="inner">
                        <div className="contentBox">
                            <h1>
                                <span data-text="Job Of Brothers">Job Of Brothers </span>
                                <span data-text="Digital Agency">Digital Agency </span>
                                <span data-text="For Your Brand">For Your Brand</span>
                            </h1>
                            <div className="hashTagBox">
                                <ul className="hashtagList">
                                    <li><div className="tag yellow">#웹개발</div></li>
                                    <li><div className="tag mint">#디자인</div></li>
                                    <li><div className="tag blue">#브랜딩&마케팅</div></li>
                                </ul>
                            </div>
                        </div>
                        <div className="logoBox">
                            <div className="jobWrap">
                                <div className="circle item"></div>
                                <div className="J item"></div>
                                <div className="B B1 item"></div>
                                <div className="B B2 item"></div> 
                            </div>
                        </div>
                    </div>
                </article>            

                <article className="serviceArti ani">
                    <div className="inner">
                        <h2><span>Service</span></h2>
                        <Swiper 
                            className="mySwiper serviceSlide"
                            effect={"fade"}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            modules={[EffectFade , Autoplay]}
                            onSlideChange={handleSlideChange}
                            loop={true}
                        >
                            <SwiperSlide>
                                <ul className="item">
                                    <li className="contentBox">
                                        <h3><span>Create</span></h3>
                                        <ul className="content">
                                            <li>
                                                <div className="slideNumber">
                                                    <span className="nb orange">01</span>
                                                    <span className="line"><b></b></span>
                                                    <span className="nb">05</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text">
                                                    모든 기획을 클라이언트와 함께. <br/>
                                                    클라이언트의 관점을 이해하고자 <br/>
                                                    끊임없이 소통합니다.
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="number">
                                            01
                                        </div>
                                    </li>
                                    <li className="imgBox">
                                        {(activeSlide === 0) && <CreateThree />}
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="item">
                                    <li className="contentBox">
                                        <h3><span>Design</span></h3>
                                        <ul className="content">
                                            <li>
                                                <div className="slideNumber">
                                                    <span className="nb orange">02</span>
                                                    <span className="line"><b></b></span>
                                                    <span className="nb">05</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text">
                                                    PC, Tablet, Mobile 모든 디바이스에서 <br/>
                                                    메시지가 전달되어야 하니까. <br/>
                                                    반응형으로 디자인하여 디바이스가 <br/>
                                                    달라도 일관된 경험을 제공합니다.
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="number">
                                            02
                                        </div>
                                    </li>
                                    <li className="imgBox">
                                        {(activeSlide === 1) && <DesginThree />}
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="item">
                                    <li className="contentBox">
                                        <h3><span>Develop</span></h3>
                                        <ul className="content">
                                            <li>
                                                <div className="slideNumber">
                                                    <span className="nb orange">03</span>
                                                    <span className="line"><b></b></span>
                                                    <span className="nb">05</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text">
                                                    웹에서 보고, 경험한 모든 효과와 <br/> 
                                                    기능을 구현합니다. <br/> 
                                                    전문 개발팀이 Publishing, <br/> 
                                                    Front-end & Back-end 개발과 테스트 <br/> 
                                                    런칭 후 사후지원까지 책임을 다합니다.
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="number">
                                            03
                                        </div>
                                    </li>
                                    <li className="imgBox">
                                        {(activeSlide === 2) && <DevelopThree /> }
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="item">
                                    <li className="contentBox">
                                        <h3><span>Branding& <br/>Maketing</span></h3>
                                        <ul className="content">
                                            <li>
                                                <div className="slideNumber">
                                                    <span className="nb orange">04</span>
                                                    <span className="line"><b></b></span>
                                                    <span className="nb">05</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text">
                                                CI/BI, 상세페이지, 리플렛, 패키지부터 <br/>
                                                제품 촬영까지 다년간의 마케팅 노하우와  <br/>
                                                고퀄리티 디자인 퍼포먼스로 <br/>
                                                브랜드에 숨결을 불어넣습니다.
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="number">
                                            04
                                        </div>
                                    </li>
                                    <li className="imgBox">
                                        {(activeSlide === 3) && <BrandingThree />}
                                        
                                    </li>
                                </ul>
                            </SwiperSlide>
                            <SwiperSlide>
                                <ul className="item">
                                    <li className="contentBox">
                                        <h3><span>지원사업 MVP <br/>기획 및 제작</span></h3>
                                        <ul className="content">
                                            <li>
                                                <div className="slideNumber">
                                                    <span className="nb orange">05</span>
                                                    <span className="line"><b></b></span>
                                                    <span className="nb">05</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="text">
                                                    중소벤처기업부 창업지원사업 (창업 패키지) <br/>4건 선정 
                                                    4건 선정 정해진 예산, 복잡한 절차,  <br/>
                                                    수많은 필요 기능 구현을 대표님과 함께합니다.
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="number">
                                            05
                                        </div>
                                    </li>
                                    <li className="imgBox">
                                        {(activeSlide === 4) && <MVPThree />}
                                    </li>
                                </ul>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </article>

                <article className="projectArti ani">
                    <div className="inner">
                        <h2><span>Project</span></h2>
                    </div>
                    <ul className="list">
                         { (project) &&  
                            project.map((item) => (
                                <li  key={item.id}>
                                    <Link to={`./project/view/${item.id}`}>
                                        <div className="item">
                                            <div className="imgBox" style={{ backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}/uploads/project/${item.projectFile1}")` }}></div>
                                            <div className="hoverBox">
                                                <div className="content">
                                                    <h3>
                                                        {item.projectName}
                                                    </h3>
                                                    <img src={`${process.env.PUBLIC_URL}/images/main_portfolio_arti.svg`} alt="arrow" className="arrow"/>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            ))
                        }
                        
                    </ul>
                    <div className="moreBtnWrap">
                        <Link to="/project">
                            <div className="moreBtn">
                                <svg width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow" > 
                                    <path d="M16.25 19.7322V0L9.75 0V19.7322H0L13 33L26 19.7322H16.25Z" fill=""/>
                                </svg>
                                <span className="txt">LOAD MORE</span>
                            </div>
                        </Link>
                    </div>
                </article>

                <article className="columnArti ani">
                    <div className="inner">
                        <h2 className="right"><span>Column</span></h2>

                        <div className="listWrap">
                            <div className="AllBoreBtn">
                                <Link to="/column">전체보기</Link>
                            </div>
                            <ul className="listBox">
                                { (column) &&  
                                    column.map((item) => (
                                        <li className="ani" key={item.id}>
                                            <Link to={`./column/view/${item.id}`}>
                                                <div className="item">
                                                    <div className="imgBox" style={{ backgroundImage: `url("${process.env.REACT_APP_SERVER_URL}/uploads/column/${item.columnFile1}")` }}></div>
                                                    <div className="category">{item.columnCategory}</div>
                                                    <div className="title">{item.columnTitle}</div>
                                                    <div className="moreBtn">
                                                    <span>Read more</span> 
                                                    <svg width="53" height="26" viewBox="0 0 53 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="arrow">
                                                        <path d="M39.7831 9.75H0V16.25H39.7831V26L53 13L39.7831 0V9.75Z" fill=""/>
                                                    </svg>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </article>  

                <article className="partnerArti ani">
                    <div className="inner">
                        <h2><span>A partner company</span></h2>
                    </div>
                    <div className="logoWrap">
                        <div className="marquee_group">
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo01.png`} alt="숙명여자대학교"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo02.png`} alt="퀸잇"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo03.png`} alt="하루엔소쿠"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo04.png`} alt="월미당"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo05.png`} alt="자블 Jabble."/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo06.png`} alt="공인나눔번역센터"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo07.png`} alt="세이브더칠드런"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo08.png`} alt="한국코치협회"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo09.png`} alt="MBN"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo10.png`} alt="Gnetworks"/></div>
                        </div>
                        <div className="marquee_group">
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo01.png`} alt="숙명여자대학교"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo02.png`} alt="퀸잇"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo03.png`} alt="하루엔소쿠"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo04.png`} alt="월미당"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo05.png`} alt="자블 Jabble."/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo06.png`} alt="공인나눔번역센터"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo07.png`} alt="세이브더칠드런"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo08.png`} alt="한국코치협회"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo09.png`} alt="MBN"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo10.png`} alt="Gnetworks"/></div>
                        </div>
                    </div>
                    <div className="logoWrap marquee--reverse">
                        <div className="marquee_group">
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo11.png`} alt="재단법인 여민재단"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo12.png`} alt="홍삼만"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo13.png`} alt="애니파킹"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo14.png`} alt="성결대학교"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo15.png`} alt="문화프레임빅테이터연구소"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo16.png`} alt="미꿈사"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo17.png`} alt="유니타"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo18.png`} alt="중앙대학교"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo19.png`} alt="오프닝즈"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo20.png`} alt="법무법인 온조"/></div>
                        </div>
                        <div className="marquee_group">
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo11.png`} alt="재단법인 여민재단"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo12.png`} alt="홍삼만"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo13.png`} alt="애니파킹"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo14.png`} alt="성결대학교"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo15.png`} alt="문화프레임빅테이터연구소"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo16.png`} alt="미꿈사"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo17.png`} alt="유니타"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo18.png`} alt="중앙대학교"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo19.png`} alt="오프닝즈"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo20.png`} alt="법무법인 온조"/></div>
                        </div>
                    </div>
                    <div className="logoWrap">
                        <div className="marquee_group">
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo21.png`} alt="APGREEN"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo22.png`} alt="ECOPACK-IT"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo23.png`} alt="Outdo"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo24.png`} alt="MINERVA"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo25.png`} alt="DR.STRENGTH"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo26.png`} alt="서울신용보증재단"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo27.png`} alt="매일경제"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo28.png`} alt="서울특별시"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo29.png`} alt="SUPERBEBN"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo30.png`} alt="법무법인 한미"/></div>
                        </div>
                        <div className="marquee_group">
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo21.png`} alt="APGREEN"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo22.png`} alt="ECOPACK-IT"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo23.png`} alt="Outdo"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo24.png`} alt="MINERVA"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo25.png`} alt="DR.STRENGTH"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo26.png`} alt="서울신용보증재단"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo27.png`} alt="매일경제"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo28.png`} alt="서울특별시"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo29.png`} alt="SUPERBEBN"/></div>
                            <div className="logoBox"><img src={`${process.env.PUBLIC_URL}/images/main_logo/main_company_logo30.png`} alt="법무법인 한미"/></div>
                        </div>
                    </div>
                    
                </article>

                <article className="contactArti ani">
                    <div className="inner">
                        <div className="pillar">
                            <ContactTree />
                        </div>
                        <div className="contentBox">
                            <h3>
                                Create Your Service<br/>
                                with <span>BROTHERS</span>
                            </h3>

                            <div className="btnWrap">
                                <div className="btn" onClick={contactModalHandler} >
                                    <span>Contact</span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </article>
                
            </section>
        </>
    );
}

export default Home; 