//휴대폰전화 유효성검사
const phone_check =(value) => {
    var regPhone = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
    if(regPhone.test(value)==false){
        return false;
    }
    return true;
}


module.exports = { phone_check };