import {useState, useEffect} from 'react';
import styled from 'styled-components';
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link, useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {formatDate} from '../middlewares/formatDate';

function Contact({setPrivacyPopup, privacyPopup}) {
    console.log("contactupdate start");
    const navigate = useNavigate();
    const {id} = useParams();
    console.log(id);
    const [contactData, setContactData] = useState(null);
    //input 정의
    const [inputValues, setInputValues] = useState({
        contactName: '',
        contactCompany: '',
        contactCategory: '',
        contactPhone: '',
        contactEmail: '',
        contactUrl: '',
        contactRoute: '',
        contactPage: '',
        contactContent: '',
        contactReference: '',
        contactSchedule: ''
    });

    //input 핸들러
    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value
        }));
    };

    //date 핸들러
    const handleDateChange = (name, value) => {
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value
        }));
    };

    useEffect(() => {
        const fetchContactData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/contact/${id}`);
                console.log(response);

                if (response.status === 200) {
                    console.log(response);
                    setContactData(response.data.result[0]);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }
        };

        fetchContactData();
    }, [id]);

    //Submit
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('contactName', inputValues.contactName);
            formData.append('contactCompany', inputValues.contactCompany);
            formData.append('contactCategory', inputValues.contactCategory);
            formData.append('contactPhone', inputValues.contactPhone);
            formData.append('contactEmail', inputValues.contactEmail);
            formData.append('contactUrl', inputValues.contactUrl);
            formData.append('contactRoute', inputValues.contactRoute);
            formData.append('contactPage', inputValues.contactPage);
            formData.append('contactContent', inputValues.contactContent);
            formData.append('contactReference', inputValues.contactReference);
            formData.append('contactSchedule', inputValues.contactSchedule);
            formData.append('id', id);

            // FormData의 value 확인
            for (let value of formData.values()) {
                console.log(value);
            }

            try {
                console.log("check");
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/contact/update/${id}`,formData);
                if (response.status === 200) {
                    const redirectURL = `/contact/view/${id}`; // 리디렉션할 URL 생성
                    console.log(response);
                    navigate(redirectURL);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }

        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        if (contactData) {
            const {
                ...rest
            } = contactData;
            setInputValues(rest);
        }
    }, [contactData]);

    if (!contactData) {
        return <div>Loading...</div>;
    }

    const {
        contactName,
        contactCompany,
        contactCategory,
        contactPhone,
        contactEmail,
        contactUrl,
        contactRoute,
        contactPage,
        contactContent,
        contactReference
    } = contactData;
    return (
        <div className="rootConatiner">
            <BannerArti bgImage={`${process.env.PUBLIC_URL}/images/contact_banner_bg.png`}>
                <div className='inner'>
                    <div className='textBox'>
                        <p className="title">CONTACT</p>
                        <p className='content'>
                            친절하고 자세하게 안내드리겠습니다
                        </p>
                    </div>
                </div>
            </BannerArti>
            <ContactArti>
                <div className="inner">
                    <ul className="contentContainer">
                        <li>
                            <FormBox>
                                <form onSubmit={onSubmitHandler}>
                                    <div className="titleBox">
                                        <img src={`${process.env.PUBLIC_URL}/images/jb_logo.png`} alt="잡오브브라더스 로고"/>
                                        <p>프로젝트 신청하기</p>
                                    </div>

                                    <div className="inputWrap">
                                        <div className="title">01. 기본 정보를 입력해주세요.</div>
                                        <ul className="iptList">
                                            <li>
                                                <label>기관 혹은 기업명</label>
                                                <div className="iptWrap">
                                                    <input
                                                        type="text"
                                                        name="contactCompany"
                                                        onChange={handleInputChange}
                                                        value={inputValues.contactCompany}
                                                        maxLength={255}
                                                        required="required"
                                                        placeholder=""
                                                        className="ipt"/>
                                                </div>
                                            </li>

                                            <li>
                                                <label>담당자 성함</label>
                                                <div className="iptWrap">
                                                    <input
                                                        type="text"
                                                        name="contactName"
                                                        value={inputValues.contactName}
                                                        onChange={handleInputChange}
                                                        maxLength={255}
                                                        required="required"
                                                        placeholder=""
                                                        className="ipt"/>
                                                </div>
                                            </li>
                                            <li>
                                                <label>연락처</label>
                                                <div className="iptWrap">
                                                    <input
                                                        type="text"
                                                        name="contactPhone"
                                                        value={inputValues.contactPhone}
                                                        onChange={handleInputChange}
                                                        maxLength={255}
                                                        required="required"
                                                        placeholder=""
                                                        className="ipt"/>
                                                </div>
                                            </li>
                                            <li>
                                                <label>이메일</label>
                                                <div className="iptWrap">
                                                    <input
                                                        type="email"
                                                        name="contactEmail"
                                                        value={inputValues.contactEmail}
                                                        onChange={handleInputChange}
                                                        maxLength={255}
                                                        required="required"
                                                        placeholder=""
                                                        className="ipt"/>
                                                </div>
                                            </li>
                                            <li>
                                                <label>홈페이지 URL
                                                    <span className="gray">(선택)</span>
                                                </label>
                                                <div className="iptWrap">
                                                    <input
                                                        type="text"
                                                        name="contactUrl"
                                                        onChange={handleInputChange}
                                                        value={inputValues.contactUrl}
                                                        maxLength={255}
                                                        required="required"
                                                        placeholder=""
                                                        className="ipt"/>
                                                </div>
                                            </li>
                                            <li>
                                                <label>홈페이지 알게된 경로</label>
                                                <div className="iptWrap">
                                                    <select
                                                        name="contactRoute"
                                                        onChange={handleInputChange}
                                                        value={inputValues.contactRoute}>
                                                        <option value="">경로를 설정해주세요.</option>
                                                        <option value="네이버 검색">네이버 검색</option>
                                                        <option value="구글 검색">구글 검색</option>
                                                        <option value="네이버 블로그">네이버 블로그</option>
                                                        <option value="지인추천">지인추천</option>
                                                    </select>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="inputWrap">
                                        <div className="title">02. 작업해야 할 프로젝트를 알려주세요</div>
                                        <ul className="iptList">
                                            <li>
                                                <label>대략적인 페이지 수
                                                </label>
                                                <div className="iptWrap">
                                                    <input
                                                        type="text"
                                                        name="contactPage"
                                                        value={inputValues.contactPage}
                                                        onChange={handleInputChange}
                                                        maxLength={255}
                                                        required="required"
                                                        placeholder=""
                                                        className="ipt"/>
                                                </div>
                                            </li>
                                            <li>
                                                <label>일정</label>
                                                <div className="iptWrap">
                                                    {/* <input type="text" name="date" required placeholder="일정을 선택해주세요"/>  */}
                                                    <ReactDatePicker
                                                        name="contactSchedule"
                                                        placeholderText="YYYY-MM-DD"
                                                        value={formatDate(inputValues.contactSchedule)}
                                                        dateFormat="yyyy-MM-dd"
                                                        onChange={(value) => handleDateChange('contactSchedule', value)}
                                                        className="ipt"/>
                                                </div>
                                            </li>
                                            <li>
                                                <label>유형</label>
                                                <div className="iptWrap">
                                                    <select
                                                        name="contactCategory"
                                                        onChange={handleInputChange}
                                                        value={inputValues.contactCategory}>
                                                        <option value="">홈페이지 유형을 선택해주세요</option>
                                                        <option value="1">유형1</option>
                                                        <option value="2">유형2</option>
                                                        <option value="3">유형3</option>
                                                    </select>
                                                </div>
                                            </li>
                                            <li>
                                                <label>참고 레퍼런스</label>
                                                <div className="iptWrap">
                                                    <input
                                                        type="text"
                                                        name="contactReference"
                                                        value={inputValues.contactReference}
                                                        onChange={handleInputChange}
                                                        maxLength={255}
                                                        required="required"
                                                        placeholder=""
                                                        className="ipt"/>
                                                </div>
                                            </li>
                                            <li className="full">
                                                <label>자세한 의뢰 내용을 입력해주세요.</label>
                                                <div className="iptWrap">
                                                    <textarea
                                                        name="contactContent"
                                                        value={inputValues.contactContent}
                                                        onChange={handleInputChange}></textarea>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <div className="checkboxWrap">
                                    <input type="checkbox" id="agree" />
                                    <label htmlFor="agree">개인정보 처리방침에 동의합니다. <span className="black"  onClick={privacyPopupHandler}>(전문보기)</span></label>
                                </div> */
                                    }
                                    <button type="submit" className="submitBtn">상담신청</button>
                                </form>
                            </FormBox>
                        </li>
                        <li>
                            <InfoBox>
                                <ul className="infoList">
                                    <li>
                                        <p className="title">상담원전화</p>
                                        <p className="tel">
                                            <a href="tel:0507-1409-9559" rel='noreferrer'>
                                                0507-1409-9559
                                            </a>
                                        </p>
                                    </li>
                                    <li>
                                        <p className="title">카카오톡 상담</p>
                                        <div className="content">
                                            <a href="https://pf.kakao.com/_RCxoxib" target="_blank" rel='noreferrer'>
                                                <span className="btn">상담하기</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li>
                                        <p className="title">방문상담 안내</p>
                                        <div className="content">
                                            <Link to="/location">
                                                <span className="btn">오시는 길</span>
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </InfoBox>
                        </li>
                    </ul>
                </div>
            </ContactArti>
        </div>
    )
}

const BannerArti = styled.article `
    padding: 200px 0 100px 0; background-size: cover; background-repeat: no-repeat; background-position: center;
    background-image : ${ (
    props
) => props.bgImage
    ? `url(${props.bgImage})`
    : `url(${process.env.PUBLIC_URL}/images/example_img.png)`};
    color:#FFF; 

    .title{ font-size:56px; font-weight:700; }
    .content{ font-size:18px; margin-top:20px; }

    @media screen and (max-width: 1250px) {
        .title{ font-size:40px; }
        .content{ font-size:16px; }
    }
    @media screen and (max-width: 650px) {
        .title{ font-size:35px; }
        .content{ font-size:14px; margin-top:10px; }
    }
`;
const ContactArti = styled.article `
    padding: 100px 0;
    .contentContainer{ 
        display:flex; flex-wrap:wrap; width:calc(100% + 20px); margin-left:-10px;
        >li{ padding:10px; }
        >li:nth-child(1){ width:calc(100% - 350px);  }  
        >li:nth-child(2){ width:350px }
    } 
    @media screen and (max-width: 1250px) {
        .contentContainer{ 
            >li:nth-child(1){ width:calc(100% - 250px);  }  
            >li:nth-child(2){ width:250px }
        } 
    }
    @media screen and (max-width: 830px) {
        padding:50px 0;
        .contentContainer{ 
            >li:nth-child(1){ width:100%; order:1; }
            >li:nth-child(2){ width:100%; order:0; }
        }
    }
`;
const FormBox = styled.div `
    .titleBox{ 
        display:flex; align-items:center; padding-bottom:10px; border-bottom:4px solid #222; 
        font-size: 26px; font-weight: 700;
        img{ margin-right:10px; }
    }
    .inputWrap{ 
        margin-top:20px; padding:20px 0; 
        + .inputWrap{ border-top:1px solid #d9d9d9; }
        
        .title{ font-size:20px; }
        .iptList{ 
            margin-top:10px; display:flex; flex-wrap:wrap; width:calc(100% + 20px); margin-left: -10px;
            > li{ 
                padding:10px; width:calc(100% / 2); 
                &.full{ width:100%; }
                .iptWrap{ 
                    margin-top:10px; 
                    .ipt{ border:1px solid #d9d9d9; padding:10px; width:100%; font-size:16px;  }
                    select{ border:1px solid #d9d9d9; padding:10px; width:100%; font-size:16px;  }
                    textarea{ padding:10px; border:1px solid #d9d9d9; width:100%; height:200px; resize:none; font-size:16px;  }
                }
            }
        }
    }
    .checkboxWrap{
        input[type="checkbox"]{ 
            display:none; 
            + label{ 
                display:flex; align-items: center; color: #7f7f7f;
                &::before{ content:''; display:block; width:20px; height:20px; border:1px solid #d9d9d9; margin-right:10px; }
                span{
                    &.black{ margin-left:10px; color:#222; cursor:pointer; }
                }
            }
            &:checked{
                + label{
                    &::before{ 
                        background-image: ${ `url(${process.env.PUBLIC_URL}/images/catact_check_icon.png)`};
                        background-size:cover; background-repeat:no-repeat; background-position:center; 
                    }
                }
            }
        }
    }

    .submitBtn{ 
        margin:50px auto 0 auto; background-color:var(--main-color); color:#fff; padding:15px; width:100%; max-width:300px; border-radius:60px;  
        border: none; display:block; font-size: 18px;
    }

    @media screen and (max-width: 1250px) {
        .titleBox{ 
            font-size:20px; 
            img{ width:35px; }
        }
        .inputWrap{ 
            .title{ font-size:16px; }
        }
    }
    @media screen and (max-width: 830px) {
        
    }
    @media screen and (max-width: 650px) {
        .inputWrap{ 
            .iptList{
                >li{ width:100%; }
            }
        }
    }

`;
const InfoBox = styled.div `
    .infoList{ 
        >li{ 
            padding:40px 10px; border-top:2px solid #D9D9D9; 
            &:first-child{ padding-top:0; border-top:none; }
        }
        .title{ font-weight:700; font-size:24px; margin-bottom:20px; }
        .tel{ font-size:36px; font-weight:700; }
        .btn{ 
            text-align:center; padding:10px; font-size:18px; color: #7f7f7f; display:block; 
            border: 2px solid #D9D9D9; border-radius:60px;
        }
    }
    @media screen and (max-width: 1250px) {
        .infoList{  
            >li{ padding:20px 10px; }
            .title{ font-size:18px; }
            .tel{ font-size:24px; }
            .btn{ font-size:16px; }
        }
    }
    @media screen and (max-width: 830px) {
        .infoList{ 
            text-align:center; display:flex; flex-wrap: wrap;
            >li{ 
                &:nth-child(1){ width:100%; }
                &:nth-child(2){ width:50%; }
                &:nth-child(3){ width:50%; }
            }
            .title{ margin-bottom:10px; }
            .tel{ font-size:30px; }
        }
        
    }
    @media screen and (max-width: 650px) {
        .infoList{
            .title{ font-size:16px; }
            .tel{ font-size:24px; }
            .btn{ font-size:14px; }
        }
    }
`;

export default Contact;