
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
const AdminMenu = ({boardName , type="",id="" , listLink=""}) => {
    const navigate = useNavigate();
    const [moreMenu, setMoreMenu] = useState(false);
    const [admin, setAdmin] = useState('');

    useEffect(() => {
        const storedData = localStorage.getItem('user');
        if(storedData==null){
            return;
        }
        // const jsonStr = storedData.substring(
        //     storedData.indexOf('"userId":'),
        //     storedData.length - 1
        // );
        // const dataArr = jsonStr.split(',');
        // setAdmin(
        //     dataArr[1].substring(dataArr[1].indexOf('"userAdmin"') + 12, dataArr[1].length)
        // );
        const jsonObject = JSON.parse(storedData);
        const userAdminValue = jsonObject.userAdmin;
        setAdmin(userAdminValue);
    }, []);

    const moreMenuRef = useRef();  

    const moreHandler = () => {
        setMoreMenu(!moreMenu);
    }

    const moreBtnOutsideClick = (event) => {
        if( moreMenu && !moreMenuRef.current.contains(event.target) ){
            setMoreMenu(!moreMenu);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', moreBtnOutsideClick);
        return () => {
            document.removeEventListener("mousedown", moreBtnOutsideClick);
        }
    },[])




    const handleDelete = async () => {
        const confirmDelete = window.confirm('정말로 선택된 항목을 삭제하시겠습니까?');
        if (confirmDelete) {
          try {
            const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/${boardName}/delete/${id}`);
            console.log(response);

            if (response.status === 200) {
                navigate(`/${boardName}`);
            } else {
              throw new Error('서버 응답 오류');
            }
          } catch (error) {
            console.log(error);
            alert(error);
          }
        }
      };





      
      
    return (
        <>
            <AdminMeni>
                <ul className="admin_list">
                    {(type === "list") ? 
                        <>
                         {(admin==1)?
                            <Link to={`/${boardName}/write`} className="write_icon item">글쓰기</Link> 
                            :
                            null
                        }
                            {/* <li className="more_icon item" onClick={moreHandler}>
                                편집
                                <div className={`search_box more_box ${(moreMenu) ? 'active': null }`} ref={moreMenuRef} >
                                    <button >선택삭제</button>
                                    <button>선택복사</button>
                                </div>
                            </li> */}
                        </>
                        :
                        <>
                            <Link to={(listLink) ? listLink : `/${boardName}`} className="list_icon item">목록</Link>
                            {/* <Link to={`/${boardName}`} className="reple_icon item">답변</Link> */}
                            {(admin==1)?
                            <>
                            <li className="more_icon item" onClick={moreHandler}>
                                    편집
                                    <div className={`search_box more_box ${(moreMenu) ? 'active' : null}`} ref={moreMenuRef}>
                                        <Link to={`/${boardName}/update/${id}`}><button>수정</button></Link>
                                        {/* <Link to={`/${boardName}/write/${id}`}><button>수정</button></Link> */}
                                        <button onClick={handleDelete}>삭제</button>
                                    </div>
                            </li>
                            <Link to={`/${boardName}/write`} className="write_icon item">글쓰기</Link></>
                            :
                            null
                            }
                        </>
                    }
                    
                </ul>
            </AdminMeni>
        </>
    );
}

const AdminMeni = styled.div`
    .admin_list{
        display:flex; justify-content: flex-end; margin-bottom:20px;
        >.item{
            font-size:14px;
            margin-right:5px; padding:5px 5px 5px 29px; display:flex; align-items: center; border-radius: 4px; font-size:14px;  
            background-repeat: no-repeat; background-position: left 5px center; cursor: pointer; background-color:#fff; 
            a{ font-size: inherit; }
            &:nth-child(2n){
                background-color:#f2f2f2; background-repeat: no-repeat; background-position: left 5px center; 
            }
            &.write_icon{ background-image: url(${process.env.PUBLIC_URL}/images/board_img/write_icon.png); }
            &.admin_icon{ background-image: url(${process.env.PUBLIC_URL}/images/board_img/admin_icon.png); }
        }

        .more_icon{background-image:url(${process.env.PUBLIC_URL}/images/board_img/comment_menu.png);  position:relative;}
        .reple_icon{background-image:url(${process.env.PUBLIC_URL}/images/board_img/view_reple_icon.png); position:relative; background-color:#fff; }
        .list_icon{background-image:url(${process.env.PUBLIC_URL}/images/board_img/list_icon.png); position:relative;}
        .more_box{
            position:absolute; background:#fff; padding:10px; border: 1px solid #E6E6E6; box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.05);
            z-index: 5; top:40px; right:0; display:none; 
            &.active{display:block;}
            &::before{
                content: "";
                position: absolute;
                top: -8px;
                right: 13px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 6px 8px 6px;
                border-color: transparent transparent #E6E6E6 transparent;
            }
            &::after{
                content: "";
                position: absolute;
                top: -6px;
                right: 13px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 6px 8px 6px;
                border-color: transparent transparent #fff transparent;
            }
        }

        li{
            &.more_icon{
                .more_box{
                    width:90px; font-size:14px; padding:0;
                    button{
                        background:none; border:none; text-align: center; width:100%; padding:10px; border-bottom:1px solid #e6e6e6; font-size:12px; 
                        cursor: pointer;
                        .button{ 
                            display:block; background:none; border:none; text-align: center; width:100%; padding:10px; border-bottom:1px solid #e6e6e6; font-size:12px; 
                            &:last-child{border-bottom:none; }
                            > a{ font-size: inherit; }
                        }

                    }

                }

            }
        }
        
    }
`;


AdminMenu.propTypes = {
    boardName: PropTypes.string.isRequired, //필수 props 지정
};


export default AdminMenu ;
