import { useState, useEffect } from "react";
/* DatePicker */
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate } from '../middlewares/formatDate';

/* Axios */
import axios from 'axios';
/* uuid 랜덤 자동생성 */

const ProjectWrite = () => {

    
    //데이터를 불러옴
    const navigate = useNavigate();
    const { id } = useParams(); 
    const [projectData, setProjectData] = useState(null);
    const [delProjectFiles, setDelProjectFiles] = useState([false, false, false, false, false]);
    //input 정의
    const [inputValues, setInputValues] = useState({
        projectName: '',
        projectCompany: '',
        projectCategory: '',
        projectCategory2: '',
        projectIntro: '',
        projectStart: '',
        projectEnd: '',
        projectUrl: '',
        projectColor1: '#000000',
        projectColor2: '#000000',
        projectColor3: '#000000',
        projectColorM: '#000000',
        projectFile1: null,
        projectFile2: null,
        projectFile3: null,
        projectFile4: null,
        projectFile5: null,
        projectOrder: '',
    });


    //input 핸들러
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
        }));
    };
    //file 핸들러
    const handleFileChange = (event) => {
        const { name, files } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: files[0],
        }));
        console.log(inputValues)
    };

    //date 핸들러
    const handleDateChange = (name, value) => {
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [name]: value,
        }));
    };

    // checkbox  핸들러
    const handleCheckboxChange = (e, fileNum) => {
        const checked = e.target.checked;
        setDelProjectFiles((prevState) => {
            const newState = [...prevState];
            newState[fileNum - 1] = checked;
            return newState;
        });
    };


    useEffect(() => {
        const fetchProjectData = async () => {
            try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/project/${id}`);

            if (response.status === 200) {
                console.log(response);
                setProjectData(response.data.result[0]);
            } else {
                throw new Error('서버 응답 오류');
            }
            } catch (error) {
            console.log(error);
            alert(error);
            }
        };

        fetchProjectData();
    }, [id]);

    //Submit
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('projectName', inputValues.projectName);
            formData.append('projectCompany', inputValues.projectCompany);
            formData.append('projectIntro', inputValues.projectIntro);
            formData.append('projectCategory', inputValues.projectCategory);
            formData.append('projectUrl', inputValues.projectUrl);
            formData.append('projectColor1', inputValues.projectColor1);
            formData.append('projectColor2', inputValues.projectColor2);
            formData.append('projectColor3', inputValues.projectColor3);
            formData.append('projectColorM', inputValues.projectColorM);
            formData.append('projectFile1', inputValues.projectFile1);
            formData.append('projectFile2', inputValues.projectFile2);
            formData.append('projectFile3', inputValues.projectFile3);
            formData.append('projectFile4', inputValues.projectFile4);
            formData.append('projectFile5', inputValues.projectFile5);
            // formData.append('projectStart', inputValues.projectStart);
            // formData.append('projectEnd', inputValues.projectEnd);
            formData.append('projectOrder', inputValues.projectOrder);
            formData.append('id', id);
            const checkboxes = document.querySelectorAll('input[name="category"]:checked');
            const selectedCategories = Array.from(checkboxes).map(checkbox => checkbox.value).join('|');
            if(selectedCategories==''){
                alert('카테고리를 선택해주세요.');
                return false;
            }
            formData.append('projectCategory2', selectedCategories);
            // 삭제할 파일 정보를 formData에 추가
            for (let i = 0; i < delProjectFiles.length; i++) {
                if (delProjectFiles[i]) {
                formData.append(`delProjectFile${i + 1}`, true);
                }
            }

            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/project/update/${id}`, formData);
                if (response.status === 200) {
                    const redirectURL = `/project/view/${id}`; // 리디렉션할 URL 생성
                    navigate(redirectURL);
                } else {
                  throw new Error('서버 응답 오류');
                }
              } catch (error) {
                console.log(error);
                alert(error);
              }


        } catch (error) {
            console.error(error);
        }
    };
    
    useEffect(() => {
        if (projectData) {
          const { projectFile1, projectFile2, projectFile3, projectFile4, projectFile5, ...rest } = projectData;
          setInputValues(rest);
        }
    }, [projectData]);

    if (!projectData) {
        return <div>Loading...</div>;
    }

    const { projectName, projectCompany, projectIntro, projectCategory, projectStart, projectEnd, projectUrl, projectColor1, projectColor2, projectColor3, projectColorM, projectFile1, projectFile2, projectFile3, projectFile4, projectFile5, projectOrder } = projectData;
    const handleCheckChange = (event) => {
        const valueToAdd = event.target.value; // 체크된 값
        const checkedValues = inputValues.projectCategory2.split('|'); // 문자열을 배열로 변환
    
        // 클릭된 값이 이미 있는지 확인
        const isAlreadyChecked = checkedValues.includes(valueToAdd);
    
        let updatedValues;
    
        // 클릭된 값이 없으면 추가
        if (!isAlreadyChecked) {
            updatedValues = [...checkedValues, valueToAdd];
        } else {
            // 클릭된 값이 있으면 제거
            updatedValues = checkedValues.filter(value => value !== valueToAdd);
        }
    
        // 다시 문자열로 변환하여 업데이트
        setInputValues({ ...inputValues, projectCategory2: updatedValues.join('|') });
    };
    
    
    


    
    return(
        <div className="rootConatiner">
            <div className="inner"> 
                <form onSubmit={onSubmitHandler}>
                    <div className="write_page">
                    <ul className="full_box title_box">
                            <li>
                                <span className="label">카테고리 *</span>
                                <span className="label_content">
                                    <label>
                                        <input type="checkbox" name="category" value="기업 소개" checked={inputValues.projectCategory2.includes('기업 소개')} onChange={handleCheckChange} />
                                        <sapn>기업 소개</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="랜딩페이지" checked={inputValues.projectCategory2.includes('랜딩페이지')} onChange={handleCheckChange}/>
                                        <sapn>랜딩페이지</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="프랜차이즈" checked={inputValues.projectCategory2.includes('프랜차이즈')} onChange={handleCheckChange} />
                                        <sapn>프랜차이즈</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="플랫폼" checked={inputValues.projectCategory2.includes('플랫폼')} onChange={handleCheckChange} />
                                        <sapn>플랫폼</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="법률·세무" checked={inputValues.projectCategory2.includes('법률·세무')} onChange={handleCheckChange} />
                                        <sapn>법률·세무</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="여행·숙박" checked={inputValues.projectCategory2.includes('여행·숙박')} onChange={handleCheckChange} />
                                        <sapn>여행·숙박</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="공기업" checked={inputValues.projectCategory2.includes('공기업')} onChange={handleCheckChange} />
                                        <sapn>공기업</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="공모전·페스티벌" checked={inputValues.projectCategory2.includes('공모전·페스티벌')} onChange={handleCheckChange} />
                                        <sapn>공모전·페스티벌</sapn>
                                    </label>
                                    &nbsp;
                                    <label>
                                        <input type="checkbox" name="category" value="온라인 쇼핑몰" checked={inputValues.projectCategory2.includes('온라인 쇼핑몰')} onChange={handleCheckChange} />
                                        <sapn>온라인 쇼핑몰</sapn>
                                    </label>
                                </span>
                            </li>
                        </ul>

                        <ul className="half_box title_box">
                            <li>
                                <span className="label">프로젝트명 *</span>
                                <span className="label_content">
                                    <input 
                                        type="text" 
                                        name="projectName"
                                        value={inputValues.projectName}
                                        onChange={handleInputChange}
                                        maxLength={255}
                                        required 
                                        placeholder="프로젝트명을 입력해주세요."
                                    />
                                </span>
                            </li>
                            <li>
                                <span className="label">회사명(고객사) *</span>
                                <span className="label_content">
                                    <input 
                                        type="text"
                                        name="projectCompany"
                                        value={inputValues.projectCompany}
                                        onChange={handleInputChange}
                                        required
                                        maxLength={255} 
                                        placeholder="회사명, 고객사를 입력해주세요."
                                        />
                                </span>
                            </li>
                        </ul>
                        <ul className="half_box title_box">
                            <li>
                                <span className="label">홈페이지 유형 *</span>
                                <span className="label_content">
                                    <select 
                                        name="projectCategory" 
                                        onChange={handleInputChange} 
                                        value={inputValues.projectCategory}
                                        required>
                                        <option value="">홈페이지 유형을 선택해주세요</option>
                                        <option value="반응형 홈페이지">반응형 홈페이지</option>
                                        <option value="PC">PC</option>
                                        <option value="모바일">모바일</option>
                                    </select>
                                </span>
                            </li>
                            <li>
                                <span className="label">간단 소개 *</span>
                                <span className="label_content">
                                    <input 
                                        type="text" 
                                        name="projectIntro"
                                        value={inputValues.projectIntro}
                                        onChange={handleInputChange}
                                        required
                                        maxLength={255} 
                                        placeholder="ex ) 리뉴얼 , 랜딩페이지 제작 등"
                                        />
                                </span>
                            </li>
                        </ul>
                        {/* <ul className="half_box title_box">
                            <li>
                                <span className="label">참여기간(시작) *</span>
                                <span className="label_content">
                                <ReactDatePicker 
                                    name="projectStart"
                                    placeholderText="YYYY-MM-DD"
                                    value={formatDate(inputValues.projectStart)}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(value) => handleDateChange('projectStart', value)}
                                    className="datepicker"
                                />
                                </span>
                            </li>
                            <li>
                                <span className="label">참여기간(종료)</span>
                                <span className="label_content">
                                <ReactDatePicker 
                                    name="projectEnd"
                                    placeholderText="YYYY-MM-DD"
                                    value={formatDate(inputValues.projectEnd)}
                                    dateFormat="yyyy-MM-dd"
                                    onChange={(value) => handleDateChange('projectEnd', value)}
                                    className="datepicker"
                                />
                                </span>
                            </li>
                        </ul> */}
                        <ul className="full_box title_box">
                            <li>
                                <span className="label">홈페이지 URL</span>
                                <span className="label_content">
                                    <input 
                                        type="text" 
                                        name="projectUrl"
                                        value={inputValues.projectUrl}
                                        onChange={handleInputChange}
                                        placeholder="홈페이지 URL을 입력해주세요."
                                    />
                                </span>
                            </li>
                            <li>
                                <span className="label">순서</span>
                                <span className="label_content">
                                    <input 
                                        type="text" 
                                        name="projectOrder"
                                        value={inputValues.projectOrder}
                                        onChange={handleInputChange}
                                        placeholder="순서를 입력해주세요."
                                    />
                                </span>
                            </li>
                        </ul>
                        <ul className="half_box title_box">
                            <li>
                                <span className="label">색상 #1</span>
                                <span className="label_content">
                                    <input 
                                        type="color" 
                                        name="projectColor1"
                                        value={inputValues.projectColor1}
                                        onChange={handleInputChange}
                                        />
                                </span>
                            </li>
                            <li>
                                <span className="label">색상 #2</span>
                                <span className="label_content">
                                    <input
                                        type="color" 
                                        name="projectColor2"
                                        value={inputValues.projectColor2}
                                        onChange={handleInputChange}
                                    />
                                </span>
                            </li>
                            <li>
                                <span className="label">색상 #3</span>
                                <span className="label_content">
                                    <input
                                        type="color" 
                                        name="projectColor3"
                                        value={inputValues.projectColor3}
                                        onChange={handleInputChange}
                                    />
                                </span>
                            </li>
                            <li>
                                <span className="label">색상 #모바일</span>
                                <span className="label_content">
                                    <input
                                        type="color" 
                                        name="projectColorM"
                                        value={inputValues.projectColorM}
                                        onChange={handleInputChange}
                                    />
                                </span>
                            </li>
                        </ul>

                        <div className="add_form">
                            <div className="add_box file_box">
                                <p className="help">썸네일 이미지(960 * 800)</p>
                                <input 
                                    name="projectFile1"
                                    onChange={handleFileChange}
                                    type="file"
                                />
                            </div>
                            {projectFile1 && (
                            <div className="file_del_box">
                                <label htmlFor="file_01" className="file_title">
                                    <div>
                                        <input
                                            id="file_01"
                                            type="checkbox"
                                            checked={delProjectFiles[0]}
                                            onChange={(e) => handleCheckboxChange(e, 1)}
                                        />
                                        <span>{projectFile1} 파일 삭제</span>
                                    </div>
                                </label>
                            </div>
                            )}
                            <div className="add_box file_box">
                                <p className="help">배너 이미지 ( 1400 * 350 )</p>
                                <input 
                                    name="projectFile2"
                                    onChange={handleFileChange}
                                    type="file"
                                />
                            </div>
                            {projectFile2 && (
                            <div className="file_del_box">
                                <label htmlFor="file_02" className="file_title">
                                    <div>
                                        <input
                                            id="file_02"
                                            type="checkbox"
                                            checked={delProjectFiles[1]}
                                            onChange={(e) => handleCheckboxChange(e, 2)}
                                        />
                                        <span>{projectFile2} 파일 삭제</span>
                                    </div>
                                </label>
                            </div>
                            )}
                            <div className="add_box file_box">
                                <p className="help">(상세) 반응형 </p>
                                <input 
                                    name="projectFile3"
                                    onChange={handleFileChange}
                                    type="file"
                                />
                            </div>
                            {projectFile3 && (
                            <div className="file_del_box">
                                <label htmlFor="file_03" className="file_title">
                                    <div>
                                        <input
                                            id="file_03"
                                            type="checkbox"
                                            checked={delProjectFiles[2]}
                                            onChange={(e) => handleCheckboxChange(e, 3)}
                                        />
                                        <span>{projectFile3} 파일 삭제</span>
                                    </div>
                                </label>
                            </div>
                            )}
                            <div className="add_box file_box">
                                <p className="help">(상세) 메인화면 </p>
                                <input 
                                    name="projectFile4"
                                    onChange={handleFileChange}
                                    type="file"
                                />
                            </div>
                            {projectFile4 && (
                            <div className="file_del_box">
                                <label htmlFor="file_04" className="file_title">
                                    <div>
                                        <input
                                            id="file_04"
                                            type="checkbox"
                                            checked={delProjectFiles[3]}
                                            onChange={(e) => handleCheckboxChange(e, 4)}
                                        />
                                        <span>{projectFile4} 파일 삭제</span>
                                    </div>
                                </label>
                            </div>
                            )}
                            <div className="add_box file_box">
                                <p className="help">(상세) 모바일 이미지 </p>
                                <input 
                                    name="projectFile5"
                                    onChange={handleFileChange}
                                    type="file"
                                />
                            </div>
                            {projectFile5 && (
                            <div className="file_del_box">
                                <label htmlFor="file_05" className="file_title">
                                    <div>
                                        <input
                                            id="file_05"
                                            type="checkbox"
                                            checked={delProjectFiles[4]}
                                            onChange={(e) => handleCheckboxChange(e, 5)}
                                        />
                                        <span>{projectFile5} 파일 삭제</span>
                                    </div>
                                </label>
                            </div>
                            )}
                        </div>
                        <div className="write_btn_box">
                            <button type="button" className="cancelBtn" onClick={() => {navigate(-1)}}>취소</button>
                            <button type="submit" className="submitBtn">작성완료</button>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    );
}

export default ProjectWrite;