import "./Signup.css"
import {useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const MemberFindPW = () => {
    const navigate = useNavigate();

        //Submit
        const onSubmitHandler = async (e) => {
            e.preventDefault();
            try {
                const formData = new FormData();
                formData.append('userEmail', inputValues.userEmail);
                formData.append('userId', inputValues.userId);
                formData.append('userPassword1', inputValues.userPassword1);
                formData.append('userPassword2', inputValues.userPassword2);
                try {
                    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/findPw`, formData);
                    if (response.status === 200) {
                            if (response.data.message == "S") {
                                alert("비밀번호가 변경되었습니다.");
                                const redirectURL = `/login`; 
                                navigate(redirectURL);
                                return
                            } else {
                                alert(response.data.message);
                                return;
                            }
                    } else {
                      throw new Error('서버 응답 오류');
                    }
                } catch (error) {
                    console.log(error);
                    alert(error);
                }
            } catch (error) {
                console.error(error);
            }
            };
            const [inputValues, setInputValues] = useState({
                userEmail: '',
                userId: '',
                userPassword1:'',
                userPassword2:''
            });
            const handleInputChange = (event) => {
                const { name, value } = event.target;
                setInputValues((prevInputValues) => ({
                    ...prevInputValues,
                    [name]: value,
                }));
            };
    return(
        <div className="rootConatiner">
            <div className="signupContainer">
                <div className="inner">
                    <div className="titleBox">
                        <h2>Jobofbrothers</h2>
                        <p>비밀번호 찾기</p>
                    </div>
                    <form onSubmit={onSubmitHandler}>
                        <div className="signupForm">
                            <p className="produce">비밀번호 찾기</p>
                            <ul className="formList">
                                <li className="half">
                                    <label>아이디</label>
                                    <div className="iptWrap">
                                        <input 
                                            type="text" 
                                            name="userId"
                                            maxLength={255}
                                            className="ipt"
                                            placeholder=""
                                            onChange={handleInputChange}

                                        />
                                        <p class="help">아이디를 입력해주세요.</p>
                                    </div>
                                </li>
                                <li className="half">
                                    <label>이메일</label>
                                    <div className="iptWrap">
                                        <input 
                                            type="email" 
                                            name="userEmail"
                                            maxLength={255}
                                            className="ipt"
                                            placeholder=""
                                            onChange={handleInputChange}

                                        />
                                        <p class="help">가입하신 이메일을 입력 해주세요.</p>
                                    </div>
                                </li>
                               
                                <li className="half">
                                    <label>새로운 비밀번호</label>
                                    <div className="iptWrap">
                                        <input 
                                            type="password" 
                                            name="userPassword1"
                                            maxLength={255}
                                            className="ipt"
                                            placeholder=""
                                            onChange={handleInputChange}

                                        />
                                        <p class="help">비밀번호를 입력해주세요.</p>
                                    </div>
                                </li>

                                    <li className="half">
                                    <label>비밀번호 확인</label>
                                    <div className="iptWrap">
                                        <input 
                                            type="password" 
                                            name="userPassword2"
                                            onChange={handleInputChange}
                                            maxLength={255}
                                            className="ipt"
                                            placeholder=""
                                        />
                                        <p class="help">비밀번호를 다시한번 입력해주세요.</p>
                                    </div>
                                </li>
                            </ul>
                            <button className="okBtn">확인</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default MemberFindPW;