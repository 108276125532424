
import React from "react";
import MypageContainer from "../../../components/mypage/MyPageContainer";
import MyPageContent from "../../../components/mypage/MyPageContent";
import MyPageHeader from "../../../components/mypage/MyPageHeader";
import MypageMenu from "../../../components/mypage/MyPageMenu";
import axios from 'axios';
import {Link} from 'react-router-dom';
import styles from '../MyProject.module.css'; // CSS Modules 파일 import
import PagingBox from '../../../components/PagingBox';
import { useLocation } from 'react-router-dom';


const ProjectManagement = () => {
    return(
        <div className="mypageContainer">
            <MypageMenu />
            <MypageContainer>
                <MyPageHeader  title="프로젝트 관리" />
                <MyPageContent>
                    <div className={styles.projectContainer}>
                        <div className={styles.manageBox}>
                            <ul className={styles.manageBtnWrap}>
                                <li><input type="checkbox" className={styles.checkbox}/></li>
                                <li>
                                    <button type="button" className={styles.btn}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M13.7535 2.4749H11.5879V1.99678C11.5879 1.15303 10.9129 0.478027 10.0691 0.478027H7.90352C7.05977 0.478027 6.38477 1.15303 6.38477 1.99678V2.4749H4.21914C3.40352 2.4749 2.72852 3.1499 2.72852 3.96553V4.80928C2.72852 5.42803 3.09414 5.93428 3.62852 6.15928L4.07852 15.8905C4.13477 16.8187 4.86602 17.5218 5.79414 17.5218H12.1223C13.0504 17.5218 13.8098 16.7905 13.8379 15.8905L14.3441 6.13115C14.8785 5.90615 15.2441 5.37178 15.2441 4.78115V3.9374C15.2441 3.1499 14.5691 2.4749 13.7535 2.4749ZM7.67852 1.99678C7.67852 1.85615 7.79102 1.74365 7.93164 1.74365H10.0973C10.2379 1.74365 10.3504 1.85615 10.3504 1.99678V2.4749H7.70664V1.99678H7.67852ZM4.02227 3.96553C4.02227 3.85303 4.10664 3.74053 4.24727 3.74053H13.7535C13.866 3.74053 13.9785 3.8249 13.9785 3.96553V4.80928C13.9785 4.92178 13.8941 5.03428 13.7535 5.03428H4.24727C4.13477 5.03428 4.02227 4.9499 4.02227 4.80928V3.96553ZM12.1504 16.2562H5.85039C5.59727 16.2562 5.40039 16.0593 5.40039 15.8343L4.95039 6.2999H13.0785L12.6285 15.8343C12.6004 16.0593 12.4035 16.2562 12.1504 16.2562Z" fill="#64748B"/>
                                            <path d="M9.00039 8.74658C8.66289 8.74658 8.35352 9.02783 8.35352 9.39346V13.7528C8.35352 14.0903 8.63477 14.3997 9.00039 14.3997C9.33789 14.3997 9.64727 14.1185 9.64727 13.7528V9.36533C9.64727 9.02783 9.33789 8.74658 9.00039 8.74658Z" fill="#64748B"/>
                                            <path d="M11.616 9.28124C11.2785 9.25311 10.9691 9.53436 10.941 9.87186L10.7723 13.1062C10.7441 13.4437 11.0254 13.7531 11.3629 13.7812H11.391C11.7285 13.7812 12.0098 13.5281 12.0098 13.1906L12.1785 9.95624C12.2348 9.59061 11.9535 9.30936 11.616 9.28124Z" fill="#64748B"/>
                                            <path d="M6.35624 9.28124C6.01874 9.30936 5.73749 9.59061 5.76561 9.95624L5.96249 13.2187C5.99061 13.5562 6.27186 13.8094 6.58124 13.8094H6.60936C6.94686 13.7812 7.22811 13.5 7.19999 13.1344L7.03124 9.87186C7.00311 9.53436 6.69374 9.25311 6.35624 9.28124Z" fill="#64748B"/>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" className={styles.btn}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                            <path d="M16.243 11.0243C16.3113 10.6926 16.1144 10.3516 15.7686 10.2589C15.4369 10.1906 15.0959 10.3875 15.0032 10.7333C14.5962 12.5596 13.4555 14.1275 11.8479 15.0556C8.65718 16.8978 4.58472 15.8066 2.74253 12.6158C0.900341 9.42507 1.99156 5.35261 5.18232 3.51042C7.54494 2.14636 10.4693 2.37404 12.5977 4.03556L11.1858 4.23372C10.8232 4.28068 10.5909 4.60964 10.6482 4.93382C10.666 5.02095 10.6838 5.10808 10.712 5.1568C10.8385 5.37601 11.0728 5.50055 11.3483 5.47142L14.2734 5.08163C14.636 5.03467 14.8683 4.70571 14.811 4.38154L14.4212 1.45641C14.3743 1.09382 14.0453 0.86155 13.7211 0.91881C13.3585 0.965775 13.1263 1.29473 13.1835 1.61891L13.3433 3.02058C10.8193 1.06778 7.35056 0.79717 4.52515 2.42842C0.749819 4.60811 -0.571641 9.46302 1.62211 13.2627C3.81586 17.0624 8.65671 18.3595 12.4564 16.1657C14.3806 15.0548 15.7677 13.1823 16.243 11.0243Z" fill="#64748B"/>
                                        </svg>
                                    </button>
                                </li>
                                <li>
                                    <Link to="/mypage/project/write" >
                                        <button type="button" className={styles.btn}>
                                            프로젝트 생성
                                        </button>
                                    </Link>
                                </li>
                            </ul>
                            <div className={styles.searchBox}>
                                <button type="submit" className={`${styles.btn} ${styles.searchBtn}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z" fill="#64748B"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M11.9572 11.957C12.2501 11.6641 12.725 11.6641 13.0179 11.957L16.2804 15.2195C16.5733 15.5124 16.5733 15.9872 16.2804 16.2801C15.9875 16.573 15.5126 16.573 15.2197 16.2801L11.9572 13.0176C11.6643 12.7247 11.6643 12.2499 11.9572 11.957Z" fill="#64748B"/>
                                    </svg>
                                </button>
                                <input type="text "id="searchcontent" name="searchcontent" placeholder="제목을 입력하세요." className={styles.searchIpt}/>
                                <select id="searchoption" name="searchoption" defaultValue="projectName">
                                    <option value="projectName">제목</option>
                                    <option value="projectStatus">상태</option>
                                    <option value="projectCategory">유형</option>
                                </select>
                                {/* <button type="button" className={`${styles.btn}`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <g clipPath="url(#clip0_60_1657)">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.25 3C8.25 3.41421 7.91421 3.75 7.5 3.75L2.25 3.75C1.83578 3.75 1.5 3.41421 1.5 3C1.5 2.58579 1.83578 2.25 2.25 2.25L7.5 2.25C7.91421 2.25 8.25 2.58579 8.25 3Z" fill="#64748B"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16.5 3C16.5 3.41421 16.1642 3.75 15.75 3.75L10.5 3.75C10.0858 3.75 9.75 3.41421 9.75 3C9.75 2.58579 10.0858 2.25 10.5 2.25L15.75 2.25C16.1642 2.25 16.5 2.58579 16.5 3Z" fill="#64748B"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M9.75 9C9.75 9.41421 9.41421 9.75 9 9.75L2.25 9.75C1.83579 9.75 1.5 9.41421 1.5 9C1.5 8.58579 1.83579 8.25 2.25 8.25L9 8.25C9.41421 8.25 9.75 8.58579 9.75 9Z" fill="#64748B"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16.5 9C16.5 9.41421 16.1642 9.75 15.75 9.75L12 9.75C11.5858 9.75 11.25 9.41421 11.25 9C11.25 8.58579 11.5858 8.25 12 8.25L15.75 8.25C16.1642 8.25 16.5 8.58579 16.5 9Z" fill="#64748B"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6.75 15C6.75 15.4142 6.41421 15.75 6 15.75L2.25 15.75C1.83578 15.75 1.5 15.4142 1.5 15C1.5 14.5858 1.83578 14.25 2.25 14.25L6 14.25C6.41421 14.25 6.75 14.5858 6.75 15Z" fill="#64748B"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M16.5 15C16.5 15.4142 16.1642 15.75 15.75 15.75L9 15.75C8.58579 15.75 8.25 15.4142 8.25 15C8.25 14.5858 8.58579 14.25 9 14.25L15.75 14.25C16.1642 14.25 16.5 14.5858 16.5 15Z" fill="#64748B"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.5 -3.27835e-08C7.91421 -1.46777e-08 8.25 0.335786 8.25 0.75L8.25 5.25C8.25 5.66421 7.91421 6 7.5 6C7.08579 6 6.75 5.66421 6.75 5.25L6.75 0.75C6.75 0.335786 7.08579 -5.08894e-08 7.5 -3.27835e-08Z" fill="#64748B"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12 6C12.4142 6 12.75 6.33579 12.75 6.75L12.75 11.25C12.75 11.6642 12.4142 12 12 12C11.5858 12 11.25 11.6642 11.25 11.25L11.25 6.75C11.25 6.33579 11.5858 6 12 6Z" fill="#64748B"/>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M6 12C6.41421 12 6.75 12.3358 6.75 12.75L6.75 17.25C6.75 17.6642 6.41421 18 6 18C5.58579 18 5.25 17.6642 5.25 17.25L5.25 12.75C5.25 12.3358 5.58579 12 6 12Z" fill="#64748B"/>
                                        </g>
                                        
                                        <defs>
                                            <clipPath id="clip0_60_1657">
                                            <rect width="18" height="18" fill="white" transform="translate(18) rotate(90)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button> */}
                            </div>
                        </div>{/* manageBox end - 관리 박스 */}
                        <ul className={styles.listBox}>
                            <li className={styles.listTitle}>
                                <div className={styles.inputWrap}>
                                    <input type="checkbox" name=""  className={styles.checkbox}/>
                                </div>
                                <div className={styles.numberWrap}>번호</div>
                                <div className={styles.titleWrap}>제목</div>
                                <div className={styles.typeWrap}>유형</div>
                                <div className={styles.statusWrap}>상태</div>
                                <div className={styles.dateWrap}>날짜</div>
                            </li>
                            {/* 목록은 10개씩 노출됩니다. */}
                            <li>
                                <div className={styles.inputWrap}>
                                    <input type="checkbox" name=""  className={styles.checkbox}/>
                                </div>
                                <div className={styles.numberWrap}>1234</div>
                                <div className={styles.titleWrap}>
                                    <Link to={`/mypage/project/view`}>
                                        프로젝트명
                                    </Link>
                                </div>
                                <div className={styles.typeWrap}>카테고리</div>
                                <div className={styles.statusWrap}>상태</div>
                                <div className={styles.dateWrap}>
                                    2022.00.00 
                                    <Link to="/mypage/project/modify" >
                                        <button type="button" className={styles.mb_modify_btn}>수정하기</button>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className={styles.inputWrap}>
                                    <input type="checkbox" name=""  className={styles.checkbox}/>
                                </div>
                                <div className={styles.numberWrap}>1234</div>
                                <div className={styles.titleWrap}>
                                    <Link to={`/mypage/project/view`}>
                                        프로젝트명
                                    </Link>
                                </div>
                                <div className={styles.typeWrap}>카테고리</div>
                                <div className={styles.statusWrap}>상태</div>
                                <div className={styles.dateWrap}>
                                    2022.00.00 
                                    <Link to="/mypage/project/modify" >
                                        <button type="button" className={styles.mb_modify_btn}>수정하기</button>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className={styles.inputWrap}>
                                    <input type="checkbox" name=""  className={styles.checkbox}/>
                                </div>
                                <div className={styles.numberWrap}>1234</div>
                                <div className={styles.titleWrap}>
                                    <Link to={`/mypage/project/view`}>
                                        프로젝트명
                                    </Link>
                                </div>
                                <div className={styles.typeWrap}>카테고리</div>
                                <div className={styles.statusWrap}>상태</div>
                                <div className={styles.dateWrap}>
                                    2022.00.00 
                                    <Link to="/mypage/project/modify" >
                                        <button type="button" className={styles.mb_modify_btn}>수정하기</button>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className={styles.inputWrap}>
                                    <input type="checkbox" name=""  className={styles.checkbox}/>
                                </div>
                                <div className={styles.numberWrap}>1234</div>
                                <div className={styles.titleWrap}>
                                    <Link to={`/mypage/project/view`}>
                                        프로젝트명
                                    </Link>
                                </div>
                                <div className={styles.typeWrap}>카테고리</div>
                                <div className={styles.statusWrap}>상태</div>
                                <div className={styles.dateWrap}>
                                    2022.00.00 
                                    <Link to="/mypage/project/modify" >
                                        <button type="button" className={styles.mb_modify_btn}>수정하기</button>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className={styles.inputWrap}>
                                    <input type="checkbox" name=""  className={styles.checkbox}/>
                                </div>
                                <div className={styles.numberWrap}>1234</div>
                                <div className={styles.titleWrap}>
                                    <Link to={`/mypage/project/view`}>
                                        프로젝트명
                                    </Link>
                                </div>
                                <div className={styles.typeWrap}>카테고리</div>
                                <div className={styles.statusWrap}>상태</div>
                                <div className={styles.dateWrap}>
                                    2022.00.00 
                                    <Link to="/mypage/project/modify" >
                                        <button type="button" className={styles.mb_modify_btn}>수정하기</button>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className={styles.inputWrap}>
                                    <input type="checkbox" name=""  className={styles.checkbox}/>
                                </div>
                                <div className={styles.numberWrap}>1234</div>
                                <div className={styles.titleWrap}>
                                    <Link to={`/mypage/project/view`}>
                                        프로젝트명
                                    </Link>
                                </div>
                                <div className={styles.typeWrap}>카테고리</div>
                                <div className={styles.statusWrap}>상태</div>
                                <div className={styles.dateWrap}>
                                    2022.00.00 
                                    <Link to="/mypage/project/modify" >
                                        <button type="button" className={styles.mb_modify_btn}>수정하기</button>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className={styles.inputWrap}>
                                    <input type="checkbox" name=""  className={styles.checkbox}/>
                                </div>
                                <div className={styles.numberWrap}>1234</div>
                                <div className={styles.titleWrap}>
                                    <Link to={`/mypage/project/view`}>
                                        프로젝트명
                                    </Link>
                                </div>
                                <div className={styles.typeWrap}>카테고리</div>
                                <div className={styles.statusWrap}>상태</div>
                                <div className={styles.dateWrap}>
                                    2022.00.00 
                                    <Link to="/mypage/project/modify" >
                                        <button type="button" className={styles.mb_modify_btn}>수정하기</button>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className={styles.inputWrap}>
                                    <input type="checkbox" name=""  className={styles.checkbox}/>
                                </div>
                                <div className={styles.numberWrap}>1234</div>
                                <div className={styles.titleWrap}>
                                    <Link to={`/mypage/project/view`}>
                                        프로젝트명
                                    </Link>
                                </div>
                                <div className={styles.typeWrap}>카테고리</div>
                                <div className={styles.statusWrap}>상태</div>
                                <div className={styles.dateWrap}>
                                    2022.00.00 
                                    <Link to="/mypage/project/modify" >
                                        <button type="button" className={styles.mb_modify_btn}>수정하기</button>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className={styles.inputWrap}>
                                    <input type="checkbox" name=""  className={styles.checkbox}/>
                                </div>
                                <div className={styles.numberWrap}>1234</div>
                                <div className={styles.titleWrap}>
                                    <Link to={`/mypage/project/view`}>
                                        프로젝트명
                                    </Link>
                                </div>
                                <div className={styles.typeWrap}>카테고리</div>
                                <div className={styles.statusWrap}>상태</div>
                                <div className={styles.dateWrap}>
                                    2022.00.00 
                                    <Link to="/mypage/project/modify" >
                                        <button type="button" className={styles.mb_modify_btn}>수정하기</button>
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className={styles.inputWrap}>
                                    <input type="checkbox" name=""  className={styles.checkbox}/>
                                </div>
                                <div className={styles.numberWrap}>1234</div>
                                <div className={styles.titleWrap}>
                                    <Link to={`/mypage/project/view`}>
                                        프로젝트명
                                    </Link>
                                </div>
                                <div className={styles.typeWrap}>카테고리</div>
                                <div className={styles.statusWrap}>상태</div>
                                <div className={styles.dateWrap}>
                                    2022.00.00 
                                    <Link to="/mypage/project/modify" >
                                        <button type="button" className={styles.mb_modify_btn}>수정하기</button>
                                    </Link>
                                </div>
                            </li>
                        </ul>{/* list end */}
                        <ul className={styles.numberBox}>
                            <li className={styles.pagingNumber}>
                                <p>1-10 / 29</p> {/* 총 29페이지 , 1~10번까지 라는 것 같아요! */}
                            </li>
                            <div className="paging_box">
                                <nav aria-label="Page navigation">
                                    <PagingBox 
                                        boardName = "mypage/myproject"
                                        // totalPage = {totalPage}
                                    />
                                </nav>
                            </div>
                            <li className={styles.p_n_Box}>
                                <ul className={styles.p_n_wrap}>
                                    <li>
                                        <div className={styles.p_n_btn}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M9.53033 15.5303C9.23744 15.8232 8.76256 15.8232 8.46967 15.5303L2.46967 9.53033C2.17678 9.23744 2.17678 8.76256 2.46967 8.46967L8.46967 2.46967C8.76256 2.17678 9.23744 2.17678 9.53033 2.46967C9.82322 2.76256 9.82322 3.23744 9.53033 3.53033L4.81066 8.25L15 8.25C15.4142 8.25 15.75 8.58579 15.75 9C15.75 9.41421 15.4142 9.75 15 9.75L4.81066 9.75L9.53033 14.4697C9.82322 14.7626 9.82322 15.2374 9.53033 15.5303Z" fill=""/>
                                            </svg>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={styles.p_n_btn}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.46967 2.46967C8.76256 2.17678 9.23744 2.17678 9.53033 2.46967L15.5303 8.46967C15.8232 8.76256 15.8232 9.23744 15.5303 9.53033L9.53033 15.5303C9.23744 15.8232 8.76256 15.8232 8.46967 15.5303C8.17678 15.2374 8.17678 14.7626 8.46967 14.4697L13.1893 9.75H3C2.58579 9.75 2.25 9.41421 2.25 9C2.25 8.58579 2.58579 8.25 3 8.25H13.1893L8.46967 3.53033C8.17678 3.23744 8.17678 2.76256 8.46967 2.46967Z" fill=""/>
                                            </svg>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </MyPageContent>
            </MypageContainer>
        </div>
    );
}

export default ProjectManagement;
