import { useContext,useState,useEffect} from "react";
import { useNavigate,useLocation,Link } from "react-router-dom";
import MypageContainer from "../../components/mypage/MyPageContainer";
import MyPageContent from "../../components/mypage/MyPageContent";
import MyPageHeader from "../../components/mypage/MyPageHeader";
import MypageMenu from "../../components/mypage/MyPageMenu";
import styles from './MyProjectView.module.css'; // CSS Modules 파일 import
import axios from 'axios';
import { formatDate } from '../../middlewares/formatDate';
import { MyPageContext } from "../../components/mypage/MyPageContext";


const MyProjectView = () => {
    
    const { data } = useContext(MyPageContext);
    const navigate = useNavigate(); // useHistory 대신 useNavigate 사용
    const goBack = () => {
        navigate(-1); // 이전 페이지로 이동
    };
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const idx = searchParams.get('index');
    const pageNum = searchParams.get('pageNum');
    const searchcontent = searchParams.get('searchcontent');
    const searchoption = searchParams.get('searchoption');
    const list_qty = 10;
    const [projectData, setProjectData] = useState();
    const [prevProjectData, setPrevProjectData] = useState();
    const [nextProjectData, setNextProjectData] = useState();
    const [totalPage, setTotalPage] = useState();
    const [totalCount, setTotalCount] = useState(0); //게시물 총 갯수 
    const [searchData, setSearchData] = useState({
       searchcontent:searchcontent,
       searchoption:searchoption
    })
    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/users/page`,
                    {
                        page : pageNum ,
                        sumPerPage : list_qty, //목록 갯수 
                        userEmail : data.userData.userEmail,
                        searchData:searchData
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                if (response.status === 200) {
                    setTotalCount( response.data.totalCount);
                    setTotalPage(response.data.totalPage);
                    const newProjectData =  response.data.result[0].map((item, idx )=> ({
                        number : ((totalCount -( list_qty * (pageNum - 1))) - idx),
                        ...item
                    }))
                    setProjectData(newProjectData);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }
        };
        fetchProjectData(); // Pass the 'id' value to the fetchTotalData function
    }, [pageNum]);

    useEffect(() => {
        const fetchNextProjectData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/users/page`,
                    {
                        page : (pageNum>1)? parseInt(pageNum)-1:pageNum,
                        sumPerPage : list_qty, //목록 갯수 
                        userEmail : data.userData.userEmail,
                        searchData:searchData
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                if (response.status === 200) {
                    const newProjectData =  response.data.result[0].map((item, idx )=> ({
                        number : ((totalCount -( list_qty * (pageNum - 1))) - idx),
                        ...item
                    }))
                    setNextProjectData(newProjectData);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }
        };
        fetchNextProjectData(); // Pass the 'id' value to the fetchTotalData function
    }, [pageNum]);

    useEffect(() => {
        const fetchPrevProjectData = async () => {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/users/page`,
                    {
                        page : parseInt(pageNum)+1 ,
                        sumPerPage : list_qty, //목록 갯수 
                        userEmail : data.userData.userEmail,
                        searchData:searchData
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                );
                if (response.status === 200) {
                    const newProjectData =  response.data.result[0].map((item, idx )=> ({
                        number : ((totalCount -( list_qty * (pageNum - 1))) - idx),
                        ...item
                    }))
                    setPrevProjectData(newProjectData);
                } else {
                    throw new Error('서버 응답 오류');
                }
            } catch (error) {
                console.log(error);
                alert(error);
            }
        };
        fetchPrevProjectData(); // Pass the 'id' value to the fetchTotalData function
    }, [pageNum]);



    let nextOkay=true;
    if(idx==0&&pageNum==1){
        nextOkay=false;
    }
    let prevOkay=true;
    if(((parseInt(pageNum)-1)*10+(parseInt(idx)+1))==totalCount){
        prevOkay=false;
    }

    let prevProject;
    let nextProject;
    let presentProject;

    let prevprojectid;
    let nextprojectid;
    let prevchangePageNum;
    let nextchangePageNum;
    
    if(idx==0){
        prevProject = projectData && projectData[parseInt(idx) + 1];
        presentProject = projectData && projectData[idx];
        nextProject = nextProjectData && nextProjectData[9];
        prevprojectid=1;
        nextprojectid=9;
        nextchangePageNum=parseInt(pageNum)-1
        prevchangePageNum=parseInt(pageNum)
    }else if(idx==9){
        prevProject = prevProjectData && prevProjectData[0];
        presentProject = projectData && projectData[idx];
        nextProject = projectData && projectData[parseInt(idx) - 1];
        nextprojectid=8;
        prevprojectid=0;
        nextchangePageNum=parseInt(pageNum)
        prevchangePageNum=parseInt(pageNum)+1
    }else{
        prevProject = projectData && projectData[parseInt(idx) + 1];
        presentProject = projectData && projectData[idx];
        nextProject = projectData && projectData[parseInt(idx) - 1];
        nextprojectid=parseInt(idx)-1;
        prevprojectid=parseInt(idx)+1;
        prevchangePageNum=parseInt(pageNum)
        nextchangePageNum=parseInt(pageNum)
    }

    return (
        <div className="mypageContainer">
            {(presentProject)?
              <>
                  <>
                    <MypageMenu />
                    <MypageContainer>
                      <MyPageHeader title="프로젝트" />
                      <MyPageContent>
                        <div className={styles.menuBar}>
                          <div className={styles.back_icon} onClick={goBack}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <path d="M13.3334 7.33317H5.22008L8.94675 3.6065L8.00008 2.6665L2.66675 7.99984L8.00008 13.3332L8.94008 12.3932L5.22008 8.6665H13.3334V7.33317Z" fill="#0C0C0C" />
                            </svg>
                          </div>
                          <p>프로젝트 진행상황</p>
                        </div>
                        <div className={styles.projectContainer}>
                          <div className={styles.view_page}>
                            <ul className={`${styles.full_title} ${styles.titleBox}`}>
                              <li>
                                <span className={styles.label}>제목</span>
                                <span className={styles.label_content}>
                                    {presentProject.projectName}
                                </span>
                              </li>
                            </ul>
                            <ul className={`${styles.three_title} ${styles.titleBox}`}>
                              <li>
                                <span className={styles.label}>유형</span>
                                <span className={styles.label_content}>
                                {presentProject.projectCategory}

                                </span>
                              </li>
                              <li>
                                <span className={styles.label}>상태</span>
                                <span className={styles.label_content}>
                                {presentProject.projectStatus}

                                    </span>
                              </li>
                              <li>
                                <span className={styles.label}>날짜</span>
                                <span className={styles.label_content}>
                                {formatDate(presentProject.projectStart)}~{(presentProject.projectEnd)?formatDate(presentProject.projectEnd):null}
                                </span>
                              </li>
                            </ul>
                            <div className={styles.view_content}>
                            {presentProject.projectIntro}
                            </div>
                            <div className={styles.add_box}>
                              <a href="#" className={`${styles.add_item} ${styles.file}`}>
                                <span className="add_title">File.jpg (741.5K)</span>
                                <span className="add_content">1회 다운로드 | DATE : 2020-05-05- 17:05:20</span>
                              </a>
                              <a href="#" className={`${styles.add_item} ${styles.file}`}>
                                <span className="add_title">File.jpg (741.5K)</span>
                                <span className="add_content">1회 다운로드 | DATE : 2020-05-05- 17:05:20</span>
                              </a>
                              <a href="#" className={`${styles.add_item} ${styles.link}`} target="_blank">
                                <span className="add_title">{presentProject.projectUrl}</span>
                                <span className="add_content">34회 연결</span>
                              </a>
                              <a href="#" className={`${styles.add_item} ${styles.link}`} target="_blank">
                                <span className="add_title">http://www.link.com</span>
                                <span className="add_content">34회 연결</span>
                              </a>
                            </div>
                            <div className={styles.p_n_box}>
                                {(prevOkay)?
                                <div className={`${styles.prev_box} ${styles.p_n_item}`}>
                                  <span className={`${styles.label}`}>이전글</span>
                                  <span className={`${styles.p_n_title}`}>
                                    <Link to={{ pathname: `/mypage/myproject/view`,search: `?index=${prevprojectid}&pageNum=${prevchangePageNum}&searchcontent=${searchData.searchcontent}&searchoption=${searchData.searchoption}`}}>
                                    {(prevProject)? prevProject.projectName: null}
                                    </Link>
                                  </span>
                                </div>
                                :
                                null
                                }
                                {(nextOkay)?
                                <div className={`${styles.next_box} ${styles.p_n_item}`}>
                                  <span className={`${styles.label}`}>다음글</span>
                                  <span className={`${styles.p_n_title}`}>
                                    <Link to={{ pathname: `/mypage/myproject/view`,search: `?index=${nextprojectid}&pageNum=${nextchangePageNum}&searchcontent=${searchData.searchcontent}&searchoption=${searchData.searchoption}`}}>
                                    {(nextProject)? nextProject.projectName: null}
                                    </Link>
                                  </span>
                                </div>
                                :
                                null
                                }
                            </div>
                          </div>
                        </div>
                      </MyPageContent>
                    </MypageContainer>
                  </>
              </>
              :
              null
              }
        </div>
      );
      
}

export default MyProjectView; 
